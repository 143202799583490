import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import $ from "jquery";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./styles.css";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";

import SalesOrders from "../SalesOrders/index";
import Quotations from "../Quotations/index";
import Rfqs from "../Quotations/Rfqs";

import { getAdminDashboard } from "./../../../config/metabase_api_calls";

import { addSettings, addMsaInfo } from "../../../actions";
import { COMPANY_NAME } from "../../../config/config";
import { listServices, getDashboardCounts } from "./../../../config/api_calls";
import { dateString, dateFirstString } from "./../../../config/config";
import localization from "../../../localization/localization";
import DataTable from "../../../components/Tables/DataTable";
import { Link } from "react-router-dom";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  columnsServices,
} from "./../../../config/config";
import HelperClass from "./../../../config/helperClass";
const auth = new HelperClass();

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      tableLoader: true,
      totalRegistered: 0,
      totalActive: 0,
      monthlyBills: "0.00",
      paidBills: "0.00",
      show_dashboard: "",
      dashboardLoader: true,
      iframe_url: "",
      columnServices: [
        { name: "Id" },
        { name: "Title" },
        {
          name: "Details",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.viewBtns()}</div>;
            },
          },
        },
        { name: "Nrc($)" },
        { name: "Mrc($)" },
        { name: "Rate Type" },
        { name: "Rate($)" },
        {
          name: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <CreateIcon
                    style={{
                      cursor: "pointer",
                    }}
                    disabled={
                      this.props.is_msa_signed === "No" ||
                      this.props.defaultSettings === "missing"
                        ? true
                        : false
                    }
                  />{" "}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setMarkUp(value, tableMeta)}
                    disabled={
                      this.props.is_msa_signed === "No" ||
                      this.props.defaultSettings === "missing"
                        ? true
                        : false
                    }
                  >
                    Set Rates
                  </a>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  setMarkUp = () => {
    //window.$("#modalForm").modal("show");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  async componentDidMount() {
    let user_subpoena_check = auth.getSubpoenaType();
    if(user_subpoena_check && user_subpoena_check === "Yes"){
      window.location.replace("/Compliance");
    }
    const getCountsInfo = await getAdminDashboard(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("getAdminDashboard: ", getCountsInfo.data);
    if (
      getCountsInfo.data.status === 403 ||
      getCountsInfo.data.errors === "authentication missing" ||
      getCountsInfo.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (getCountsInfo.data.status === 404) {
      await this.setServiceList();
      await this.setGraphs();
      this.setState({
        show_dashboard: "simple",
        dashboardLoader: false,
      });
    } else if (
      getCountsInfo.data.status === 200 &&
      getCountsInfo.data.message === "success"
    ) {
      if (getCountsInfo.data.data && getCountsInfo.data.data.length > 0 && getCountsInfo.data.iframeData !== "integration_disabled") {
        this.setState({
          show_dashboard: "report",
          iframe_url: getCountsInfo.data.iframeData,
          dashboardLoader: false,
        });
      } else {
        await this.setServiceList();
        await this.setGraphs();
        this.setState({
          show_dashboard: "simple",
          dashboardLoader: false,
        });
      }
    } else {
      await this.setServiceList();
      await this.setGraphs();
      this.setState({
        show_dashboard: "simple",
        dashboardLoader: false,
      });
    }
    //console.log("dateFirstString: ", dateFirstString);
  }

  viewModal = async (value, tableMeta) => {
    console.log("[viewModal]value: ", value);
    // console.log("[viewModal]tableMeta: ", tableMeta.rowIndex);
  };

  setGraphs = async () => {
    let getCountsInfo = await getDashboardCounts(
      auth.getAccount(),
      auth.getToken(),
      dateFirstString,
      dateString
    );
    //console.log("Totals: ", getCountsInfo.data.data);
    if (
      getCountsInfo.data.status === 403 ||
      getCountsInfo.data.errors === "authentication missing" ||
      getCountsInfo.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (getCountsInfo.data.status === 404) {
      // window.location.replace("/error");
    } else if (
      getCountsInfo.data.status === 200 &&
      getCountsInfo.data.message === "success"
    ) {
      /*this.setState({
        totalRegistered: getCountsInfo.data.data["total_registered"],
        totalActive: getCountsInfo.data.data["total_active"],
        monthlyBills: getCountsInfo.data.data["monthly_bills"],
        paidBills: getCountsInfo.data.data["paid_bills"],
      });*/
      this.setState({
        totalRegistered: getCountsInfo.data.all_clients,
        totalActive: getCountsInfo.data.all_active_clients,
        monthlyBills: 0,
        paidBills: 0,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  setServiceList = async () => {
    const servicesResponce = await listServices(
      auth.getAccount(),
      auth.getToken()
    );

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  // header = () => {
  //   return (
  //     <div className="nk-block-head nk-block-head-sm">
  //       <div className="nk-block-between">
  //         <div className="nk-block-head-content" style={{ mrginLeft: "2.5%" }}>
  //           <h3 className="nk-block-title page-title">
  //             <strong>Welcome to</strong> {auth.getCompName()}{" "}
  //             <strong>Dashboard</strong>
  //           </h3>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  render() {
    $("#Services_Block.nk-content").css("padding", "0px");
    $("#Services_Block.nk-content .container-fluid").css("padding-left", "0px");
    $("#Services_Block.nk-content .container-fluid").css(
      "padding-right",
      "0px"
    );

    $("#Analytics_Block.nk-content").css("padding", "0px");
    $("#Analytics_Block.nk-content").css("padding-bottom", "20px");
    $("#Analytics_Block.nk-content .container-fluid").css(
      "padding-left",
      "0px"
    );
    $("#Analytics_Block.nk-content .container-fluid").css(
      "padding-right",
      "0px"
    );
    $("#HeadContent").hide();
    $("#HeadContentAgent").hide();

    $("#HeadContentSales").hide();
    $("#HeadContentQuotation").hide();
    $("#HeadContentRfq").hide();

    $("#Agents_Block.nk-content").css("padding", "0px");
    $("#Agents_Block.nk-content .container-fluid").css("padding-left", "0px");
    $("#Agents_Block.nk-content .container-fluid").css("padding-right", "0px");

    $("#Clients_Block.nk-content").css("padding", "0px");
    $("#Clients_Block.nk-content .container-fluid").css("padding-left", "0px");
    $("#Clients_Block.nk-content .container-fluid").css("padding-right", "0px");

    $(".tab-content .card-bordered").css("border", "0px");
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">
                <div className="nk-block-head nk-block-head-sm">
                  <div className="nk-block-between">
                    <div className="nk-block-head-content">
                      <h3 className="nk-block-title page-title">
                        <strong>Welcome to</strong> {auth.getCompName()}{" "}
                        <strong>Dashboard</strong>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              {/* {this.header()} */}

              {this.state.show_dashboard === "simple" ? (
                <>
                  <div className="nk-block">
                    {/* FIRST ROW START */}

                    <div className="row g-gs" style={{ clear: "both" }}>
                      <div className="col-lg-12 col-xxl-12">
                        <div
                          className="card card-bordered"
                          style={{ clear: "both" }}
                        >
                          <div className="card-inner">
                            <div className="card-title-group pb-3 g-2">
                              <div className="card-title card-title">
                                <h4 className="title">
                                  {localization.dashboardPage.executiveHeading}
                                </h4>
                                <p>
                                  {localization.dashboardPage.executiveText}
                                </p>
                              </div>
                            </div>
                            <div className="analytic-ov">
                              <div className="analytic-data-group analytic-ov-group g-3">
                                <div className="col-sm-12 col-lg-4 col-xxl-4">
                                  <div className="nk-order-ovwg-data buy  text-center">
                                    <div className="amount">
                                      {this.state.totalRegistered}{" "}
                                      <small className="currenct currency-usd"></small>
                                    </div>
                                    <div className="info">
                                      {
                                        localization.dashboardPage
                                          .totalRegistered
                                      }{" "}
                                      <strong>
                                        {" "}
                                        <span className="currenct currency-usd"></span>
                                      </strong>
                                    </div>
                                    <div className="title">
                                      <em className="icon ni ni-users"></em>{" "}
                                      {
                                        localization.dashboardPage
                                          .totalRegisteredHead
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-lg-4 col-xxl-4">
                                  <div className="nk-order-ovwg-data sell  text-center">
                                    <div className="amount">
                                      {this.state.totalActive}{" "}
                                      <small className="currenct currency-usd"></small>
                                    </div>
                                    <div className="info">
                                      {localization.dashboardPage.totalActive}{" "}
                                      <strong>
                                        {" "}
                                        <span className="currenct currency-usd"></span>
                                      </strong>
                                    </div>
                                    <div className="title">
                                      <em className="icon ni ni-user-check"></em>{" "}
                                      {
                                        localization.dashboardPage
                                          .totalActiveHead
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-12 col-lg-4 col-xxl-4">
                                  <div className="nk-order-ovwg-data buy  text-center">
                                    <div className="amount">
                                      ${this.state.paidBills}{" "}
                                      <small className="currenct currency-usd">
                                        USD
                                      </small>
                                    </div>
                                    <div className="info">
                                      {localization.dashboardPage.totalPaid}
                                      <strong>
                                        {" "}
                                        <span className="currenct currency-usd"></span>
                                      </strong>
                                    </div>
                                    <div className="title">
                                      <em className="icon ni ni-sign-usd-alt"></em>{" "}
                                      {localization.dashboardPage.totalPaidHead}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />

                  <div className="row g-gs" style={{ clear: "both" }}>
                    <div className="col-lg-12">
                      <div
                        className="card card-bordered"
                        style={{ clear: "both" }}
                      >
                        <div className="card-inner">
                          <ul class="nav nav-tabs">
                            <li class="nav-item ">
                              <a
                                class="nav-link active"
                                data-toggle="tab"
                                href="#tabItem6"
                              >
                                <em class="icon ni ni-users"></em>
                                <span>
                                  {localization.dashboardPage.salesOrderTab}
                                </span>
                              </a>
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                data-toggle="tab"
                                href="#tabItem7"
                              >
                                <em class="icon ni ni-user-list"></em>
                                <span>
                                  {localization.dashboardPage.quotationsTab}
                                </span>
                              </a>
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link "
                                data-toggle="tab"
                                href="#tabItem5"
                              >
                                <em class="icon ni ni-card-view"></em>
                                <span>{localization.dashboardPage.rfqTab}</span>
                              </a>
                            </li>
                          </ul>
                          <div class="tab-content" style={{ marginTop: 0 }}>
                            <div class="tab-pane active" id="tabItem6">
                              <div className="row">
                                <div className="col-md-12">
                                  <SalesOrders from="dashboard" />
                                </div>
                              </div>
                            </div>
                            <div class="tab-pane" id="tabItem7">
                              <div className="row">
                                <div className="col-md-12">
                                  <Quotations from="dashboard" />
                                </div>
                              </div>
                            </div>
                            <div class="tab-pane " id="tabItem5">
                              <div className="row">
                                <div className="col-md-12">
                                  <Rfqs from="dashboard" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : this.state.show_dashboard === "report" ? (
                <iframe
                  src={this.state.iframe_url}
                  height="100%"
                  width="100%"
                  style={{
                    height: "100vh",
                    marginTop: "30px",
                    marginLeft: "-5px",
                    border: "0",
                  }}
                  id="report_iframe"
                ></iframe>
              ) : (
                <div className="card card-bordered" style={{ clear: "both" }}>
                  <div className="card-inner">{tableLoader()}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
