import { React } from "react";
import { Component, Fragment } from "react";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listClientAgreements,
  deleteClientAgreement,
  listServicesClients,
  assignAgreementToServices,
  listClientAgreementsArchived,
  submitAgreementDefault
} from "./../../../config/api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import dateFormat, { masks } from "dateformat";
import localization from "../../../localization/localization";

const auth = new HelperClass();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class Agentagreement extends Component {
  constructor() {
    super();
    this.state = {
      agreementsData: [],
      archivedagreementsData: [],
      errorMessaged: "",
      errorMessage: "",
      successMessaged: "",
      title: "",
      name: "",
      logo: "",
      agent_email: auth.getEmail(),
      delete_id: "",
      agent_password: "",
      c_password: "",
      description: "",
      successMessage: "",
      tableLoader: true,
      domain: "",
      step: 1,
      dnsList: [],
      a_ip: "",
      is_verified: 0,
      list_services: [],
      assigned_services: [],
      did_service: [],
      emptyService: 0,
      assignSuccessMessage: "",
      assignErrorMessage: "",

      defaultErrorMessage: "",
      defaultSuccessMessage: "",
      default_action_button: false,
      default_title: "",
      default_button_text: "",
      default_body_text: "",
      default_agreement_accountno: "",
      default_value_to_change: "",

      columns: [
        { name: "Sequence #" },
        {
          name: "Title",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.titleText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.statusText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Mandatory",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.signupText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Type",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.typeText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Date Added",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "Agreement Type",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.serviceText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.addMenuHistory(
                                      "/clients/editagreement/" +
                                        tableMeta.rowData[7]
                                    )
                                  }
                                  title="Edit Agreement"
                                  style={{ cursor: "pointer" }}
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>
                                    {
                                      localization.clientsAgreementsListing
                                        .agreementsListingLink1
                                    }
                                  </span>
                                </a>
                              </li>
                              {tableMeta.rowData[9] && tableMeta.rowData[9] === "duplicate" ? (
                                <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.setAgreementDefault(value, tableMeta.rowData[9])
                                  }
                                  title="Default agreements will be applied to newly registered clients."
                                  style={{ cursor: "pointer" }}
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-link-group"></em>
                                  <span>Make Default</span>
                                </a>
                                </li>
                              ) : (
                                <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.setAgreementDefault(value, tableMeta.rowData[9])
                                  }
                                  title="Agreements that are not set as default will not be assigned to newly registered clients."
                                  style={{ cursor: "pointer" }}
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-link-group"></em>
                                  <span>Unset Default</span>
                                </a>
                                </li>
                              )}
                              
                              <li>
                                <a
                                  onClick={() =>
                                    this.deleteAlert(tableMeta.rowData[7])
                                  }
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete Agreement"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>
                                    {
                                      localization.clientsAgreementsListing
                                        .agreementsListingLink2
                                    }
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "Archived",
          options: {
            display: "false",
            customBodyRender: (value, tableMeta) => {
              return <div>{this.statusText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Created As",
          options: {
            display: "false",
          },
        },
      ],
      columnsExtended: [
        { name: "Title" },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.statusTextArchive(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Mandatory",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.signupTextArchive(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Type",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.typeTextArchive(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Date Added",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "Agreement Type",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.serviceTextArchive(value, tableMeta)}</div>;
            },
          },
        },
        /*{
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.addMenuHistory(
                                      "/clients/editagreement/" +
                                        tableMeta.rowData[6]
                                    )
                                  }
                                  title="Edit Agreement"
                                  style={{ cursor: "pointer" }}
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Edit Agreement</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={() =>
                                    this.deleteAlert(tableMeta.rowData[6])
                                  }
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete Agreement"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Delete Permanent</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },*/
        {
          name: "Archived",
          options: {
            display: "false",
            customBodyRender: (value, tableMeta) => {
              return <div>{this.statusTextArchive(value, tableMeta)}</div>;
            },
          },
        },
      ],
      tableData: [],
    };
  }

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy");
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };
  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    const servicesResponce = await listClientAgreements(
      auth.getAccount(),
      auth.getToken()
    );

    //console.log("Agreement Res: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        agreementsData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  ClientAgreementsArchivedFun = async () => {
    const servicesResponce = await listClientAgreementsArchived(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("Archived Agreement Res: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        archivedagreementsData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  reLoadClients = async () => {
    const servicesResponce = await listClientAgreements(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("Agreement Res: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        agreementsData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  setAgreementDefault = (agreement_accountno, default_value) => {
    console.log("agreement_accountno: ", agreement_accountno);
    console.log("default_value: ", default_value);
    let default_title = "Unset Default";
    let default_body_text = "Are you sure you want to unset this agreement as the default? Agreements that are not set as default will not be assigned to newly registered clients.";
    let default_button_text = "UNSET DEFAULT";
    let default_value_to_change = "duplicate";
    if(default_value === "duplicate"){
      default_title = "Make Default";
      default_body_text = "Are you sure you want to set this agreement as the default? Default agreements will be applied to newly registered clients.";
      default_button_text = "MAKE DEFAULT";
      default_value_to_change = null;
    }
    this.setState({
      default_action_button: false,
      defaultErrorMessage: "",
      defaultSuccessMessage: "",
      default_title: default_title,
      default_body_text: default_body_text,
      default_button_text: default_button_text,
      default_agreement_accountno: agreement_accountno,
      default_value_to_change: default_value_to_change
    });
    window.$("#modalMakeDefault").modal("show");
  };

  submitAgreementDefault = async () => {
    const {default_agreement_accountno, default_value_to_change} = this.state;
    this.setState({
      default_action_button: true,
      defaultErrorMessage: "",
      defaultSuccessMessage: "",
    });
    const servicesResponce = await submitAgreementDefault(
      auth.getAccount(),
      auth.getToken(),
      default_agreement_accountno,
      default_value_to_change
    );

    console.log("Agreement Res: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        default_action_button: false,
        defaultErrorMessage: "There is some error while processing your request.",
        defaultSuccessMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        defaultErrorMessage: "",
        defaultSuccessMessage: "Agreement type updated successfully.",
      });
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } else {
      this.setState({
        default_action_button: false,
        defaultErrorMessage: "There is some error while processing your request.",
        defaultSuccessMessage: "",
      });
      //window.location.replace("/error");
    }
  };

  modalHideRemoveAlert = (modal_id) => {
    window.$("#" + modal_id).modal("hide");
  };

  publishText = (value, tableMeta) => {
    let is_published = tableMeta.rowData[1];
    if (is_published === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  titleText = (value, tableMeta) => {
    let title = tableMeta.rowData[1];
    let is_archived = tableMeta.rowData[9];
    //console.log("titleText: ");
    if (is_archived === "duplicate") {
      return title;
    } else {
      return (
        <p>
          {title}
          {"  "}
          <Tooltip
            title="Default agreements will be applied to all newly added clients."
            placement="right"
          >
            <span className="badge badge-outline-primary">Default</span>
          </Tooltip>
        </p>
      );
    }
  };

  statusText = (value, tableMeta) => {
    let is_status = tableMeta.rowData[2];
    let is_archived = tableMeta.rowData[8];
    //console.log("statusText: " + is_status + ": is_archived: " + is_archived);
    if (is_status === 1 && is_archived === 0) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-na"></em> Inactive
        </span>
      );
    }
  };

  statusTextArchive = (value, tableMeta) => {
    let is_status = tableMeta.rowData[1];
    let is_archived = tableMeta.rowData[7];
    if (is_status === 1 && is_archived === 0) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-na"></em> Inactive
        </span>
      );
    }
  };

  signupText = (value, tableMeta) => {
    let is_signup = tableMeta.rowData[3];
    if (is_signup === 1) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    }
  };

  signupTextArchive = (value, tableMeta) => {
    let is_signup = tableMeta.rowData[2];
    if (is_signup === 1) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    }
  };

  typeText = (value, tableMeta) => {
    let is_signup = tableMeta.rowData[4];
    if (is_signup === "esign") {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-edit-fill"></em> E-Signature
        </span>
      );
    } else if (is_signup === "accept") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-done"></em> Acceptance only
        </span>
      );
    }
  };

  typeTextArchive = (value, tableMeta) => {
    let is_signup = tableMeta.rowData[3];
    if (is_signup === "esign") {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-edit-fill"></em> E-Signature
        </span>
      );
    } else if (is_signup === "accept") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-done"></em> Acceptance only
        </span>
      );
    }
  };

  serviceText = (value, tableMeta) => {
    let is_signup = tableMeta.rowData[6];
    if (is_signup === "did") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> DID Origination
        </span>
      );
    } else if (is_signup === "tfn") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> Toll Free Origination
        </span>
      );
    } else if (is_signup === "term") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> Voice Termination
        </span>
      );
    } else if (is_signup === "sms") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> SMS Inbound / Outbound
        </span>
      );
    } else if (is_signup === "teams") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> Microsoft Teams UCAAS
        </span>
      );
    } else if (is_signup === "tfn_term") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> Toll Free Termination
        </span>
      );
    } else if (is_signup === "az_term") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> International A-Z
          Termination
        </span>
      );
    } else if (is_signup === "live_agent") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> Live Agent
        </span>
      );
    } else if (is_signup === "cnam") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> CNAM
        </span>
      );
    } else if (is_signup === "main") {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-row-view"></em> Client Agreement
        </span>
      );
    }
  };

  serviceTextArchive = (value, tableMeta) => {
    let is_signup = tableMeta.rowData[5];
    if (is_signup === "did") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> DID Origination
        </span>
      );
    } else if (is_signup === "tfn") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> Toll Free Origination
        </span>
      );
    } else if (is_signup === "term") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> Voice Termination
        </span>
      );
    } else if (is_signup === "sms") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> SMS Inbound / Outbound
        </span>
      );
    } else if (is_signup === "teams") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> Microsoft Teams UCAAS
        </span>
      );
    } else if (is_signup === "tfn_term") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> Toll Free Termination
        </span>
      );
    } else if (is_signup === "az_term") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> International A-Z
          Termination
        </span>
      );
    } else if (is_signup === "live_agent") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> Live Agent
        </span>
      );
    } else if (is_signup === "cnam") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-row-view"></em> CNAM
        </span>
      );
    } else if (is_signup === "main") {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-row-view"></em> Client Agreement
        </span>
      );
    }
  };

  defaultText = (value, tableMeta) => {
    let is_default = tableMeta.rowData[2];
    if (is_default === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  serviceAlert = async (value) => {
    let id = value;
    this.setState({
      delete_id: id,
      assignSuccessMessage: "",
      assignErrorMessage: "",
    });
    const agreementResponce = await listServicesClients(
      auth.getAccount(),
      auth.getToken(),
      id
    );

    console.log("serviceAlert: ", agreementResponce.data);

    if (
      agreementResponce.data.status === 403 ||
      agreementResponce.data.errors === "authentication missing" ||
      agreementResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      agreementResponce.data.status === 404 &&
      agreementResponce.data.message === "fail" &&
      agreementResponce.data.note !== ""
    ) {
      this.setState({
        list_services: [],
        assignErrorMessage: "There are no services.",
      });
    } else if (
      agreementResponce.data.status === 200 &&
      agreementResponce.data.message === "success"
    ) {
      this.setState({
        emptyService: true,
        disabled: false,
        list_services: agreementResponce.data.data,
        assigned_services: agreementResponce.data.assigned,
      });
    } else {
      this.setState({
        list_services: [],
        assignErrorMessage: "There are no services.",
      });
    }
    window.$("#modalAssignService").modal("show");
  };

  assignServices = async () => {
    const { did_service, delete_id } = this.state;
    console.log("did_service: ", did_service);
    console.log("delete_id: ", delete_id);
    var checkedValues = $("input:checkbox.vdrSelected:checked")
      .map(function () {
        return this.value;
      })
      .get();
    console.log("checkedValues: ", checkedValues);
    if (checkedValues.length === 0) {
      this.setState({
        assignErrorMessage: "Please choose the service.",
        assignSuccessMessage: "",
        disabled: false,
      });
    } else {
      this.setState({
        assignErrorMessage: "",
        assignSuccessMessage: "",
        disabled: false,
      });
      const agreementResponce = await assignAgreementToServices(
        auth.getAccount(),
        auth.getToken(),
        JSON.stringify(checkedValues),
        delete_id
      );

      console.log("status: ", agreementResponce.data);

      if (
        agreementResponce.data.status === 403 ||
        agreementResponce.data.errors === "authentication missing" ||
        agreementResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        agreementResponce.data.status === 404 &&
        agreementResponce.data.note === "empty"
      ) {
        this.setState({
          assignErrorMessage: agreementResponce.data.message,
          assignSuccessMessage: "",
          disabled: false,
        });
      } else if (
        agreementResponce.data.status === 200 &&
        agreementResponce.data.message === "success"
      ) {
        this.setState({
          assignErrorMessage: "",
          assignSuccessMessage: "Agreement assigned successfully.",
          disabled: false,
        });
        this.componentDidMount();
        setTimeout(function () {
          window.$("#modalAssignService").modal("hide");
        }, 3000);
      } else {
        this.setState({
          assignErrorMessage:
            "There is some error while assigning the agreement.",
          assignSuccessMessage: "",
          disabled: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        assignErrorMessage: "",
        assignSuccessMessage: "",
        disabled: false,
      });
    }, 4000);
  };

  modalHideAssign = () => {
    window.$("#modalAssignService").modal("hide");
  };

  deleteAlert = async (value) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let id = value;
    this.setState({
      delete_id: id,
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  deleteAgreement = async () => {
    let agreement_id = this.state.delete_id;
    const agreementResponce = await deleteClientAgreement(
      auth.getAccount(),
      auth.getToken(),
      agreement_id
    );

    console.log("deleteAgreement: ", agreementResponce.data);
    //$("#btnloader").show();
    if (
      agreementResponce.data.status === 403 ||
      agreementResponce.data.errors === "authentication missing" ||
      agreementResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      agreementResponce.data.status === 404 &&
      agreementResponce.data.message === "fail" &&
      agreementResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessaged: agreementResponce.data.note,
        successMessaged: "",
        disabled: false,
      });
      //$("#btnloader").hide();
    } else if (
      agreementResponce.data.status === 200 &&
      agreementResponce.data.message === "success"
    ) {
      //await this.reLoadClients();
      this.setState({
        errorMessaged: "",
        successMessaged: "Agreement deleted successfully.",
        disabled: false,
      });

      setTimeout(function () {
        window.location.reload();
        window.$("#modalDeleteAlert").modal("hide");
      }, 3000);
      //$("#btnloader").hide();
    } else {
      this.setState({
        errorMessaged: agreementResponce.data.message,
        successMessaged: "",
        disabled: false,
      });
      //$("#btnloader").hide();
      //window.location.replace("/error");
    }
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeStep = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChangeFile = async (event) => {
    this.setState({
      rate_deck_file: event.target.files[0],
    });
    //await this.sendFileData(event.target.files[0]);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({ logo: event.target.files[0] });
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MODAL EDIT PROFILE START */}
          <div className="modal fade" tabIndex="-1" id="modalDeleteAlert">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  style={{ cursor: "pointer" }}
                  className="close"
                  onClick={() => {
                    this.modalHideDel();
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                    <h4 className="nk-modal-title">Delete Agreement?</h4>
                    <div className="nk-modal-text">
                      {this.state.errorMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="caption-text">
                        Are you sure you want to delete this Agreement?
                      </div>
                    </div>
                    <div className="nk-modal-action">
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-primary"
                        onClick={() => {
                          this.modalHideDel();
                        }}
                      >
                        CANCEL
                      </a>
                      {"  "}
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-danger"
                        onClick={() => {
                          this.deleteAgreement();
                        }}
                      >
                        DELETE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* MSA WARNING END */}

          {/*### Assign Service Modal ###*/}
          <div className="modal fade" tabIndex="-1" id="modalAssignService">
            <div
              className="modal-dialog modal-lg modal-dialog-top"
              role="document"
            >
              <div className="modal-content">
                <a
                  style={{ cursor: "pointer" }}
                  className="close"
                  onClick={() => {
                    this.modalHideAssign();
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-header">
                  <h5 className="modal-title">
                    <em className="icon ni ni-link-group"></em> Assign agreement
                    to the service
                  </h5>
                </div>
                <div className="modal-body">
                  {this.state.assignErrorMessage !== "" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "15px" }}
                    >
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.assignErrorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.assignSuccessMessage !== "" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "15px" }}
                    >
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.assignSuccessMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div
                    className="col-lg-12 col-md-12 col-xxl-12"
                    style={{ marginBottom: "30px" }}
                  >
                    <div id="accordion-2" className="accordion accordion-s3">
                      <div className="accordion-item">
                        <a
                          href="#"
                          className="accordion-head"
                          data-toggle="collapse"
                          data-target="#accordion-item-2-1"
                          style={{ borderBottom: "1px solid #dbdfea" }}
                        >
                          <h6 className="title">
                            Select services to assign to the agreement
                          </h6>
                          <span className="accordion-icon"></span>
                        </a>
                        <div
                          className="accordion-body collapse show"
                          id="accordion-item-2-1"
                          data-parent="#accordion-2"
                        >
                          <div className="accordion-inner">
                            {this.state.list_services ? (
                              <>
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group select_services">
                                    <label className="form-label">
                                      Select Services
                                    </label>
                                    <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                      {this.state.list_services.map(
                                        (splits, index) => (
                                          <li key={`addser${index}`}>
                                            <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input vdrSelected"
                                                id={`cp1-package-${index}`}
                                                name="did_service"
                                                defaultChecked={false}
                                                value={splits[0]}
                                                onChange={this.handleChange}
                                                ref="did_service"
                                                required=""
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={`cp1-package-${index}`}
                                              >
                                                <span className="d-flex align-center">
                                                  <span className="user-avatar sq bg-primary-dim">
                                                    <span className="icon ni ni-briefcase"></span>
                                                  </span>
                                                  <span className="ms-2">
                                                    <span className="lead-text">
                                                      {splits[1]}
                                                    </span>
                                                    <span className="sub-text">
                                                      {splits[2]}
                                                    </span>
                                                  </span>
                                                </span>
                                              </label>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  className="form-group text-right col-md-12"
                                  style={{ marginTop: "20px" }}
                                >
                                  <button
                                    className="btn btn-lg btn-primary"
                                    type="button"
                                    disabled={this.state.disabled}
                                    onClick={() => {
                                      this.assignServices();
                                    }}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <a
                          href="#"
                          className="accordion-head collapsed"
                          data-toggle="collapse"
                          data-target="#accordion-item-2-2"
                          style={{ borderBottom: "1px solid #dbdfea" }}
                        >
                          <h6 className="title">Already Assigned Services</h6>
                          <span className="accordion-icon"></span>
                        </a>
                        <div
                          className="accordion-body collapse"
                          id="accordion-item-2-2"
                          data-parent="#accordion-2"
                        >
                          <div className="accordion-inner">
                            {this.state.assigned_services.length > 0 ? (
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col">Service</th>
                                    <th scope="col">Date Assigned</th>
                                    <th scope="col">Update Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.assigned_services.map(
                                    (sel_ser, inde) => (
                                      <tr key={`sel_ser${inde}`}>
                                        <td>{sel_ser[0]}</td>
                                        <td>
                                          {sel_ser[1] !== "" ? (
                                            <>
                                              {dateFormat(
                                                sel_ser[1],
                                                "mmmm dS, yyyy, h:MM:ss TT"
                                              )}
                                            </>
                                          ) : null}
                                        </td>
                                        <td>
                                          {sel_ser[2] !== "" ? (
                                            <>
                                              {dateFormat(
                                                sel_ser[2],
                                                "mmmm dS, yyyy, h:MM:ss TT"
                                              )}
                                            </>
                                          ) : null}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            ) : (
                              <div
                                className="example-alert"
                                style={{ marginBottom: "20px" }}
                              >
                                <div className="alert alert-pro alert-danger">
                                  <div className="alert-text">
                                    <h4>Note</h4>
                                    <p>
                                      Services not assigned to this agreement.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*### Assign Service Modal ###*/}

          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  <PageTitle
                    name={localization.clientsAgreementsListing.heading}
                    icon="icon ni ni-file-docs"
                  />
                </h3>
              </div>
              <div className="nk-block-head-content" id="HeadContent">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    href="#"
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                  >
                    <em className="icon ni ni-more-v"></em>
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      <li className="nk-block-tools-opt">
                        <Link
                          color="inherit"
                          onClick={() =>
                            this.addMenuHistory("/clients/addagreement")
                          }
                          className="btn btn-primary"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            color: "#fff",
                          }}
                        >
                          <em className="icon ni ni-plus-round"></em>
                          &nbsp;
                          {localization.clientsAgreementsListing.addNewButton}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-block">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#tabItem1">
                  {localization.clientsAgreementsListing.allAgreementsTab}
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#tabItem2"
                  onClick={this.ClientAgreementsArchivedFun}
                >
                  {localization.clientsAgreementsListing.archivedTab}
                </a>
              </li>
            </ul>
            {/* Table 5th Col Start */}
            <div class="tab-content">
              <div class="tab-pane active" id="tabItem1">
                <div className="row g-gs">
                  <div className="col-xxl-12">
                    {this.state.errorMessage !== "" ? (
                      <div className="example-alert">
                        <div className="alert alert-pro alert-danger">
                          <div className="alert-text">
                            <h4>Error</h4>
                            <p>{this.state.errorMessage}</p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.successMessage !== "" ? (
                      <div className="example-alert">
                        <div className="alert alert-pro alert-success">
                          <div className="alert-text">
                            <h4>Success</h4>
                            <p>{this.state.successMessage}</p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div class="example-alert">
                      <div class="alert alert-gray alert-icon">
                        <em class="icon ni ni-alert-circle"></em>{" "}
                        <strong>
                          Default agreements will be applied to all newly added
                          clients.
                        </strong>
                      </div>
                    </div>
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columns}
                          tableData={this.state.agreementsData}
                          title={
                            localization.clientsAgreementsListing
                              .allAgreementsTabSub
                          }
                        />
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="tabItem2">
                <div className="row g-gs">
                  <div className="col-xxl-12">
                    {this.state.errorMessage !== "" ? (
                      <div className="example-alert">
                        <div className="alert alert-pro alert-danger">
                          <div className="alert-text">
                            <h4>Error</h4>
                            <p>{this.state.errorMessage}</p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.successMessage !== "" ? (
                      <div className="example-alert">
                        <div className="alert alert-pro alert-success">
                          <div className="alert-text">
                            <h4>Success</h4>
                            <p>{this.state.successMessage}</p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTableExtended
                          columns={this.state.columnsExtended}
                          tableData={this.state.archivedagreementsData}
                          title={
                            localization.clientsAgreementsListing.archivedTabSub
                          }
                        />
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table 5th Col End */}
          </div>
          {/* Alert INVOICE */}
          <div class="modal fade" tabindex="-1" id="modalMakeDefault">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em
                          class="icon ni ni-alert-circle"
                          style={{ fontSize: "3rem" }}
                        ></em>
                        
                        <h4 className="nk-modal-title">
                          {this.state.default_title}
                        </h4>
                        {this.state.defaultErrorMessage !== "" ? (
                            <div class="alert alert-pro alert-danger mb-4">
                              <div class="alert-text">
                                <h6>Error</h6>
                                <p>{this.state.defaultErrorMessage}</p>
                              </div>
                            </div>
                          ) : null}
                          {this.state.defaultSuccessMessage !== "" ? (
                            <div class="alert alert-pro alert-success mb-4">
                              <div class="alert-text">
                                <h6>Success</h6>
                                <p>{this.state.defaultSuccessMessage}</p>
                              </div>
                            </div>
                          ) : null}
                        <div className="nk-modal-text">
                          <div className="caption-text">
                          {this.state.default_body_text}
                          </div>
                        </div>
                        <div className="nk-modal-action">
                        {this.state.default_action_button === true ? (
                          <button
                                  className="btn btn-lg btn-primary"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span> Processing... </span>
                                </button>
                        ) : (
                          <>
                          <a
                            style={{ cursor: "pointer" }}
                            className="btn btn-lg btn-light"
                            onClick={() => {
                              this.modalHideRemoveAlert("modalMakeDefault");
                            }}
                            disabled={this.state.disabled}
                          >
                            CLOSE
                          </a>
                          {"  "}
                          <a
                            style={{ cursor: "pointer" }}
                            className="btn btn-lg btn-mw btn-primary"
                            onClick={() => {
                              this.submitAgreementDefault();
                            }}
                            disabled={this.state.disabled}
                          >
                            {this.state.default_button_text}
                          </a>
                          </>)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Alert INVOICE */}
          {/*<div className="nk-block">
            <div className="row g-gs">
              <div className="col-xxl-12">
                {this.state.errorMessage !== "" ? (
                  <div className="example-alert">
                    <div className="alert alert-pro alert-danger">
                      <div className="alert-text">
                        <h4>Error</h4>
                        <p>{this.state.errorMessage}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.successMessage !== "" ? (
                  <div className="example-alert">
                    <div className="alert alert-pro alert-success">
                      <div className="alert-text">
                        <h4>Success</h4>
                        <p>{this.state.successMessage}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="nk-block nk-block-lg">
                  {this.state.tableLoader === true ? (
                    tableLoader()
                  ) : (
                    <DataTable
                      columns={this.state.columns}
                      tableData={this.state.agreementsData}
                      title="Client Agreements"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Agentagreement);
