import { React } from "react";
import { Component, Fragment } from "react";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  getCannedEmail,
  editCannedEmail,
  setDefaultTemplate,
  getDefaultEmailPreview,
} from "./../../../config/api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  AGENT_SERVER_URL,
} from "./../../../config/config";

import Step1 from "../../../components/Agents/Step1";
import Step2 from "../../../components/Agents/Step2";
import Step3 from "../../../components/Agents/Step3";

import TextField from "../../../components/InputFields/TextField";
import SelectField from "../../../components/InputFields/SelectField";
import MutextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import StepWizard from "react-step-wizard";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
//import { Editor } from "@tinymce/tinymce-react";
//import { CKEditor } from "@ckeditor/ckeditor5-react";
//import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import RichTextEditorComponent from "../../../components/Reseller/RichTextEditorComponent";
const auth = new HelperClass();

class Editemail extends Component {
  constructor() {
    super();
    this.state = {
      errorMessaged: "",
      errorMessage: "",
      successMessaged: "",
      successMessage: "",
      email_id: "",
      email_title: "",
      email_subject: "",
      email_template: "",
      tableLoader: false,
      isEditorReady: false,
      errorMessageDefault: "",
      successMessageDefault: "",
    };
  }

  async componentDidMount() {
    let email_id = this.props.props.match.params.id;
    console.log(email_id);
    const getEmailResponse = await getCannedEmail(
      auth.getAccount(),
      auth.getToken(),
      email_id
    );
    this.setState({
      email_id: email_id,
    });
    console.log("Agreement Res: ", getEmailResponse.data);
    if (
      getEmailResponse.data.status === 403 ||
      getEmailResponse.data.errors === "authentication missing" ||
      getEmailResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (getEmailResponse.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      getEmailResponse.data.status === 200 &&
      getEmailResponse.data.message === "success"
    ) {
      this.setState({
        email_id: getEmailResponse.data.data[0][0],
        email_title: getEmailResponse.data.data[0][1],
        email_subject: getEmailResponse.data.data[0][2],
        email_template: getEmailResponse.data.data[0][3],
        tableLoader: false,
        isEditorReady: true,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  openEditorHtmlModal = () => {
    window.$("#modalEditorHtml").modal("show");
  };

  saveEditorHtml = () => {
    const email_template = document.getElementById("editEditorHtml").value;
    this.setState(
      {
        email_template: email_template,
        isEditorReady: false,
      },
      () => {
        if (this.editor && this.editor.ui.view.toolbar) {
          this.editor.ui.view.toolbar.element.remove();
        }
        this.editor = null;

        setTimeout(() => {
          this.setState({ isEditorReady: true });
          window.$("#modalEditorHtml").modal("hide");
        }, 500);
      }
    );
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleEditorChange = (updatedContent) => {
    //console.log('updatedContent: ',updatedContent);
    this.setState({ email_template: updatedContent });
  };

  formSave = async () => {
    window.scrollTo(0, 0);
    const { email_title, email_subject, email_template } = this.state;

    var validated = true;

    if (email_title === "") {
      validated = false;
    } else if (email_subject === "") {
      validated = false;
    } else if (email_template === "") {
      validated = false;
    }
    if (validated === false) {
      return this.setState({
        errorMessage: "Please enter the required fields.",
      });
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await editCannedEmail(
        auth.getAccount(),
        auth.getToken(),
        email_title,
        email_subject,
        email_template,
        this.state.email_id
      );

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: "There is some error while updating the agreement.",
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Email updated successfully.",
          disabled: false,
        });
        setTimeout(() => {
          this.props.props.history.push("/CannedEmails");
        }, 3000);
      } else {
        this.setState({
          errorMessage: saveResponce.data.message,
          successMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  openDefaultModal = async () => {
    this.setState({
      errorMessageDefault: "",
      successMessageDefault: "",
      disabled: false,
    });
    window.$("#modalDefaultModal").modal("show");
  };

  closeResetForm = async () => {
    window.$("#modalDefaultModal").modal("hide");
  };

  confirmResetTemplate = async () => {
    this.setState({
      disabled: true,
    });
    const saveResponce = await setDefaultTemplate(
      auth.getAccount(),
      auth.getToken(),
      this.state.email_id
    );

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (saveResponce.data.status === 404) {
      this.setState({
        errorMessageDefault:
          "There was an error while resetting the template. Please try again.",
        successMessageDefault: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessageDefault: "",
        successMessageDefault: "Template reset to default successfully.",
        disabled: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      this.setState({
        errorMessageDefault: saveResponce.data.message,
        successMessageDefault: "",
        disabled: false,
      });
    }
  };

  preViewDefault = async () => {
    const getEmailResponse = await getDefaultEmailPreview(
      auth.getAccount(),
      auth.getToken(),
      this.state.email_id
    );
    console.log("getEmailResponse: ", getEmailResponse.data);
    if (
      getEmailResponse.data.status === 403 ||
      getEmailResponse.data.errors === "authentication missing" ||
      getEmailResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (getEmailResponse.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      getEmailResponse.data.status === 200 &&
      getEmailResponse.data.message === "success"
    ) {
      let emailTemplate = getEmailResponse.data.data[0][3];
      let logoUrl = getEmailResponse.data.data[0][6];

      if (emailTemplate && emailTemplate !== "") {
        emailTemplate = emailTemplate.replace(/{{comp_logo}}/g, logoUrl);
        emailTemplate = emailTemplate.replace(/<p>&nbsp;<\/p>/g, "");
      }
      this.setState({
        email_id: getEmailResponse.data.data[0][0],
        email_title: getEmailResponse.data.data[0][1],
        email_subject: getEmailResponse.data.data[0][2],
        email_template: emailTemplate,
        tableLoader: false,
        isEditorReady: true,
      });
      window.$("#modalPreviewDefaultModal").modal("show");
    } else {
      //window.location.replace("/error");
    }
  };

  closePreviewModal = () => {
    // Close the preview modal
    window.$("#modalPreviewDefaultModal").modal("hide");
  };

  render() {
    const fetchToken = async () => {
      try {
        const response = await fetch(
          AGENT_SERVER_URL + "agents/editor_token_url",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              request_type: "ckeditor-tokenUrl",
              user: auth.getAccount(),
            }),
          }
        );
        const data = await response.json();
        return data.token;
      } catch (error) {
        console.error("Failed to fetch token:", error);
        return null;
      }
    };
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MODAL EDIT PROFILE START */}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/CannedEmails")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Canned Emails
                  </Link>
                  <Typography color="textPrimary" className="">
                    Edit Email
                  </Typography>
                </Breadcrumbs>
              </div>
              <div>
                <Link
                  color="inherit"
                  onClick={() =>
                    window.open(
                      `/CannedEmails/previewEmail/${this.state.email_id}`,
                      "_blank"
                    )
                  }
                  className=""
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    gap: "3px",
                  }}
                >
                  <VisibilityIcon fontSize="inherit" className="" />
                  Preview
                </Link>
              </div>
            </div>
          </div>
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="row g-gs">
              <div className="col-xxl-12">
                <div className="nk-block nk-block-lg">
                  <div className="card card-bordered">
                    <div className="card-inner">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <h5
                        className="card-title"
                        style={{ marginBottom: "30px" }}
                      >
                        Update Email
                      </h5>
                      <form method="post" className="pageForm" id="pageForm">
                        <div className="row g-4">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="email_title"
                                name="email_title"
                                type="text"
                                label="Email Title"
                                value={this.state.email_title}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="email_subject"
                                name="email_subject"
                                type="text"
                                label="Email Subject"
                                value={this.state.email_subject}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>

                          <div className="col-lg-4"></div>
                          <div style={{ clear: "both" }}></div>

                          <div
  className="col-lg-12"
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  }}
>
  <h6 style={{ margin: 0, marginRight: "10px" }}>
    Email Content
  </h6>
  <div>
    <button
      className="btn btn-secondary btn-md mr-2"
      onClick={() => this.preViewDefault()}
      type="button"
    >
      <em className="icon ni ni-eye mr-1"></em>
      Preview Default Template
    </button>
    <button
      className="btn btn-secondary btn-md"
      onClick={() => this.openDefaultModal()}
      type="button"
    >
      <em class="icon ni ni-exchange mr-1"></em>
      Reset Default Template
    </button>
  </div>
</div>

                          <div className="col-lg-12">
                            <div className="form-group">
                              {this.state.isEditorReady ? (
                                <>
                                  <RichTextEditorComponent
                                    agreementContent={this.state.email_template}
                                    onContentChange={this.handleEditorChange}
                                  />
                                </>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div class="alert alert-fill alert-light alert-icon">
                              <em class="icon ni ni-alert-circle"></em>{" "}
                              <strong> NOTE :</strong> Please do not change or
                              remove anything between {"{{ }}"}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group text-right">
                              <button
                                type="button"
                                onClick={() => this.formSave()}
                                className="btn btn-lg btn-primary"
                                disabled={this.state.disabled}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table 5th Col End */}
            <div className="modal fade" tabIndex="-1" id="modalEditorHtml">
              <div
                className="modal-dialog modal-xl modal-dialog-top"
                role="document"
              >
                <div className="modal-content">
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                  <div className="modal-header">
                    <h5 className="modal-title">Editor HTML</h5>
                  </div>
                  <div className="modal-body">
                    <textarea
                      className="prettyprint lang-html"
                      id="editEditorHtml"
                      defaultValue={this.state.email_template}
                    />
                  </div>
                  <div
                    className="modal-footer"
                    style={{ justifyContent: "right" }}
                  >
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        onClick={() => this.saveEditorHtml()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

{/* START DEFAULT MODAL  */}
<div className="modal fade" tabIndex="-1" id="modalDefaultModal">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <a
                    style={{ cursor: "pointer" }}
                    className="close"
                    onClick={() => this.closeResetForm()}
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal">
                      <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-alert bg-warning"></em>

                      <h4 className="nk-modal-title">Reset Template?</h4>
                      <div className="nk-modal-text">
                        {this.state.errorMessageDefault !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>{this.state.errorMessageDefault}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.successMessageDefault !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="alert alert-pro alert-success">
                              <div className="alert-text">
                                <h4>Success</h4>
                                <p>{this.state.successMessageDefault}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="caption-text">
                          Are you sure you want to reset to the default template? Any unsaved changes will be lost.
                        </div>
                      </div>
                      <div className="nk-modal-action">
                        <a
                          style={{ cursor: "pointer" }}
                          className="btn btn-lg btn-mw btn-primary"
                          onClick={() => this.closeResetForm()}
                        >
                          CANCEL
                        </a>
                        {"  "}
                        <button
                          style={{ cursor: "pointer" }}
                          className={"btn btn-lg btn-mw btn-warning"}
                          onClick={() => {
                            this.confirmResetTemplate();
                          }}
                          disabled={this.state.disabled ? true : false}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* START DEFAULT MODAL  */}
            {/* PREVIEW DEFAULT MODAL  */}
            <div className="modal fade" tabIndex="-1" id="modalPreviewDefaultModal">
              <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Default Template Preview</h5>
                    <a
                      style={{ cursor: "pointer" }}
                      className="close"
                      onClick={() => this.closePreviewModal()}
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                  </div>
                  <div className="modal-body">
                    <div className="nk-block">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          <div className="email-preview" style={{ maxHeight: "60vh", overflow: "auto" }}>
                            {this.state.isEditorReady ? (
                              <div
                                className="email-preview-content"
                                dangerouslySetInnerHTML={{
                                  __html: this.state.email_template,
                                }}
                              />
                            ) : (
                              tableLoader()
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* PREVIEW DEFAULT MODAL  */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Editemail);
