import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { addSettings, addMsaInfo } from "../../../actions";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitleBreadCrumb from "../ExtraComponents/PageTitleBreadCrumb";
import HelperClass from "./../../../config/helperClass";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  listSubAgent
} from "./../../../config/api_calls";
import {
  ListAgentAssignRoles,
  editAgentAssignRole,
  ListSubAgentAssignRoles,
  editSubAgentAssignRole,
} from "../../../config/agent_role_api_calls";
import localization from "../../../localization/localization";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import { ValidateEmail } from "../../../config/utility";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();
class Subagents extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      tableLoader: true,
      value: "",
      signedData: [],
      unsignedData: [],
      isRevokeReasonActive: false,
      revoke_reason: "",
      unsigned_email_agreement_accountno: 0,
      liveagent_service: "",
      serviceData: [],
      serviceAssignedData: [],
      packagesAssignedData: [],
      client_services_welcome_content: "",
      client_services_email: "",
      company_id: "",
      company_title: "",
      errorMessage: "",
      successMessage: "",
      disabled: false,
      activation_button: false,
      activation_tick: false,
      activation_cross: false,
      client_services_client_id: "",
      agent_accountno: "",
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      assignRoleData: [],
      assignSubRoleData: [],
      changedRolesRights: [],
      changedSubRolesRights: [],
      errorMessageSubClientRole: "",
      successMessageSubClientRole: "",
      subassignRoleData: [],
      subassignSubRoleData: [],
      subchangedRolesRights: [],
      subchangedSubRolesRights: [],
      checkedRoleIds: [],
      checkedSubRoleIds: [],
      checkedName: "",
      checkedEmail: "",

      clientErrorMessage: "",
      clientSuccessMessage: "",
      main_client_name: "",
      agent_name: "",
      agent_email: "",
      agent_id: "",
      agent_title: "",
      invitation_comment: "",
      tableData_invitedClients: [],
      sub_activeTab: localStorage.getItem("sub_activeTab") || "tabItem8",

      columnServices: [
        { name: "Date Added" },
        { name: "Title" },
        { name: "Name" },
        { name: "Email" },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setStatus(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.subagentAssignRole(value, tableMeta)
                                  }
                                  title="Assign Menu"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-grid-c"></em>
                                  <span>Assign Menu</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  subagentAssignRole = async (value, tableMeta) => {
    console.log("value: ", value);
    const agentId = tableMeta.rowData[5];
    const agentEmail = tableMeta.rowData[3];
    this.setState({
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      assignRoleData: [],
      assignSubRoleData: [],
      changedRolesRights: [],
      changedSubRolesRights: [],
      agentRoleLoader: true,
      disableRoleBtn: false,
      disableSubRoleBtn: false,
      rolesAgentName: tableMeta.rowData[2] + " (" + tableMeta.rowData[1] + ")",
      checkedName: tableMeta.rowData[2],
      checkedEmail: tableMeta.rowData[3],
    });
    window.$("#modalSubAgentAssignRole").modal("show");
    this.getSubAgentRoleData(agentId, agentEmail);
  };

  getSubAgentRoleData = async (agentId, agentEmail) => {
    const listSubAgentAssignRoles = await ListSubAgentAssignRoles(
      auth.getAccount(),
      auth.getToken(),
      agentId,
      agentEmail
    );

    console.log("ListSubAgentRoles Res: ", listSubAgentAssignRoles.data.data);
    if (
      listSubAgentAssignRoles.data.status === 403 ||
      listSubAgentAssignRoles.data.errors === "authentication missing" ||
      listSubAgentAssignRoles.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listSubAgentAssignRoles.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listSubAgentAssignRoles.data.status === 200 &&
      listSubAgentAssignRoles.data.message === "success"
    ) {
      const assignRoleData = listSubAgentAssignRoles.data.data.roles;
      const assignSubRoleData = listSubAgentAssignRoles.data.data.subroles;
      const allRoleData = listSubAgentAssignRoles.data.data.Allroles.map(
        (role) => {
          const isActiveRole = assignRoleData.some(
            (assignedRole) =>
              assignedRole.alt === role.alt && assignedRole.isactive === 1
          );
          return {
            ...role,
            isChecked: isActiveRole,
          };
        }
      );

      const allSubRoleData = listSubAgentAssignRoles.data.data.Allsubroles.map(
        (subRole) => {
          const isActiveSubRole = assignSubRoleData.some(
            (assignedSubRole) =>
              assignedSubRole.alt === subRole.alt &&
              assignedSubRole.isactive === 1
          );
          return {
            ...subRole,
            isChecked: isActiveSubRole,
          };
        }
      );

      this.setState({
        assignRoleData,
        assignSubRoleData,
        allRoleData,
        allSubRoleData,
        agentRoleLoader: false,
      });

      const initialActiveRoles = assignRoleData
        .filter((role) => role.isactive === 1)
        .map((role) => ({
          alt: role.alt,
          isChecked: true,
        }));

      const initialActiveSubRoles = assignSubRoleData
        .filter((subRole) => subRole.isactive === 1)
        .map((subRole) => ({
          id: subRole.id,
          isChecked: true,
        }));

      this.setState({
        changedRolesRights: initialActiveRoles,
        changedSubRolesRights: initialActiveSubRoles,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  setStatus = (value, tableMeta) => {
    let domain_str = tableMeta.rowData[4];
    //console.log("domain_str: ", domain_str);
    if (domain_str === "active") {
      return (
        <div>
          <span className="badge badge-outline-success">
            {" "}
            <em className="icon ni ni-done" title="Active"></em> Active
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-outline-danger">
            {" "}
            <em className="icon ni ni-alert-c"></em> Inactive
          </span>
        </div>
      );
    }
  };

  async componentDidMount() {
    let agent_id = this.props.props.match.params.id;
    this.setState({
      agent_id: agent_id,
    });
    const clientsResponce = await listSubAgent(
      auth.getAccount(),
      auth.getToken(),
      Number(agent_id)
    );

    console.log("Client Data: ", clientsResponce.data);
    if (
      clientsResponce.data.status === 403 ||
      clientsResponce.data.errors === "authentication missing" ||
      clientsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      clientsResponce.data.status === 404 ||
      clientsResponce.data.note === "missing"
    ) {
      // window.location.replace("/error");
      console.log("settings missing");
      this.setState({
        tableLoader: false,
      });
    } else if (
      clientsResponce.data.status === 200 &&
      clientsResponce.data.message === "success"
    ) {
      this.setState({
        tableData: clientsResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableLoader: false,
      });
      // window.location.replace("/error");
    }
  }

  addMenuHistoryLeft = (link) => {
    this.props.props.history.push(link);
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      client_services_welcome_content: content,
    });
    //console.log("Content was updated:", content);
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value;

    if (is_default === "inactive") {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-signin"></em> Inactive
        </span>
      );
    } else if (is_default === "active") {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;
    console.log(name);
    this.setState({
      [name]: value,
    });
  };


  formatPhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumberObj) {
      if (phoneNumberObj.country === "US") {
        const nationalNumber = phoneNumberObj
          .formatNational()
          .replace(/\D/g, ""); // e.g. "2123727200"
        const areaCode = nationalNumber.slice(0, 3);
        const firstPart = nationalNumber.slice(3, 6);
        const secondPart = nationalNumber.slice(6);
        return `(${areaCode}) ${firstPart}-${secondPart}`;
      }
      return phoneNumberObj.formatInternational();
    }
    return phoneNumber; // Return original number if parsing fails
  };

  setNewClientSettings = async () => {
    this.setState({
      clientErrorMessage: "",
      clientSuccessMessage: "",
      client_name: "",
      client_email: "",
      invitation_comment: "",
    });
    window.$("#modalAddNewClient").modal("show");
  };

  changedAllRolesRights = (event) => {
    const { name, checked } = event.target;

    // Update checkedRoleIds in the state
    const { checkedRoleIds } = this.state;
    const updatedCheckedRoleIds = checked
      ? [...checkedRoleIds, name] // add ID if checked
      : checkedRoleIds.filter((id) => id !== name); // remove ID if unchecked

    this.setState({ checkedRoleIds: updatedCheckedRoleIds });

    // Update allRoleData with isChecked property
    const { allRoleData, allSubRoleData } = this.state;
    const updatedAllRoleData = allRoleData.map((role) => {
      if (role.id.toString() === name) {
        return {
          ...role,
          isChecked: checked,
        };
      }
      return role;
    });

    // Update associated sub roles
    const updatedAllSubRoleData = allSubRoleData.map((subRole) => {
      if (subRole.role_id.toString() === name) {
        return {
          ...subRole,
          isChecked: checked,
        };
      }
      return subRole;
    });

    this.setState({
      allRoleData: updatedAllRoleData,
      allSubRoleData: updatedAllSubRoleData,
    });
  };

  changedAllSubRolesRights = (event) => {
    const { name, checked } = event.target;

    // Update checkedSubRoleIds in the state
    const { checkedSubRoleIds } = this.state;
    const updatedCheckedSubRoleIds = checked
      ? [...checkedSubRoleIds, name] // add ID if checked
      : checkedSubRoleIds.filter((id) => id !== name); // remove ID if unchecked

    this.setState({ checkedSubRoleIds: updatedCheckedSubRoleIds });

    // Update allSubRoleData with isChecked property
    const { allSubRoleData } = this.state;
    const updatedAllSubRoleData = allSubRoleData.map((subRole) => {
      if (subRole.tabdetailid.toString() === name) {
        return {
          ...subRole,
          isChecked: checked,
        };
      }
      return subRole;
    });

    this.setState({ allSubRoleData: updatedAllSubRoleData });
  };

  handleSubmitRoleCheckbox = async (name, email) => {
    this.setState({
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      disableRoleBtn: true,
    });

    const { allRoleData, allSubRoleData } = this.state;

    // Prepare the CheckRoleFields array with IDs and isactive status
    const CheckRoleFields = allRoleData.map((role) => ({
      id: role.id,
      isactive: role.isChecked ? 1 : 0,
    }));

    const CheckSubRoleFields = allSubRoleData.map((role) => ({
      id: role.tabdetailid,
      isactive: role.isChecked ? 1 : 0,
    }));

    try {
      // Call the backend API to save the roles
      const saveResponce = await editAgentAssignRole(
        auth.getAccount(),
        auth.getToken(),
        CheckRoleFields,
        CheckSubRoleFields,
        name,
        email
      );

      // Handle response status
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        this.setState({
          errorMessageAgentRole:
            "There is some error while updating the Channel Partner menu.",
          successMessageAgentRole: "",
          disableRoleBtn: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessageAgentRole: "",
          successMessageAgentRole: "Channel Partner menu updated successfully.",
          disableRoleBtn: false,
        });
        setTimeout(() => {
          window.$("#modalSubAgentAssignRole").modal("hide");
        }, 3000);
      } else {
        this.setState({
          errorMessageAgentRole: saveResponce.data.message,
          successMessageAgentRole: "",
          disableRoleBtn: false,
        });
      }
    } catch (error) {
      console.error("Error while saving roles:", error);
      this.setState({
        errorMessageAgentRole: "An error occurred while saving menu.",
        successMessageAgentRole: "",
        disableRoleBtn: false,
      });
    }
  };

  render() {
    const breadcrumb_arr = [
      {
        title: "Channel Partners",
        url: "/agents",
      },
      {
        title: "Sub Channel Partners",
        url: "",
      },
    ];
    return (
      <div className="nk-content " id="SubClients_Block">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" &&
            this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {this.props.is_msa_signed === "No" &&
            this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}
          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitleBreadCrumb
                        name={breadcrumb_arr}
                        icon="icon ni ni-users"
                      />
                    </h3>
                  </div>

                </div>
              </div>
              {/* PAGE TITLE END */}

              {/* DETAIL MODAL START */}

              {/* DETAIL MODAL End */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="row g-gs" style={{ clear: "both" }}>
                      <div className="col-lg-12">
                        <div
                          className="card card-bordered"
                          style={{ clear: "both" }}
                        >
                          <div className="card-inner">
                            <div class="tab-content" style={{ marginTop: 0 }}>
                              <div
                                class={`tab-pane ${this.state.sub_activeTab === "tabItem8"
                                  ? "active"
                                  : ""
                                  }`}
                                id="tabItem8"
                              >
                                <div className="row">
                                  <div className="col-md-12">
                                    {this.state.tableLoader === true ? (
                                      tableLoader()
                                    ) : (
                                      <DataTable
                                        columns={this.state.columnServices}
                                        tableData={this.state.tableData}
                                        title="All Sub Channel Partners"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/* Role Sub Assignment Modal START */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalSubAgentAssignRole"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-grid-alt"></em>Sub Channel
                              Partner Menu{" "}
                              <sup>
                                <small className="text-soft">
                                  {this.state.rolesAgentName}
                                </small>
                              </sup>
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body text-left">
                          <div className="nk-modal">
                            <div className="nk-modal-text">
                              {this.state.agentRoleLoader === true ? (
                                tableLoader()
                              ) : (
                                <>
                                  {this.state.errorMessageAgentRole !== "" ? (
                                    <div
                                      className="example-alert "
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-danger">
                                        <div className="alert-text">
                                          <h4>Error</h4>
                                          <p>{this.state.errorMessageAgentRole}</p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  {this.state.successMessageAgentRole !== "" ? (
                                    <div
                                      className="example-alert "
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-success">
                                        <div className="alert-text">
                                          <h4>Success</h4>
                                          <p>
                                            {this.state.successMessageAgentRole}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}

                                  <div className="card card-bordered card-preview">
                                    <table className="table table-orders">
                                      <thead className="tb-odr-head">
                                        <tr className="tb-odr-item">
                                          <th
                                            width="35%"
                                            style={{
                                              borderRight: "1px solid #dbdfea",
                                            }}
                                          >
                                            <span className="tb-odr-action">
                                              Main Menu
                                            </span>
                                          </th>
                                          <th width="65%">
                                            <span className="tb-odr-action">
                                              Sub Menu
                                            </span>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="tb-odr-body">
                                        {this.state.allRoleData &&
                                          this.state.allRoleData.length > 0 ? (
                                          <>
                                            {this.state.allRoleData.map(
                                              (role, index) => (
                                                <tr
                                                  className="tb-odr-item"
                                                  key={`role${index}`}
                                                >
                                                  <td
                                                    style={{
                                                      borderRight:
                                                        "1px solid #dbdfea",
                                                    }}
                                                  >
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          checked={this.state.allRoleData.some(
                                                            (item) =>
                                                              item.alt ===
                                                              role.alt &&
                                                              item.isChecked
                                                          )}
                                                          onChange={
                                                            this
                                                              .changedAllRolesRights
                                                          }
                                                          disabled={
                                                            role.seqno === 1 &&
                                                            this.state.assignRoleData.some(
                                                              (subRole) =>
                                                                role.alt ===
                                                                subRole.alt
                                                            )
                                                          }
                                                          name={role?.id?.toString()}
                                                          color="primary"
                                                        />
                                                      }
                                                      style={{
                                                        marginBottom: "0px",
                                                      }}
                                                      label={role.alt}
                                                    />
                                                  </td>
                                                  <td
                                                    style={{
                                                      display: "flex",
                                                      flexWrap: "wrap",
                                                      paddingLeft: "16px",
                                                    }}
                                                  >
                                                    {this.state.allSubRoleData
                                                      .filter((subRole) => {
                                                        return (
                                                          subRole.role_id ===
                                                          role.id
                                                        );
                                                      })
                                                      .map((subRole, subIndex) => (
                                                        <div
                                                          key={`subRole${subIndex}`}
                                                        >
                                                          <FormControlLabel
                                                            control={
                                                              <Checkbox
                                                                checked={this.state.allSubRoleData.some(
                                                                  (item) =>
                                                                    item.alt ===
                                                                    subRole.alt &&
                                                                    item.isChecked
                                                                )}
                                                                onChange={
                                                                  this
                                                                    .changedAllSubRolesRights
                                                                }
                                                                name={subRole?.tabdetailid?.toString()}
                                                                color="primary"
                                                              />
                                                            }
                                                            style={{
                                                              marginBottom: "0px",
                                                            }}
                                                            label={subRole.alt}
                                                          />
                                                        </div>
                                                      ))}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </>
                                        ) : null}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="nk-modal-action  text-right">
                                    <a
                                      href="#"
                                      className="btn btn-lg btn-mw btn-primary "
                                      onClick={() => {
                                        this.handleSubmitRoleCheckbox(
                                          this.state.checkedName,
                                          this.state.checkedEmail
                                        );
                                      }}
                                      disabled={this.state.disableRoleBtn}
                                    >
                                      Channel Partner Menu
                                    </a>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Role Sub Assignment Modal End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Subagents);
