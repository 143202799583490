import axios from "axios";

import $ from "jquery";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { axiosConfig } from "./utility";

export const getSubpoena = async (accountno, token, user_type, sub_accountno, is_supoena_user) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "subpoena/get_subpoena",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            user_type: user_type,
            sub_accountno: sub_accountno,
            is_supoena_user: is_supoena_user
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getDidDetail = async (
  accountno,
  token,
  client_accountno,
  request_did,
  start_date,
  end_date,
  ref_number
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "subpoena/get_subpoena",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            request_did: request_did,

            start_date: start_date,
            end_date: end_date,
            ref_number: ref_number,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deleteSubpoenaReport = async (accountno, token, report_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "subpoena/delete_subpoena_request",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            report_id: report_id
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSingleSubpoenaReport = async (accountno, token, report_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "subpoena/single_subpoena_reports",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            request_id: report_id
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const viewSubpoenaReport = async (accountno, token, report_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "subpoena/view_subpoena_metabase_reports",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            request_id: report_id
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};
