import { React } from "react";
import { Component, Fragment } from "react";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  ListClientRoles,
  ListClientSubRoles,
  setSequenceNo,
  setSequenceNoSub,
} from "../../../config/client_role_api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "../../../config/config";
import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";
import localization from "../../../localization/localization";

const auth = new HelperClass();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class ClientRoles extends Component {
  constructor() {
    super();
    this.state = {
      listRoleData: [],
      listSubRoleData: [],
      errorMessaged: "",
      errorMessage: "",
      successMessaged: "",
      successMessage: "",
      title: "",
      name: "",
      logo: "",
      delete_id: "",
      description: "",
      tableLoader: true,
      domain: "",
      step: 1,
      isEditing: null,
      menuSequences: {},
      sequenceNo: "",
      roleId: "",
      errorMessageMatch: "",
      successMessageMatch: "",

      isEditingSub: null,
      menuSequencesSub: {},
      sequenceNoSub: "",
      roleIdSub: "",
      errorMessageMatchSub: "",
      successMessageMatchSub: "",
      assigned_services: [],
      subRoleColumns: [],
      rolecolumns: [
        { name: "Title" },
        { name: "Link" },
        { name: "Menu Icon" },
        {
          name: "Menu Sequence",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  {this.state.isEditing === tableMeta.rowIndex ? (
                    <div className="flex items-center">
                      <input
                        type="text"
                        value={
                          this.state.menuSequences[tableMeta.rowIndex] ?? value
                        }
                        onChange={(e) =>
                          this.handleMenuSequenceChange(e, tableMeta.rowIndex)
                        }
                        onInput={(e) => {
                          let value = e.target.value.replace(/[^0-9]/g, "");
                          if (value.length === 1) {
                            value = value.replace(/[0]/g, "");
                          }
                          if (
                            value !== "" &&
                            parseInt(value) > 0 &&
                            parseInt(value) <= 99
                          ) {
                            e.target.value = value;
                          } else if (value === "") {
                            e.target.value = "";
                          } else {
                            e.target.value = e.target.previousValue || "";
                          }
                          e.target.previousValue = e.target.value;
                        }}
                        className="w-8 mr-2 p-1 text-center"
                        maxLength="2"
                        disabled={this.state.isSaving}
                      />
                      <Tooltip title="Save" placement="top">
                        <span
                          className="mr-2 text-primary"
                          onClick={() =>
                            this.handleSaveClick(
                              tableMeta.rowIndex,
                              tableMeta.rowData[6]
                            )
                          }
                          style={{
                            cursor: this.state.isSaving ? "default" : "pointer",
                            fontSize: "17px",
                            opacity: this.state.isSaving ? "0.5" : "1",
                            pointerEvents: this.state.isSaving
                              ? "none"
                              : "auto",
                          }}
                        >
                          <em className="icon ni ni-save "></em>
                        </span>
                      </Tooltip>

                      {!this.state.isSaving && (
                        <Tooltip title="Close" placement="top">
                          <span
                            onClick={() => this.handleCancelEdit()}
                            style={{ cursor: "pointer", fontSize: "17px" }}
                          >
                            <em className="icon ni ni-cross"></em>
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  ) : (
                    <div>
                      {value}{" "}
                      <Tooltip title="Edit" placement="top">
                        <span
                          className="mr-1"
                          onClick={() =>
                            this.handleEditClick(tableMeta.rowIndex, value)
                          }
                          style={{
                            cursor: "pointer",
                            fontSize: "13px",
                          }}
                        >
                          <em className="icon ni ni-edit"></em>
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </div>
              );
            },
          },
        },
        {
          name: "status",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.roleStatus(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Default Tab",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.roleDefault(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.addMenuHistory(
                                      "/client_roles/edit_client_role/" +
                                        tableMeta.rowData[6]
                                    )
                                  }
                                  title="Edit Email"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>
                                    {" "}
                                    {localization.clientsRoles.mainRoleLink}
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
      tableData: [],
    };
  }

  async componentDidMount() {
    this.setState({
      tableLoader: true,
    });
    const listClientResponce = await ListClientRoles(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("ListClientRoles Res: ", listClientResponce.data);
    if (
      listClientResponce.data.status === 403 ||
      listClientResponce.data.errors === "authentication missing" ||
      listClientResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listClientResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listClientResponce.data.status === 200 &&
      listClientResponce.data.message === "success"
    ) {
      this.setState({
        listRoleData: listClientResponce.data.data,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  roleStatus = (value, tableMeta) => {
    let active = tableMeta.rowData[4];
    if (active === 1) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-na"></em> In Active
        </span>
      );
    }
  };

  roleDefault = (value, tableMeta) => {
    let active = tableMeta.rowData[5];
    if (active === 1) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    }
  };

  subRoleStatus = (value, tableMeta) => {
    let active = tableMeta.rowData[5];
    if (active === 1) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-na"></em> In Active
        </span>
      );
    }
  };

  subRoleDefault = (value, tableMeta) => {
    let active = tableMeta.rowData[6];
    if (active === 1) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  getClientSubRoles = async () => {
    this.setState({
      tableLoader: true,
    });
    const listAgentSubRoleResponce = await ListClientSubRoles(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("client sub roles  Res: ", listAgentSubRoleResponce.data);
    if (
      listAgentSubRoleResponce.data.status === 403 ||
      listAgentSubRoleResponce.data.errors === "authentication missing" ||
      listAgentSubRoleResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listAgentSubRoleResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listAgentSubRoleResponce.data.status === 200 &&
      listAgentSubRoleResponce.data.message === "success"
    ) {
      this.setState({
        listSubRoleData: listAgentSubRoleResponce.data.data,
        tableLoader: false,
        subRoleColumns: [
          { name: "Title" },
          { name: "Link" },
          { name: "Menu Icon" },
          {
            name: "Menu Sequence",
            options: {
              customBodyRender: (value, tableMeta) => {
                return (
                  <div>
                    {this.state.isEditingSub === tableMeta.rowIndex ? (
                      <div className="flex items-center">
                        <input
                          type="text"
                          value={
                            this.state.menuSequencesSub[tableMeta.rowIndex] ??
                            value
                          }
                          onChange={(e) =>
                            this.handleMenuSequenceChangeSub(
                              e,
                              tableMeta.rowIndex
                            )
                          }
                          onInput={(e) => {
                            let value = e.target.value.replace(/[^0-9]/g, "");
                            if (value.length === 1) {
                              value = value.replace(/[0]/g, "");
                            }
                            if (
                              value !== "" &&
                              parseInt(value) > 0 &&
                              parseInt(value) <= 99
                            ) {
                              e.target.value = value;
                            } else if (value === "") {
                              e.target.value = "";
                            } else {
                              e.target.value = e.target.previousValue || "";
                            }
                            e.target.previousValue = e.target.value;
                          }}
                          className="w-8 mr-2 p-1 text-center"
                          maxLength="2"
                          disabled={this.state.isSaving}
                          style={{
                            width: "65%",
                          }}
                        />
                        <Tooltip title="Save" placement="top">
                          <span
                            className="mr-2"
                            onClick={() =>
                              this.handleSaveClickSub(
                                tableMeta.rowIndex,
                                tableMeta.rowData[7]
                              )
                            }
                            style={{
                              cursor: this.state.isSaving
                                ? "default"
                                : "pointer",
                              fontSize: "17px",
                              opacity: this.state.isSaving ? "0.5" : "1",
                              pointerEvents: this.state.isSaving
                                ? "none"
                                : "auto",
                            }}
                          >
                            <em className="icon ni ni-save"></em>
                          </span>
                        </Tooltip>

                        {!this.state.isSaving && (
                          <Tooltip title="Close" placement="top">
                            <span
                              onClick={() => this.handleCancelEditSub()}
                              style={{ cursor: "pointer", fontSize: "17px" }}
                            >
                              <em className="icon ni ni-cross"></em>
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    ) : (
                      <div>
                        {value}{" "}
                        <Tooltip title="Edit" placement="top">
                          <span
                            className="mr-1"
                            onClick={() =>
                              this.handleEditClickSub(tableMeta.rowIndex, value)
                            }
                            style={{
                              cursor: "pointer",
                              fontSize: "13px",
                            }}
                          >
                            <em className="icon ni ni-edit"></em>
                          </span>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                );
              },
            },
          },
          { name: "Parent Menu " },
          {
            name: "status",
            options: {
              customBodyRender: (value, tableMeta) => {
                return <div>{this.subRoleStatus(value, tableMeta)}</div>;
              },
            },
          },
          {
            name: "Default Tab",
            options: {
              customBodyRender: (value, tableMeta) => {
                return <div>{this.subRoleDefault(value, tableMeta)}</div>;
              },
            },
          },
          {
            name: "Actions",
            options: {
              customBodyRender: (value, tableMeta) => {
                return (
                  <div>
                    <div
                      className="nk-tb-col nk-tb-col-tools"
                      style={{ padding: "0px" }}
                    >
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <div className="drodown">
                            <a
                              href="#"
                              className="dropdown-toggle btn btn-icon btn-outline-light"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <a
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    onClick={() =>
                                      this.addMenuHistory(
                                        "/client_roles/edit_client_subrole/" +
                                          tableMeta.rowData[7]
                                      )
                                    }
                                    title="Edit Email"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <em className="icon ni ni-edit"></em>
                                    <span>
                                      {" "}
                                      {
                                        localization.clientsRoles.subRoleLink
                                      }{" "}
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          },
        ],
      });
    } else {
      //window.location.replace("/error");
    }
  };

  handleEditClick = (rowIndex, currentValue) => {
    this.setState({
      isEditing: rowIndex,
      menuSequences: {
        ...this.state.menuSequences,
        [rowIndex]: currentValue,
      },
    });
  };

  handleMenuSequenceChange = (event, rowIndex) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      menuSequences: {
        ...prevState.menuSequences,
        [rowIndex]: value,
      },
    }));
  };

  handleCancelEdit = () => {
    this.setState({
      isEditing: null,
      errorMessage: "",
      successMessage: "",
    });
  };

  handleSaveClick = async (rowIndex, roleId) => {
    const sequenceNo = this.state.menuSequences[rowIndex];

    if (sequenceNo === "" || !sequenceNo) {
      this.setState({
        errorMessage: "Enter the sequence number.",
      });
      setTimeout(() => {
        this.setState({
          errorMessage: "",
        });
      }, 3000);
      return;
    }

    let isMatchFound = this.state.listRoleData.find((role) => {
      return role[3] == sequenceNo && role[6] != roleId;
    });

    if (isMatchFound) {
      this.setState({
        alertModalMessage: (
          <>
            The menu item <b>{isMatchFound[0]}</b> has already used this
            sequence number. Do you want to proceed?
          </>
        ),
        alertModalTitle: "Warning",
        alertModalIcon: "icon ni ni-alert-circle icon-circle-xxl",
        sequenceNo: sequenceNo,
        roleId: roleId,
      });
      window.$("#modalAlertConflict").modal("show");
      return;
    }

    await this.executeSaveSequence(sequenceNo, roleId);
  };

  executeSaveSequence = async (sequenceNo, roleId) => {
    this.setState({ isSaving: true });
    try {
      const listClientResponse = await setSequenceNo(
        auth.getAccount(),
        auth.getToken(),
        sequenceNo,
        roleId
      );

      if (
        listClientResponse.data.status === 403 ||
        listClientResponse.data.errors === "authentication missing" ||
        listClientResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      }

      if (listClientResponse.data.status === 404) {
        this.setState({
          errorMessage: "Menu item not found",
          successMessage: "",
          isSaving: false,
        });
      } else if (
        listClientResponse.data.status === 200 &&
        listClientResponse.data.message === "success"
      ) {
        this.setState(
          {
            errorMessage: "",
            successMessage: "Menu item sequence updated successfully",
            isEditing: null,
            isSaving: false,
          },
          () => {
            setTimeout(() => {
              this.reloadClientRoles();
            }, 500);
          }
        );
      }
    } catch (error) {
      console.error("Error updating sequence:", error);
      this.setState({
        errorMessage: "Failed to update menu sequence",
        successMessage: "",
        isSaving: false,
      });
    }
    setTimeout(() => {
      this.setState({
        errorMessage: "",
        successMessage: "",
      });
    }, 3000);
  };

  updateSequence = async () => {
    this.setState({ isSaving: true });
    try {
      const listClientResponse = await setSequenceNo(
        auth.getAccount(),
        auth.getToken(),
        this.state.sequenceNo,
        this.state.roleId
      );

      if (
        listClientResponse.data.status === 403 ||
        listClientResponse.data.errors === "authentication missing" ||
        listClientResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      }

      if (listClientResponse.data.status === 404) {
        this.setState({
          errorMessageMatch: "Menu item not found",
          successMessageMatch: "",
          isSaving: false,
        });
      } else if (
        listClientResponse.data.status === 200 &&
        listClientResponse.data.message === "success"
      ) {
        this.setState(
          {
            errorMessageMatch: "",
            alertModalTitle: "Success",
            alertModalIcon: "icon ni ni-check-circle icon-circle-xxl",
            successMessageMatch: "Menu item sequence updated successfully",
            isEditing: null,
            isSaving: false,
          },
          () => {
            setTimeout(() => {
              this.reloadClientRoles();
              window.$("#modalAlertConflict").modal("hide");
            }, 3000);
          }
        );
      }
    } catch (error) {
      console.error("Error updating sequence:", error);
      this.setState({
        errorMessageMatch: "Failed to update menu sequence",
        successMessageMatch: "",
        isSaving: false,
      });
    }
    this.removeMessages();
  };

  reloadClientRoles = async () => {
    this.setState({ tableLoader: true });

    try {
      const listClientResponse = await ListClientRoles(
        auth.getAccount(),
        auth.getToken()
      );

      if (
        listClientResponse.data.status === 403 ||
        listClientResponse.data.errors === "authentication missing" ||
        listClientResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      }

      if (
        listClientResponse.data.status === 200 &&
        listClientResponse.data.message === "success"
      ) {
        this.setState({
          listRoleData: listClientResponse.data.data,
          tableLoader: false,
          isEditing: null,
          menuSequences: {},
        });
      } else if (listClientResponse.data.status === 404) {
        this.setState({
          errorMessage: "Failed to load roles",
          tableLoader: false,
        });
      }
    } catch (error) {
      console.error("Error reloading roles:", error);
      this.setState({
        errorMessage: "Failed to reload roles",
        tableLoader: false,
      });
    }
  };

  handleEditClickSub = (rowIndex, currentValue) => {
    this.setState({
      isEditingSub: rowIndex,
      menuSequencesSub: {
        ...this.state.menuSequencesSub,
        [rowIndex]: currentValue,
      },
    });
  };

  handleMenuSequenceChangeSub = (event, rowIndex) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      menuSequencesSub: {
        ...prevState.menuSequencesSub,
        [rowIndex]: value,
      },
    }));
  };

  handleCancelEditSub = () => {
    this.setState({
      isEditingSub: null,
      errorMessage: "",
      successMessage: "",
    });
  };

  handleSaveClickSub = async (rowIndex, roleId) => {
    const sequenceNoSub = this.state.menuSequencesSub[rowIndex];

    if (sequenceNoSub === "" || !sequenceNoSub) {
      this.setState({
        errorMessage: "Enter the sequence number.",
      });
      setTimeout(() => {
        this.setState({
          errorMessage: "",
        });
      }, 3000);
      return;
    }

    let isMatchFound = this.state.listSubRoleData.find((role) => {
      return (
        role[3] == sequenceNoSub &&
        role[4] == this.state.listSubRoleData[rowIndex][4] &&
        role[7] != roleId
      );
    });

    if (isMatchFound) {
      this.setState({
        alertModalMessageSub: (
          <>
            The sub menu item <b>{isMatchFound[0]}</b> has already used this
            sequence number. Do you want to proceed?
          </>
        ),
        alertModalTitleSub: "Warning",
        alertModalIconSub: "icon ni ni-alert-circle icon-circle-xxl",
        sequenceNoSub: sequenceNoSub,
        roleIdSub: roleId,
      });
      window.$("#modalAlertConflictSub").modal("show");
      return;
    }

    await this.executeSaveSequenceSub(sequenceNoSub, roleId);
  };

  executeSaveSequenceSub = async (sequenceNoSub, roleId) => {
    this.setState({ isSaving: true });
    try {
      const listClientResponse = await setSequenceNoSub(
        auth.getAccount(),
        auth.getToken(),
        sequenceNoSub,
        roleId
      );

      if (
        listClientResponse.data.status === 403 ||
        listClientResponse.data.errors === "authentication missing" ||
        listClientResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      }

      if (listClientResponse.data.status === 404) {
        this.setState({
          errorMessage: "Menu item not found",
          successMessage: "",
          isSaving: false,
        });
      } else if (
        listClientResponse.data.status === 200 &&
        listClientResponse.data.message === "success"
      ) {
        this.setState(
          {
            errorMessage: "",
            successMessage: "Menu item sequence updated successfully",
            isEditing: null,
            isSaving: false,
          },
          () => {
            setTimeout(() => {
              this.reloadClientRolesSub();
            }, 500);
          }
        );
      }
    } catch (error) {
      console.error("Error updating sequence:", error);
      this.setState({
        errorMessage: "Failed to update menu sequence",
        successMessage: "",
        isSaving: false,
      });
    }
    setTimeout(() => {
      this.setState({
        errorMessage: "",
        successMessage: "",
      });
    }, 3000);
  };

  updateSequenceSub = async () => {
    this.setState({ isSaving: true });
    try {
      const listClientResponse = await setSequenceNoSub(
        auth.getAccount(),
        auth.getToken(),
        this.state.sequenceNoSub,
        this.state.roleIdSub
      );

      if (
        listClientResponse.data.status === 403 ||
        listClientResponse.data.errors === "authentication missing" ||
        listClientResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      }

      if (listClientResponse.data.status === 404) {
        this.setState({
          errorMessageMatchSub: "Menu item not found",
          successMessageMatchSub: "",
          isSaving: false,
        });
      } else if (
        listClientResponse.data.status === 200 &&
        listClientResponse.data.message === "success"
      ) {
        this.setState(
          {
            errorMessageMatchSub: "",
            alertModalTitleSub: "Success",
            alertModalIconSub: "icon ni ni-check-circle icon-circle-xxl",
            successMessageMatch: "Menu item sequence updated successfully",
            isEditingSub: null,
            isSaving: false,
          },
          () => {
            setTimeout(() => {
              this.reloadClientRolesSub();
              window.$("#modalAlertConflictSub").modal("hide");
            }, 3000);
          }
        );
      }
    } catch (error) {
      console.error("Error updating sequence:", error);
      this.setState({
        errorMessageMatchSub: "Failed to update menu sequence",
        successMessageMatchSub: "",
        isSaving: false,
      });
    }
    this.removeMessages();
  };

  reloadClientRolesSub = async () => {
    this.setState({ tableLoader: true });

    try {
      const listClientResponse = await ListClientSubRoles(
        auth.getAccount(),
        auth.getToken()
      );

      if (
        listClientResponse.data.status === 403 ||
        listClientResponse.data.errors === "authentication missing" ||
        listClientResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      }

      if (
        listClientResponse.data.status === 200 &&
        listClientResponse.data.message === "success"
      ) {
        this.setState({
          listSubRoleData: listClientResponse.data.data,
          tableLoader: false,
          isEditingSub: null,
          menuSequencesSub: {},
        });
      } else if (listClientResponse.data.status === 404) {
        this.setState({
          errorMessage: "Failed to load roles",
          tableLoader: false,
        });
      }
    } catch (error) {
      console.error("Error reloading roles:", error);
      this.setState({
        errorMessage: "Failed to reload roles",
        tableLoader: false,
      });
    }
  };

  removeMessages = () => {
    setTimeout(() => {
      this.setState({
        errorMessageMatch: "",
        successMessageMatch: "",
        errorMessageMatchSub: "",
        successMessageMatchSub: "",
      });
    }, 3000);
  };

  modalHideMatchConflict = () => {
    window.$("#modalAlertConflict").modal("hide");
  };

  modalHideMatchConflictSub = () => {
    window.$("#modalAlertConflictSub").modal("hide");
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MODAL EDIT PROFILE START */}
          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  <PageTitle
                    name={localization.clientsRoles.heading}
                    icon="icon ni ni-grid-alt"
                  />
                </h3>
              </div>
              <div className="nk-block-head-content" id="HeadContent">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    href="#"
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                  >
                    {/*<em className="icon ni ni-more-v"></em>*/}
                  </a>
                  <div className="dropdown">
                    <a
                      href="#"
                      className="btn btn-large btn-primary"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>{localization.clientsRoles.addNewButton}</span>
                      <em className="icon ni ni-chevron-down"></em>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-auto mt-1">
                      <ul className="link-list-plain">
                        <li>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.addMenuHistory(
                                "/client_roles/add_client_role/"
                              )
                            }
                          >
                            <span>
                              <em className="icon ni ni-plus"></em>{" "}
                              {localization.clientsRoles.addNewButtonLink1}
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.addMenuHistory(
                                "/client_roles/add_client_subrole/"
                              )
                            }
                          >
                            <span>
                              <em className="icon ni ni-plus"></em>{" "}
                              {localization.clientsRoles.addNewButtonLink2}
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="row g-gs">
              <div className="col-xxl-12">
                {this.state.errorMessage !== "" ? (
                  <div className="example-alert mb-2">
                    <div className="alert alert-pro alert-danger">
                      <div className="alert-text">
                        <h4>Error</h4>
                        <p>{this.state.errorMessage}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.successMessage !== "" ? (
                  <div className="example-alert mb-2">
                    <div className="alert alert-pro alert-success">
                      <div className="alert-text">
                        <h4>Success</h4>
                        <p>{this.state.successMessage}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="nk-block nk-block-lg">
                  <div className="row g-gs">
                    <div className="col-xxl-12">
                      <div className="nk-block nk-block-lg">
                        <div className="card card-bordered">
                          <div className="card-inner">
                            <ul className="nav nav-tabs">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  data-toggle="tab"
                                  href="#adminEmails"
                                  onClick={() => this.componentDidMount()}
                                >
                                  <em class="icon ni ni-grid-c"></em>
                                  <span>
                                    {localization.clientsRoles.mainRolesTab}{" "}
                                  </span>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  data-toggle="tab"
                                  href="#agentEmails"
                                  onClick={() => this.getClientSubRoles()}
                                >
                                  <em class="icon ni ni-grid-c"></em>
                                  <span>
                                    {localization.clientsRoles.subRoleTab}
                                  </span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div className="tab-pane active" id="adminEmails">
                                {this.state.tableLoader === true ? (
                                  tableLoader()
                                ) : (
                                  <DataTable
                                    columns={this.state.rolecolumns}
                                    tableData={this.state.listRoleData}
                                    title={
                                      localization.clientsRoles.mainRolesSub
                                    }
                                  />
                                )}
                              </div>
                              <div className="tab-pane" id="agentEmails">
                                {this.state.tableLoader === true ? (
                                  tableLoader()
                                ) : (
                                  <DataTable
                                    columns={this.state.subRoleColumns}
                                    tableData={this.state.listSubRoleData}
                                    title={
                                      localization.clientsRoles.subRoleTabSub
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table 5th Col End */}
            {/* ROLE MATCH ALERT START */}
            <div
              className="modal fade"
              tabIndex="-1"
              id="modalAlertConflict"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <a
                    style={{ cursor: "pointer" }}
                    className="close"
                    onClick={() => {
                      this.modalHideMatchConflict();
                    }}
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal">
                      <em
                        className={this.state.alertModalIcon}
                        style={{
                          fontSize: "6rem",
                        }}
                      ></em>

                      <h4 className="nk-modal-title">{this.state.alertModalTitle}</h4>

                      <div className="nk-modal-text">
                        <p>{this.state.alertModalMessage}</p>
                        {this.state.errorMessageMatch !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>
                                  {this.state.errorMessageMatch}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.successMessageMatch !==
                          "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="alert alert-pro alert-success">
                              <div className="alert-text">
                                <h4>Success</h4>
                                <p>
                                  {
                                    this.state
                                      .successMessageMatch
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="nk-modal-action">
                        <a
                          style={{ cursor: "pointer" }}
                          className="btn btn-lg btn-mw btn-danger"
                          onClick={() => {
                            this.modalHideMatchConflict();
                          }}
                        >
                          CANCEL
                        </a>
                        {"  "}
                        <a
                          style={{
                            cursor: "pointer",
                          }}
                          className="btn btn-lg btn-mw btn-primary"
                          onClick={() => {
                            this.updateSequence();
                          }}
                          disabled={this.state.isSaving}
                        >
                          {this.state.isSaving ? (
                            <span>UPDATING...</span>
                          ) : (
                            <span>
                              Update
                            </span>
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ROLE MATCH ALERT END */}
            {/* SUB ROLE MATCH ALERT START */}
            <div
              className="modal fade"
              tabIndex="-1"
              id="modalAlertConflictSub"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <a
                    style={{ cursor: "pointer" }}
                    className="close"
                    onClick={() => {
                      this.modalHideMatchConflictSub();
                    }}
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                  <div className="modal-body modal-body-lg text-center">
                    <div className="nk-modal">
                      <em
                        className={this.state.alertModalIconSub}
                        style={{
                          fontSize: "6rem",
                        }}
                      ></em>

                      <h4 className="nk-modal-title">{this.state.alertModalTitleSub}</h4>

                      <div className="nk-modal-text">
                        <p>{this.state.alertModalMessageSub}</p>
                        {this.state.errorMessageMatch !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>
                                  {this.state.errorMessageMatch}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.successMessageMatch !==
                          "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="alert alert-pro alert-success">
                              <div className="alert-text">
                                <h4>Success</h4>
                                <p>
                                  {
                                    this.state
                                      .successMessageMatch
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="nk-modal-action">
                        <a
                          style={{ cursor: "pointer" }}
                          className="btn btn-lg btn-mw btn-danger"
                          onClick={() => {
                            this.modalHideMatchConflictSub();
                          }}
                        >
                          CANCEL
                        </a>
                        {"  "}
                        <a
                          style={{
                            cursor: "pointer",
                          }}
                          className="btn btn-lg btn-mw btn-primary"
                          onClick={() => {
                            this.updateSequenceSub();
                          }}
                          disabled={this.state.isSaving}
                        >
                          {this.state.isSaving ? (
                            <span>UPDATING...</span>
                          ) : (
                            <span>
                              Update
                            </span>
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* SUB ROLE MATCH ALERT END */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientRoles);
