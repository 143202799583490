import { React } from "react";
import { Component } from "react";

import { COMPANY_NAME } from "../../../config/config";
import axios from "axios";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";

import { viewSingleAgentMetabaseReport } from "./../../../config/metabase_api_calls";

import {
  dateString,
  APP_URL,
  AGENT_SERVER_URL,
  APP_LIVE_URL,
} from "./../../../config/config";

import $ from "jquery";

import DataTable from "../../../components/Tables/DataTableReports";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Link } from "react-router-dom";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import Tooltip from "@material-ui/core/Tooltip";

import dateFormat, { masks } from "dateformat";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import TextField from "../../../components/InputFields/TextField";
import MutextField from "@material-ui/core/TextField";
import * as XLSX from "xlsx";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  columnsServices,
} from "./../../../config/config";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import { extendMoment } from "moment-range";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const extendedMoment = extendMoment(moment);

const animatedComponents = makeAnimated();

const auth = new HelperClass();

class ViewAgentReport extends Component {
  constructor() {
    var date = (date = new Date()),
      y = date.getFullYear(),
      m = date.getMonth();

    var datestring = new Date().toISOString().slice(0, 10);

    super();
    this.decimalPoint = Number(auth.getDecimalPoint());
    this.billing_decimalPoint = Number(auth.getBillingDecimalPoint());
    const today = extendedMoment();
    const monthAgo = moment().subtract(1, "month");
    this.state = {
      tableData: [],
      tableLoader: true,
      //start_date: monthAgo.format("YYYY-MM-DD"),
      //end_date: today.format("YYYY-MM-DD"),
      start_date: "",
      end_date: "",
      selected_order_accountno: null,
      selected_client_accountno: null,
      selected_agent_accountno: null,
      value: moment.range(monthAgo, today),

      deposit_main_section: false,
      depositErrorMessage: "",
      depositSuccessMessage: "",

      emailErrorMessage: "",
      emailErrorMessageTitle: "",
      emailSuccessMessage: "",
      orderno: "",
      form_title: "",
      formDetails: [],
      formGroups: [],
      is_submitted: "",
      payment_method_title: "",
      selected_payment_method: "",
      paymentDetails: [],
      requestDetails: [],
      request_main_section: true,
      requestErrorMessage: "",
      requestErrorMessageTitle: "",
      requestSuccessMessage: "",

      successMessage: "",
      errorMessage: "",
      document_file: "",
      csvData: [],

      selectedRows: [],
      bulkAction: "",

      report_id: "",
      title: "",
      iframe_url: "",
    };
  }

  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    this.setState({
      tableLoader: false,
      tableLoaderListing: false,
    });
    if (
      this.props.props.match.params.report_id &&
      this.props.props.match.params.report_id !== ""
    ) {
      let request_id = this.props.props.match.params.report_id;
      this.setState({ report_id: request_id });
      await this.getSingleRequest(request_id);
    }
  }

  getSingleRequest = async (request_id) => {
    const saveResponce = await viewSingleAgentMetabaseReport(
      auth.getAccount(),
      auth.getToken(),
      request_id
    );
    console.log("getSingleRequest: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      this.setState({
        mcqLoader: false,
        isEditorReady: true,
        iframe_url: "not_found",
      });
    } else if (saveResponce.data.status === 200) {
      let added_file = [];

      if (
        saveResponce.data.data[0]["files"] &&
        saveResponce.data.data[0]["files"].length > 0
      ) {
        added_file = saveResponce.data.data[0]["files"];
        let updatedData = added_file.map((item) => ({
          ...item,
          added_file: item.added_file.split(", "), // Split string into an array by comma and space
        }));
        this.setState({
          added_file: updatedData,
        });
      }
      console.log(added_file);

      const start_date = saveResponce.data.data[0]["start_date"]
        ? moment(saveResponce.data.data[0]["start_date"])
        : moment();
      const end_date = saveResponce.data.data[0]["end_date"]
        ? moment(saveResponce.data.data[0]["end_date"])
        : moment().add(7, "days");

      this.setState({
        title: saveResponce.data.data[0]["title"],
        start_date: start_date.format("MMMM DD, YYYY"),
        end_date: end_date.format("MMMM DD, YYYY"),
        value: moment.range(start_date, end_date),
        description: saveResponce.data.data[0]["description"],
        report_params: saveResponce.data.data[0]["params"],
        report_url_id: saveResponce.data.data[0]["url"],
        add_picture: "",
        report_id: saveResponce.data.data[0]["id"],
        iframe_url: saveResponce.data.iframeData,
        publish_report:
          saveResponce.data.data[0]["report_status"] === 1 ? "Yes" : "No",
        //added_file: added_file,
        isEditorReady: true,
      });
    } else {
      this.setState({
        mcqLoader: false,
        isEditorReady: true,
      });
    }
  };

  format_date = (value) => {
    let date = value;
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy");
    } else {
      return "---";
    }
  };

  setStatus = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === "processing") {
      return (
        <div>
          <span className="badge badge-dim badge-danger">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> In process
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-success">
            {" "}
            <em className="icon ni ni-done "></em> Processed
          </span>
        </div>
      );
    }
  };

  setStatusPublished = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === 0) {
      return (
        <div>
          <span className="badge badge-dim badge-danger">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> Not
            Published
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-success">
            {" "}
            <em className="icon ni ni-done "></em> Published
          </span>
        </div>
      );
    }
  };

  setReportLink = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str && domain_str !== "") {
      return (
        <Tooltip title="View report">
          <a href="">
            <em
              class="icon ni ni-reports text-primary"
              style={{ fontSize: "22px" }}
            ></em>
          </a>
        </Tooltip>
      );
    } else {
      return "---";
    }
  };

  setEditLink = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str && domain_str !== "") {
      return (
        <>
          <Tooltip title="Edit report">
            <a
              onClick={() =>
                this.addMenuHistory("/metabaseReportsEdit/" + domain_str)
              }
              style={{ cursor: "pointer" }}
            >
              <em
                class="icon ni ni-edit text-primary"
                style={{ fontSize: "22px", paddingRight: "5px" }}
              ></em>
            </a>
          </Tooltip>

          <Tooltip title="Delete report">
            <a
              onClick={() => this.deleteReportModal(domain_str)}
              style={{ cursor: "pointer" }}
            >
              <em
                class="icon ni ni-trash text-danger"
                style={{
                  fontSize: "22px",
                  paddingLeft: "5px",
                  borderLeft: "1px solid rgb(0 0 0 / 87%)",
                }}
              ></em>
            </a>
          </Tooltip>
        </>
      );
    } else {
      return "---";
    }
  };

  deleteReportModal = async (report_id) => {
    this.setState({
      request_main_section: false,
      report_id: report_id,
      requestErrorMessage: "",
      requestErrorMessageTitle: "",
      requestSuccessMessage: "",
    });
    window.$("#modalRequests").modal("show");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  formatOrderno = (value) => {
    return (
      <a
        href={`${APP_URL}ViewSalesOrder/${value}`}
        className="text-primary"
        target="_blank"
      >
        {value}
      </a>
    );
  };

  formatCommission = (value) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    );
  };

  onSelect = (value) => {
    this.setState({
      value,
      start_date: value.start.format("YYYY-MM-DD"),
      end_date: value.end.format("YYYY-MM-DD"),
      isOpen: false,
    });
  };

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    //  let tableTitle = this.props.props.match.params.api_url.replaceAll("_", " ");
    const Theme = {
      palette: {
        primary: {
          contrastText: "#FFFFFF",
          dark: "#000000",
          main: "#000000",
          light: "#000000",
        },
      },
    };
    const { isOpen, value, start_date, end_date } = this.state;
    const formattedDateRange = start_date ? `${start_date} - ${end_date}` : "";
    const options = {
      selectableRows: "multiple", //Enable checkboxes for row selection
    };
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name={`Agent Report / ${this.state.title}`}
                        icon="icon ni ni-reports"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContentAgent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <a
                              onClick={() =>
                                this.addMenuHistory("/metaBaseSettings")
                              }
                              style={{ cursor: "pointer", color: "#fff" }}
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-setting"></em>
                              &nbsp;Report Settings
                            </a>
                          </li>
                          <li className="nk-block-tools-opt">
                            <a
                              onClick={() =>
                                this.addMenuHistory("/agentReports")
                              }
                              style={{ cursor: "pointer" }}
                              className="btn btn-light"
                            >
                              <em class="icon ni ni-back-ios"></em>
                              &nbsp;Back
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-4">
                  {/* Table 5th Col Start */}
                  {this.state.iframe_url &&
                  this.state.iframe_url === "integration_disabled" ? (
                    <div className="col-md-12">
                      <div class="alert alert-pro alert-danger">
                        <div class="alert-text">
                          <h6>Metabase integration disabled</h6>
                          <p>
                            Metabase integration is currently disabled. Please
                            contsct administrator to enable it.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : this.state.iframe_url &&
                    this.state.iframe_url === "missing_api_url" ? (
                    <div className="col-md-12">
                      <div class="alert alert-pro alert-danger">
                        <div class="alert-text">
                          <h6>Metabase API URL Missing</h6>
                          <p>
                            Metabase API URL is missing. Please contact
                            administrator to enable it.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : this.state.iframe_url &&
                    this.state.iframe_url === "not_found" ? (
                    <div className="col-md-12">
                      <div class="alert alert-pro alert-danger">
                        <div class="alert-text">
                          <h6>Report Not Found</h6>
                          <p>
                            Report you are trying to access is either not
                            active, published or processed.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <iframe
                      src={this.state.iframe_url}
                      height="100%"
                      width="100%"
                      style={{
                        height: "100vh",
                        marginTop: "0px",
                        marginLeft: "-5px",
                        border: "0",
                      }}
                      id="report_iframe"
                    ></iframe>
                  )}

                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewAgentReport);
