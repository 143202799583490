import { React } from "react";
import { Component } from "react";

import { COMPANY_NAME } from "../../../config/config";
import axios from "axios";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";

import {
  getClientMetabseReports,
  deleteClientReport,
} from "./../../../config/metabase_api_calls";

import {
  dateString,
  APP_URL,
  AGENT_SERVER_URL,
  APP_LIVE_URL,
} from "./../../../config/config";

import $ from "jquery";

import DataTable from "../../../components/Tables/DataTableReports";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Link } from "react-router-dom";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import Tooltip from "@material-ui/core/Tooltip";

import dateFormat, { masks } from "dateformat";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import TextField from "../../../components/InputFields/TextField";
import MutextField from "@material-ui/core/TextField";
import * as XLSX from "xlsx";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  columnsServices,
} from "./../../../config/config";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import { extendMoment } from "moment-range";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const extendedMoment = extendMoment(moment);

const animatedComponents = makeAnimated();

const auth = new HelperClass();

class MetabaseReports extends Component {
  constructor() {
    var date = (date = new Date()),
      y = date.getFullYear(),
      m = date.getMonth();

    var datestring = new Date().toISOString().slice(0, 10);

    super();
    this.decimalPoint = Number(auth.getDecimalPoint());
    this.billing_decimalPoint = Number(auth.getBillingDecimalPoint());
    const today = extendedMoment();
    const monthAgo = moment().subtract(1, "month");
    this.state = {
      tableData: [],
      tableLoader: true,
      //start_date: monthAgo.format("YYYY-MM-DD"),
      //end_date: today.format("YYYY-MM-DD"),
      start_date: "",
      end_date: "",
      selected_order_accountno: null,
      selected_client_accountno: null,
      selected_agent_accountno: null,
      value: moment.range(monthAgo, today),
      commission_title_array: "get_commission_reports",
      commissionReportsArray: [],
      all_active_agents: [],
      all_active_clients: [],
      all_complete_orders: [],
      deposit_main_section: false,
      depositErrorMessage: "",
      depositSuccessMessage: "",
      emailto: "",
      emailcc: "",
      emailbcc: "",
      emailsubject: "",
      emailcontent: "",
      email_button: false,
      emailErrorMessage: "",
      emailErrorMessageTitle: "",
      emailSuccessMessage: "",
      orderno: "",
      form_title: "",
      formDetails: [],
      formGroups: [],
      is_submitted: "",
      payment_method_title: "",
      selected_payment_method: "",
      paymentDetails: [],
      requestDetails: [],
      request_main_section: true,
      requestErrorMessage: "",
      requestErrorMessageTitle: "",
      requestSuccessMessage: "",
      commission_amount: 0,
      payment_amount: (0).toFixed(this.billing_decimalPoint),
      payment_receipt: "",
      payment_notes: "",
      payment_date: new Date(),
      payment_method_pay: "Quickbooks",
      add_picture: "",
      add_picture_name: "",
      fundErrorMessage: "",
      fundSuccessMessage: "",
      add_funds_button: false,
      agent_pending_commision: 0,
      agent_paid_commision: 0,

      successMessage: "",
      errorMessage: "",
      document_file: "",
      csvData: [],

      selectedRows: [],
      bulkAction: "",

      report_id: "",

      columnsArray: [
        { name: "title", label: "Title" },
        { name: "client_name", label: "Client" },
        {
          name: "created_at",
          label: "Created Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "published_at",
          label: "Published Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "status",
          label: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setStatus(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "report_status",
          label: "Published Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setStatusPublished(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "url",
          label: "Report",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setReportLink(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "id",
          label: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setEditLink(value)}</div>;
            },
          },
        },
      ],
    };
  }

  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    //await this.getActiveAgents();
    //await this.getActiveClients();
    //await this.getCompleteOrders();
    const reportsData = await getClientMetabseReports(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("getClientMetabseReports: ", reportsData.data);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (reportsData.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
      });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({
        commissionReportsArray: reportsData.data.data,
        tableLoader: false,
      });
    } else {
      console.log("Last: ", reportsData.data);
      //window.location.replace("/error");
    }
    $("#viewBtn").show();
    $("#btnloader").hide();
  }

  format_date = (value) => {
    let date = value;
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy");
    } else {
      return "---";
    }
  };

  setStatus = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === "processing") {
      return (
        <div>
          <span className="badge badge-dim badge-danger">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> In process
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-success">
            {" "}
            <em className="icon ni ni-done "></em> Processed
          </span>
        </div>
      );
    }
  };

  setStatusPublished = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === 0) {
      return (
        <div>
          <span className="badge badge-dim badge-danger">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> Not
            Published
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-success">
            {" "}
            <em className="icon ni ni-done "></em> Published
          </span>
        </div>
      );
    }
  };

  setReportLink = (value, tableMeta) => {
    let domain_str = value;
    let report_id = tableMeta.rowData[7];
    //console.log("domain_str: ", domain_str);
    if (domain_str && domain_str !== "") {
      return (
        <Tooltip title="View report">
          <a
            href={`${APP_URL}viewMetabaseReport/${report_id}`}
            title="View report"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
            }}
            target="_blank"
          >
            <em
              class="icon ni ni-reports text-primary"
              style={{ fontSize: "22px" }}
            ></em>
          </a>
        </Tooltip>
      );
    } else {
      return "---";
    }
  };

  setEditLink = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str && domain_str !== "") {
      return (
        <>
          <Tooltip title="Edit report">
            <a
              onClick={() =>
                this.addMenuHistory("/metabaseReportsEdit/" + domain_str)
              }
              style={{ cursor: "pointer" }}
            >
              <em
                class="icon ni ni-edit text-primary"
                style={{ fontSize: "22px", paddingRight: "5px" }}
              ></em>
            </a>
          </Tooltip>

          <Tooltip title="Delete report">
            <a
              onClick={() => this.deleteReportModal(domain_str)}
              style={{ cursor: "pointer" }}
            >
              <em
                class="icon ni ni-trash text-danger"
                style={{
                  fontSize: "22px",
                  paddingLeft: "5px",
                  borderLeft: "1px solid rgb(0 0 0 / 87%)",
                }}
              ></em>
            </a>
          </Tooltip>
        </>
      );
    } else {
      return "---";
    }
  };

  deleteReportModal = async (report_id) => {
    this.setState({
      request_main_section: false,
      report_id: report_id,
      requestErrorMessage: "",
      requestErrorMessageTitle: "",
      requestSuccessMessage: "",
    });
    window.$("#modalRequests").modal("show");
  };

  deleteReport = async () => {
    const { report_id } = this.state;
    this.setState({
      diabled: true,
      report_id: report_id,
      requestErrorMessage: "",
      requestErrorMessageTitle: "",
      requestSuccessMessage: "",
    });
    const reportsData = await deleteClientReport(
      auth.getAccount(),
      auth.getToken(),
      report_id
    );
    //console.log("getCommissionReports: ", reportsData.data);

    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      reportsData.data.status === 404 &&
      reportsData.data.message === "Report not found."
    ) {
      //window.location.replace("/error");
      this.setState({
        diabled: false,
        requestErrorMessage: "Report not found.",
        requestErrorMessageTitle: "Error",
        requestSuccessMessage: "",
      });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({
        requestErrorMessage: "",
        requestErrorMessageTitle: "",
        requestSuccessMessage:
          "Report deleted successfully. Please wait while we refresh the page.",
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      console.log("Last: ", reportsData.data);
      this.setState({
        diabled: false,
        requestErrorMessage: "There is some error while deleting the report.",
        requestErrorMessageTitle: "System Error",
        requestSuccessMessage: "",
      });
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  formatOrderno = (value) => {
    return (
      <a
        href={`${APP_URL}ViewSalesOrder/${value}`}
        className="text-primary"
        target="_blank"
      >
        {value}
      </a>
    );
  };

  formatCommission = (value) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    );
  };

  onSelect = (value) => {
    this.setState({
      value,
      start_date: value.start.format("YYYY-MM-DD"),
      end_date: value.end.format("YYYY-MM-DD"),
      isOpen: false,
    });
  };

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  regenerate_report = async () => {
  };

  open_agent_requests = async (value, tableMeta) => {
  };

  open_deposit_request = async (value, tableMeta) => {
  };

  modalHideMarketingPDF = (id) => {
    window.$("#" + id).modal("hide");
  };

  

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    console.log("handleChange " + name + " " + value);
    this.setState({
      [name]: value,
    });
  };

  handleDateChange = async (date) => {
    await this.handleStatesDynamic(
      "start_date",
      date.toISOString().slice(0, 10)
    );
    console.log("start_date " + this.state.start_date);
  };

  handleDateChangeEnd = async (date) => {
    await this.handleStatesDynamic("end_date", date.toISOString().slice(0, 10));
    console.log("end_date " + this.state.end_date);
  };

  handleStatesDynamic = async (start_date, date) => {
    this.setState({
      [start_date]: date,
    });
  };

  handleStates = async (start_date, end_date) => {
    this.setState({
      start_date: start_date,
      end_date: end_date,
    });
  };

  

  capitalizeFirstLetters = (str) => {
    return str.toLowerCase().replace(/^\w|\s\w/g, function (letter) {
      return letter.toUpperCase();
    });
  };

  formatAsCurrency = (amount) => {
    //console.log("amount: "+  typeof amount + " - " + amount);
    //console.log("amount: ", typeof amount);
    if (!amount) {
      amount = 0;
    }
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: this.billing_decimalPoint,
      maximumFractionDigits: this.billing_decimalPoint,
    });
  };

  

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation selectedFiles PDF, DOC, DOCX, ZIP, TXT, PNG, JPEG, JPG
    const allowedFileTypes = [".png", ".jpg", ".jpeg", ".pdf"];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        uploadErrorMessage:
          "Invalid file type. Please select a valid file format.",
        uploadErrorTitle: "Invalid File",
        add_picture: "",
        add_picture_name: "",
      });
      return;
    }

    // File size validation (e.g., limit to 10MB)
    const maxSizeBytes = 10 * 1024 * 1024; // 10MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        uploadErrorMessage:
          "File size exceeds the limit (10MB). Please choose a smaller file.",
        uploadErrorTitle: "Invalid File",
        add_picture: "",
        add_picture_name: "",
      });
      return;
    }

    // Clear any previous errors
    this.setState({
      add_picture: selectedFile,
      uploadErrorMessage: "",
      uploadErrorTitle: "",
      add_picture_name: selectedFile.name,
    });
    //return true;
  };

  handleModalopen = () => {
    window.$("#modalInsertSalesOrder").modal("show");
  };

  handleFileUploadClick = () => {
    document.getElementById("fileInput").click();
  };

  parseExcelData = (data) => {
    try {
      const workbook = XLSX.read(data, { type: "binary" });
      return workbook;
    } catch (error) {
      console.error("Error parsing Excel data:", error);
      return null;
    }
  };

  parseSheetData = (sheet) => {
    const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    const headers = data[0]; // Assuming first row contains headers
    const rows = data.slice(1); // Exclude headers
    return rows.map((row) =>
      headers.reduce((acc, header, index) => {
        acc[header] = row[index];
        return acc;
      }, {})
    );
  };

  downloadPdfRfq = async (d_f_name) => {
    var url = d_f_name;
    //let file_name = this.extractFileNameFromUrl(d_f_name);
    url = APP_LIVE_URL + "files_data/kyc_signup/" + d_f_name;
    //console.log("downloadPdfRfq: ", url);
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = d_f_name;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "There is some error while downloading the attachment.",
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  handleRowSelection = (rows) => {
    // Update state with the selected rows
    this.setState({ selectedRows: rows });
    console.log("Selected rows:", rows);
  };

  handleBulkActionChange = (event) => {
    const action = event.target.value;
    this.setState({ bulkAction: action });

    if (action === "approve") {
      this.handleApprove();
    } else if (action === "delete") {
      this.handleDelete();
    }
  };

  handleApprove = () => {
    const { selectedRows } = this.state;
    console.log("Approve rows:", selectedRows);
    // Add your approve logic here
  };

  handleDelete = () => {
    const { selectedRows } = this.state;
    console.log("Delete rows:", selectedRows);
    // Add your delete logic here
  };

  render() {
    //  let tableTitle = this.props.props.match.params.api_url.replaceAll("_", " ");
    const Theme = {
      palette: {
        primary: {
          contrastText: "#FFFFFF",
          dark: "#000000",
          main: "#000000",
          light: "#000000",
        },
      },
    };
    const { isOpen, value, start_date, end_date } = this.state;
    const formattedDateRange = start_date ? `${start_date} - ${end_date}` : "";
    const options = {
      selectableRows: "multiple", // Enable checkboxes for row selection
    };
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                              <div className="nk-block-between">
                                <div className="nk-block-head-content">
                                  <h3 className="nk-block-title page-title">
                                    <PageTitle
                                      name="Client Reports"
                                      icon="icon ni ni-reports"
                                    />
                                  </h3>
                                </div>
                                <div className="nk-block-head-content" id="HeadContentAgent">
                                  <div className="toggle-wrap nk-block-tools-toggle">
                                    <a
                                      href="#"
                                      className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                      data-target="pageMenu"
                                    >
                                      <em className="icon ni ni-more-v"></em>
                                    </a>
                                    <div
                                      className="toggle-expand-content"
                                      data-content="pageMenu"
                                    >
                                      <ul className="nk-block-tools g-3">
                                        <li className="nk-block-tools-opt">
                                          <a
                                            onClick={() =>
                                              this.addMenuHistory("/metaBaseSettings")
                                            }
                                            style={{ cursor: "pointer", color: "#fff" }}
                                            className="btn btn-primary"
                                          >
                                            <em className="icon ni ni-setting"></em>
                                            &nbsp;Report Settings
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-4">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <>
                          <DataTable
                            columns={this.state.columnsArray}
                            tableData={this.state.commissionReportsArray}
                            title=""
                            //options={options}
                            //onRowSelection={this.handleRowSelection}
                          />
                        </>
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/* Link Modal MODAL START */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalDeposit"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em> Add
                              Commission Payment for the Order#{" "}
                              <sup className="text-primary">
                                ({this.state.orderno})
                              </sup>
                            </strong>
                          </div>
                          <a
                            className="close"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.modalHideMarketingPDF("modalDeposit");
                            }}
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          {this.state.depositErrorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "20px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>{this.state.emailErrorMessageTitle}</h4>

                                  <p>{this.state.depositErrorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.is_submitted === "No" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "20px" }}
                            >
                              <div className="alert alert-pro alert-warning">
                                <div className="alert-text">
                                  <h4>Billing Form Missing</h4>

                                  <p>
                                    Channel partner have not submissted the
                                    billing / payment details form.{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.deposit_main_section === true ? (
                            <div class="row g-gs">
                              {this.state.fundSuccessMessage !== "" ? (
                                <div class="col-sm-12">
                                  <div class="alert alert-pro alert-success">
                                    <div class="alert-text">
                                      <h6>Success</h6>
                                      <p>{this.state.fundSuccessMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.fundErrorMessage !== "" ? (
                                <div class="col-sm-12">
                                  <div class="alert alert-pro alert-danger">
                                    <div class="alert-text">
                                      <h6>Error</h6>
                                      <p>{this.state.fundErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div class="col-sm-12">
                                <p>
                                  The process to pay a channel partner involves
                                  entering the payment amount, specifying the
                                  payment date, selecting a payment method
                                  (Submitted by the CP in payment form),
                                  uploading a receipt as proof of payment, and
                                  optionally adding a short note about the
                                  payment. Once all details are provided, the
                                  payment can be submitted by clicking the "Pay"
                                  button.
                                </p>
                              </div>
                              <div class="col-sm-12">
                                <div class="row g-4">
                                  <div className="col-md-6 col-lg-6 col-xl-6">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          <Tooltip
                                            title="The commission amount from the order to be deposited into the channel partner's account."
                                            placement="right"
                                          >
                                            <em
                                              className="icon ni ni-info"
                                              style={{
                                                color: "#6576ff",
                                              }}
                                            ></em>
                                          </Tooltip>{" "}
                                          Commission Amount <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="payment_amount"
                                          name="payment_amount"
                                          type="text"
                                          label=""
                                          value={this.state.payment_amount}
                                          onChange={this.handleChange}
                                          onKeyPress={() => {
                                            this.checkNumber();
                                          }}
                                          inputProps={{
                                            maxLength: 9, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                      <div class="form-note">
                                        Remaining commission{" "}
                                        {this.formatAsCurrency(
                                          this.state.agent_pending_commision
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6 col-lg-6 col-xl-6">
                                    <div class="form-group">
                                      <label class="form-label">
                                        Payment Date
                                      </label>
                                      <div class="form-control-wrap">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <DateTimePicker
                                            variant="dialog"
                                            name="payment_date"
                                            id="payment_date"
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy HH:mm"
                                            fullWidth
                                            value={this.state.payment_date}
                                            onChange={this.handleDateChange}
                                            rientation="landscape"
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                      <div class="form-note">
                                        Date of payment sent.
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-lg-6 col-xl-6">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          <Tooltip
                                            title="Receipt of the payment sent to the channel partner."
                                            placement="right"
                                          >
                                            <em
                                              className="icon ni ni-info"
                                              style={{
                                                color: "#6576ff",
                                              }}
                                            ></em>
                                          </Tooltip>{" "}
                                          Upload Receipt <sup></sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group signatory_upload">
                                        <div class="custom-file">
                                          <input
                                            type="file"
                                            class="custom-file-input"
                                            id="add_picture"
                                            name="add_picture"
                                            defaultValue={
                                              this.state.add_picture
                                            }
                                            accept=".png, .jpg, .jpeg, .pdf"
                                            onChange={this.handleFileChange}
                                          />
                                          <label
                                            class="custom-file-label"
                                            for="add_picture"
                                          >
                                            Choose file
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {this.state.formGroups &&
                                  this.state.formGroups.length > 0 ? (
                                    <div className="col-md-6 col-lg-6 col-xl-6">
                                      <div class="form-group">
                                        <label
                                          class="form-label"
                                          htmlFor="emailsubject"
                                        >
                                          Payment Method <sup>*</sup>
                                        </label>
                                        <div class="form-control-wrap ">
                                          <div class="form-control-select customSelect">
                                            <select
                                              name="selected_payment_method"
                                              class="form-control form-control-lg custom-select-lg"
                                              id="default-06"
                                              onChange={this.handleChangeSelect}
                                            >
                                              <option value="" key="2weds">
                                                Select payment method
                                              </option>
                                              {this.state.formGroups.map(
                                                (grp, index) => (
                                                  <option
                                                    value={`${grp.id}###${grp.group_title}`}
                                                    key={`aa${index}`}
                                                  >
                                                    {grp.group_title}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>

                              {this.state.paymentDetails &&
                              this.state.paymentDetails.length > 0 ? (
                                <div class="col-sm-12">
                                  <div className="card card-bordered mt-1">
                                    <div class="card-inner-group">
                                      <div class="card-inner">
                                        <div class="sp-plan-head">
                                          <h6 class="title">
                                            Payment Method Details
                                          </h6>
                                        </div>
                                        <div
                                          class="sp-plan-desc sp-plan-desc-mb"
                                          id="paymentMethodDetails"
                                        >
                                          <table class="table table-bordered table-striped">
                                            <tbody class="text-dark">
                                              {this.state.paymentDetails.map(
                                                (pay, idx) => (
                                                  <tr key={`pid${idx}`}>
                                                    <td
                                                      style={{
                                                        fontWeight: "600",
                                                      }}
                                                    >
                                                      {pay.questionText}
                                                    </td>
                                                    <td>{pay.answer}</td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div class="col-sm-12 mt-2">
                                <div class="form-group">
                                  <label
                                    class="form-label"
                                    htmlFor="emailcontent"
                                  >
                                    Notes <sup>*</sup>
                                  </label>
                                  <div class="form-control-wrap">
                                    <textarea
                                      class="form-control"
                                      id="payment_notes"
                                      name="payment_notes"
                                      value={this.state.payment_notes}
                                      onChange={this.handleChange}
                                      placeholder="Add payment notes with a maximum of 500 characters.."
                                      maxLength="500"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12 mt-2 d-flex justify-content-end">
                                <div class="nk-block-head-content">
                                  <ul class="nk-block-tools gx-3">
                                    {this.state.add_funds_button === true ? (
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        disabled
                                      >
                                        <span
                                          className="spinner-grow spinner-grow-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        <span> Processing... </span>
                                      </button>
                                    ) : (
                                      <li>
                                        <a
                                          onClick={() =>
                                            this.addFundsInAgentAccount()
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: "#fff",
                                          }}
                                          class="btn btn-primary"
                                        >
                                          <em class="icon ni ni-check-round-cut"></em>{" "}
                                          <span>Approve & Add Payment</span>{" "}
                                        </a>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* LINK MODAL End */}
                  {/* Link Modal MODAL START */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalRequests"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em> Delete
                              Report
                            </strong>
                          </div>
                          <a
                            className="close"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.modalHideMarketingPDF("modalRequests");
                            }}
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          {this.state.request_main_section === true ? (
                            FormLoader()
                          ) : (
                            <div class="row g-gs">
                              {this.state.requestErrorMessage !== "" ? (
                                <div class="col-sm-12">
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>
                                          {this.state.requestErrorMessageTitle}
                                        </h4>

                                        <p>{this.state.requestErrorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.requestSuccessMessage !== "" ? (
                                <div class="col-sm-12">
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>

                                        <p>
                                          {this.state.requestSuccessMessage}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div class="col-sm-12">
                                <div className="card card-bordered mt-1">
                                  <div class="card-inner-group">
                                    <div class="card-inner">
                                      <div
                                        class="sp-plan-desc sp-plan-desc-mb"
                                        id="paymentMethodDetails"
                                      >
                                        <div id="no-more-tables-new">
                                          Are you sure you want to permanently
                                          delete this report? Deleting this
                                          report will also disable the dashboard
                                          if it is attached to one.
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12">
                                <div class="d-flex justify-content-end">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      this.modalHideMarketingPDF(
                                        "modalRequests"
                                      );
                                    }}
                                    class="btn btn-light mr-2"
                                  >
                                    Cancel
                                  </a>
                                  <button
                                    type="button"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.deleteReport()}
                                    class="btn btn-primary"
                                    disabled={this.state.diabled}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* LINK MODAL End */}
                  {/* New Client Modal Start */}
                  <div
                    className="modal fade"
                    tabindex="-1"
                    id="modalInsertSalesOrder"
                  >
                    <div
                      className="modal-dialog modal-dialog-top modal-xl"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">
                            <em
                              className="icon ni ni-upload"
                              style={{ marginRight: "10px" }}
                            ></em>
                            <span> Import Orders</span>
                            <a
                              onClick={() =>
                                this.downloadPdfRfq("order_import.csv")
                              }
                              style={{ cursor: "pointer", marginLeft: "15px" }}
                            >
                              <span class="badge badge-outline-primary">
                                <em class="icon ni ni-download"></em> Download
                                Sample
                              </span>
                            </a>
                          </h5>
                        </div>
                        <div className="modal-body">
                          <div className="row g-4">
                            {this.state.errorMessage !== "" ? (
                              <div className="col-md-12 mb-3">
                                <div
                                  className="example-alert example-alert-revoke"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.errorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.successMessage !== "" ? (
                              <div className="col-md-12 mb-3">
                                <div
                                  className="example-alert example-alert-revoke"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.successMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-md-6">
                              <div className="form-group">
                                <div class="form-label-group">
                                  <label class="form-label">
                                    Select Channel Partner <sup>*</sup>
                                    <span class="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <Select
                                    options={this.state.all_active_agents}
                                    name="selected_accountno"
                                    placeholder="Select Channel Partner"
                                    value={this.state.selected_agent}
                                    autoComplete="off"
                                    emptyMessage="Channel Partner not found"
                                    onChange={this.handleChangeSearch}
                                    isClearable
                                    isSearchable
                                    components={animatedComponents}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        lineHeight: "30px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div class="form-label-group">
                                  <label class="form-label">
                                    Select Client <sup>*</sup>
                                    <span class="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <Select
                                    options={this.state.filtered_clients}
                                    name="selected_accountno"
                                    placeholder="Select Clients"
                                    value={this.state.selected_client}
                                    autoComplete="off"
                                    emptyMessage="Clients not found"
                                    onChange={this.handleChangeSearchClient}
                                    isClearable
                                    isSearchable
                                    components={animatedComponents}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        lineHeight: "30px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-control-group"></div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    Upload File
                                    <sup> *</sup>
                                  </label>
                                </div>
                                <div
                                  className="form-control-group"
                                  id="fileContainer"
                                >
                                  <input
                                    type="file"
                                    id="document_file"
                                    className="file-block"
                                    name="document_file"
                                    defaultValue={this.state.document_file}
                                    accept=".csv"
                                    onChange={(e) => this.handleFileUpload(e)}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 d-flex justify-content-end">
                              <button
                                type="button"
                                onClick={() =>
                                  this.processCsvData(this.state.csvData)
                                }
                                className="btn btn-primary"
                                disabled={
                                  this.state.disabled || this.state.isProcessing
                                } // disable button when processing
                              >
                                {this.state.isProcessing ? (
                                  <span>Processing...</span>
                                ) : (
                                  <span>Import</span>
                                )}
                                <em className="icon ni ni-upload"></em>
                              </button>
                            </div>
                            <div className="col-md-12">
                              <div className="example-alert">
                                <div className="alert alert-light">
                                  <ul className="list">
                                    <li>Supported file formats include: CSV</li>
                                    <li>
                                      Invoice# should be a six digit number.
                                    </li>
                                    <li>
                                      Do not change the first row from sample
                                      CSV file.
                                    </li>
                                    <li>
                                      All of the columns in CSV file are
                                      required.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* New Client Modal End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MetabaseReports);
