import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  getAdminReports,
  updateAdminDashboard,
  listActiveClients,
  getClientReports,
  listClientAssignedDashboard,
  updateClientDashboard,
  listAgentAssignedDashboard,
  getAgentReports,
  updateAgentDashboard
} from "./../../../config/reseller_api_calls";
import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  AGENT_SERVER_URL,
} from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
//import { Editor } from "@tinymce/tinymce-react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Tooltip from "@material-ui/core/Tooltip";
const animatedComponents = makeAnimated();
const auth = new HelperClass();

class MetaBaseSettings extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      tableDataAgents: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      api_url: "",
      api_accountno: "",
      api_token: "",
      isEditorReady: false,
      status: 1,
      admin_dashboard_check: false,
      admin_reports_check: true,
      all_admin_reports: [],
      selected_admin_report: null,
      selected_admin_report_id: "",
      errorMessageAdmin: "",
      successMessageAdmin: "",
      adminLoader: true,

      client_dashboard_check: false,
      client_reports_check: true,
      all_client_reports: [],
      selected_client_report: null,
      selected_client_report_id: "",
      errorMessageClient: "",
      successMessageClient: "",
      clientLoader: false,
      client_title: "",
      report_id: "",

      agent_dashboard_check: false,
      agent_reports_check: true,
      all_agent_reports: [],
      selected_agent_report: null,
      selected_agent_report_id: "",
      errorMessageAgent: "",
      successMessageAgent: "",
      agentLoader: false,

      selected_accountno: null,
      client_accountno: "",
      all_active_clients: [],
      selected_account: "",
      dashboardLoader: false,

      agent_accountno: "",
      agent_dashboard_check: false,
      all_agent_reports: [],
      selected_agent_report: null,
      selected_agent_report_id: "",
      errorMessageAgents: "",
      successMessageAgents: "",
      agentLoader: false,

      columnServices: [
        {
          name: "client_name",
          label: "Client",
        },
        {
          name: "status",
          label: "Dashboard Status",
        },
        {
          name: "report_title",
          label: "Report Title",
        },
        {
          name: "report_id",
          label: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <Tooltip title="Update client dashboard" placement="top">
                    <a
                      onClick={() => this.showUpdateModal(value, tableMeta)}
                      style={{ cursor: "pointer" }}
                    >
                      <span class="badge badge-outline-primary">
                        <em class="icon ni ni-edit"></em>
                      </span>
                    </a>
                  </Tooltip>
                </div>
              );
            },
          },
        },
        {
          name: "client_accountno",
          label: "client_accountno",
          options: { display: false },
        },
      ],
      columnServicesAgents: [
        {
          name: "client_name",
          label: "Agent",
        },
        {
          name: "status",
          label: "Dashboard Status",
        },
        {
          name: "report_title",
          label: "Report Title",
        },
        {
          name: "report_id",
          label: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <Tooltip title="Update agent dashboard" placement="top">
                    <a
                      onClick={() => this.showUpdateModalAgents(value, tableMeta)}
                      style={{ cursor: "pointer" }}
                    >
                      <span class="badge badge-outline-primary">
                        <em class="icon ni ni-edit"></em>
                      </span>
                    </a>
                  </Tooltip>
                </div>
              );
            },
          },
        },
        {
          name: "agent_accountno",
          label: "agent_accountno",
          options: { display: false },
        },
      ],
    };
  }

  async componentDidMount() {
    const reportsData = await getAdminReports(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("reportsData.data.data: ", reportsData.data);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (reportsData.data.status === 404) {
      this.setState({
        errorMessageAdmin: "Admin reports not found.",
        successMessageAdmin: "",
        adminLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      let first_value = null;
      let admin_dashboard_enabled = false;
      let dashboardList = reportsData.data.dashboardList;
      if (dashboardList && dashboardList.length > 0) {
        first_value = dashboardList[0]["report_id"];
        if (dashboardList[0]["is_active"] === 1) {
          admin_dashboard_enabled = true;
        }
      }
      let all_clients = [];
      if (reportsData.data.data && reportsData.data.data.length > 0) {
        for (let i = 0; i < reportsData.data.data.length; i++) {
          let newArr = {
            label: reportsData.data.data[i]["title"],
            value: reportsData.data.data[i]["id"],
          };
          all_clients.push(newArr);
        }
      }

      this.setState({
        all_admin_reports: all_clients,
        admin_dashboard_check: admin_dashboard_enabled,
        adminLoader: false,
      });

      if (first_value && first_value !== "") {
        let get_initial_selection = this.getInitialFormSelectedOption(
          first_value,
          all_clients
        );
        console.log("get_initial_selection: ", get_initial_selection);

        this.setState(
          (prevState) => ({
            selected_admin_report_id: get_initial_selection.value,
            selected_admin_report: get_initial_selection,
          }),
          () => {
            // This callback function is called right after the state is updated
            //console.log("Updated state:", this.state.service_type_id);
          }
        );
      }
    } else {
      this.setState({
        errorMessageAdmin:
          "There is some error while getting the admin dashboard reports.",
        successMessageAdmin: "",
        adminLoader: false,
      });
    }
  }

  getInitialFormSelectedOption = (value, all_clients) => {
    // Retrieve the initial selected option from your options array
    let options = all_clients; // Replace with your actual options array

    console.log("value: ", value);
    console.log("all_clients: ", all_clients);

    if (options) {
      return options.find((option) => option.value === value) || null;
    }
  };

  getClientActiveReports = async (client_accountno) => {
    const reportsData = await getClientReports(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    console.log("reportsData.data.data: ", reportsData.data);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (reportsData.data.status === 404) {
      this.setState({
        errorMessageClient: "There are no active client dashboard reports available.",
        successMessageClient: "",
      });
      //window.location.replace("/error");
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      let first_value = this.state.selected_client_report;

      let all_clients = [];
      if (reportsData.data.data && reportsData.data.data.length > 0) {
        for (let i = 0; i < reportsData.data.data.length; i++) {
          let newArr = {
            label: reportsData.data.data[i]["title"],
            value: reportsData.data.data[i]["id"],
          };
          all_clients.push(newArr);
        }
      }

      this.setState({
        all_client_reports: all_clients,
      });

      if (first_value && first_value !== "") {
        let get_initial_selection = this.getInitialFormSelectedOption(
          first_value,
          all_clients
        );
        console.log("get_initial_selection: ", get_initial_selection);

        this.setState(
          (prevState) => ({
            selected_client_report_id: get_initial_selection.value,
            selected_client_report: get_initial_selection,
          }),
          () => {
            // This callback function is called right after the state is updated
            //console.log("Updated state:", this.state.service_type_id);
          }
        );
      }
    } else {
      this.setState({
        errorMessageClient:
          "There are no active client dashboard reports available.",
        successMessageClient: "",
      });
    }
  };

  showUpdateModal = async (value, tableMeta) => {
    this.setState({
      errorMessageClient: "",
      successMessageClient: "",
    });
    console.log("value: ", value);
    console.log("showUpdateModal: ", tableMeta.rowData);
    let report_id = tableMeta.rowData[3];
    let client_title = tableMeta.rowData[0];
    let client_accountno = tableMeta.rowData[4];
    let client_dashboard_check = false;
    if (tableMeta.rowData[1] === "Assigned") {
      client_dashboard_check = true;
    }
    this.setState(
      (prevState) => ({
        ...prevState, // Preserve previous state
        disabled: false,
        client_accountno: client_accountno,
        selected_client_report:
          report_id && report_id !== "" ? report_id : null,
        client_title: client_title,
        client_dashboard_check: client_dashboard_check,
      }),
      async () => {
        // This runs AFTER state is updated
        await this.getClientActiveReports(client_accountno);
        window.$("#modalClientDashboard").modal("show");
      }
    );
  };

  getClientDashboardSettings = async () => {
    this.setState({
      clientLoader: true,
      errorMessageClient: "",
    });
    const servicesResponce = await listClientAssignedDashboard(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("listClientAssignedDashboard: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessageClient: "Settings not found.",
        clientLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        clientLoader: false,
      });
    } else {
      this.setState({
        errorMessage: "",
        clientLoader: false,
      });
    }
  };

  listAllActiveClients = async () => {
    const servicesResponce = await listActiveClients(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("listAllActiveClients: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "The question not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (
        servicesResponce.data.all_data &&
        servicesResponce.data.all_data.length > 0
      ) {
        for (let i = 0; i < servicesResponce.data.all_data.length; i++) {
          let newArr = {
            label:
              servicesResponce.data.all_data[i]["name"] +
              " (" +
              servicesResponce.data.all_data[i]["comp_name"] +
              ")",
            value: servicesResponce.data.all_data[i]["accountno"],
          };
          all_clients.push(newArr);
        }
      }
      this.setState({
        all_active_clients: all_clients,
      });
    } else {
      this.setState({
        errorMessage: "",
      });
    }
  };

  listClientAssignedDashboard = async () => {
    const servicesResponce = await listClientAssignedDashboard(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("listClientAssignedDashboard: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "The question not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
      });
    } else {
      this.setState({
        errorMessage: "",
      });
    }
  };

  getAgentDashboardSettings = async () => {
    this.setState({
      clientLoader: true,
      errorMessageClient: "",
    });
    const servicesResponce = await listAgentAssignedDashboard(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("listAgentAssignedDashboard: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessageClient: "Settings not found.",
        clientLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableDataAgents: servicesResponce.data.data,
        clientLoader: false,
      });
    } else {
      this.setState({
        errorMessage: "",
        clientLoader: false,
      });
    }
  };

  showUpdateModalAgents = async (value, tableMeta) => {
    this.setState({
      errorMessageAgents: "",
      successMessageAgents: "",
    });
    console.log("value: ", value);
    console.log("showUpdateModal: ", tableMeta.rowData);
    let report_id = tableMeta.rowData[3];
    let client_title = tableMeta.rowData[0];
    let agent_accountno = tableMeta.rowData[4];
    let agent_dashboard_check = false;
    if (tableMeta.rowData[1] === "Assigned") {
      agent_dashboard_check = true;
    }
    this.setState(
      (prevState) => ({
        ...prevState, // Preserve previous state
        disabled: false,
        agent_accountno: agent_accountno,
        selected_client_report:
          report_id && report_id !== "" ? report_id : null,
        client_title: client_title,
        agent_dashboard_check: agent_dashboard_check,
      }),
      async () => {
        // This runs AFTER state is updated
        await this.getAgentActiveReports(agent_accountno);
        window.$("#modalAgentDashboard").modal("show");
      }
    );
  };

  getAgentActiveReports = async (agent_accountno) => {
    const reportsData = await getAgentReports(
      auth.getAccount(),
      auth.getToken(),
      agent_accountno
    );
    console.log("reportsData.data.data: ", reportsData.data);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (reportsData.data.status === 404) {
      this.setState({
        errorMessageAgents: "There are no active agent dashboard reports available.",
        successMessageAgents: "",
      });
      //window.location.replace("/error");
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      let first_value = this.state.selected_client_report;

      let all_clients = [];
      if (reportsData.data.data && reportsData.data.data.length > 0) {
        for (let i = 0; i < reportsData.data.data.length; i++) {
          let newArr = {
            label: reportsData.data.data[i]["title"],
            value: reportsData.data.data[i]["id"],
          };
          all_clients.push(newArr);
        }
      }

      this.setState({
        all_agent_reports: all_clients,
      });

      if (first_value && first_value !== "") {
        let get_initial_selection = this.getInitialFormSelectedOption(
          first_value,
          all_clients
        );
        console.log("get_initial_selection: ", get_initial_selection);

        this.setState(
          (prevState) => ({
            selected_agent_report_id: get_initial_selection.value,
            selected_agent_report: get_initial_selection,
          }),
          () => {
            // This callback function is called right after the state is updated
            //console.log("Updated state:", this.state.service_type_id);
          }
        );
      }
    } else {
      this.setState({
        errorMessageAgents:
          "There are no active agent dashboard reports available.",
        successMessageAgents: "",
      });
    }
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = tableMeta.rowData[9];
    if (is_default === "No") {
      return (
        <a
          href="#"
          onClick={() => this.setDefaultService(value, tableMeta)}
          disabled={
            this.props.is_msa_signed === "No" ||
            this.props.defaultSettings === "missing"
              ? true
              : false
          }
        >
          Set Default
        </a>
      );
    } else {
      return "Yes";
    }
  };

  handleChangeSearchClient = async (changed_value) => {
    console.log("handleChangeSearchClient: ", changed_value);

    if (changed_value) {
      this.setState(
        (prevState) => ({
          client_accountno: changed_value.value,
          selected_accountno: changed_value,
        }),
        () => {
          // This callback function is called right after the state is updated
          console.log("Updated state:", this.state.client_accountno);
        }
      );
    } else {
      this.setState(
        {
          client_accountno: null,
          selected_accountno: null,
        },
        () => {
          console.log("Cleared selection");
        }
      );
    }
  };

  handleChangeSearch = async (changed_value) => {
    if (changed_value) {
      console.log("selected_admin_report_id : ", changed_value.value);
      this.setState(
        (prevState) => ({
          selected_admin_report_id: changed_value.value,
          selected_admin_report: changed_value,
        }),
        () => {
          // This callback function is called right after the state is updated
          //localStorage.setItem("serviceValue", changed_value.value);
          //this.reloadServices();
        }
      );
    } else {
      this.setState(
        {
          selected_admin_report_id: null,
          selected_admin_report: null,
        },
        () => {
          console.log("Cleared selection");
          //localStorage.removeItem("serviceValue");
          //this.reloadServices();
        }
      );
    }
  };

  handleChangeSearchClientDash = async (changed_value) => {
    if (changed_value) {
      console.log("selected_client_report_id : ", changed_value.value);
      this.setState(
        (prevState) => ({
          selected_client_report_id: changed_value.value,
          selected_client_report: changed_value,
        }),
        () => {
          // This callback function is called right after the state is updated
          //localStorage.setItem("serviceValue", changed_value.value);
          //this.reloadServices();
        }
      );
    } else {
      this.setState(
        {
          selected_client_report_id: null,
          selected_client_report: null,
        },
        () => {
          console.log("Cleared selection");
          //localStorage.removeItem("serviceValue");
          //this.reloadServices();
        }
      );
    }
  };

  handleChangeSearchAgentDash = async (changed_value) => {
    if (changed_value) {
      console.log("selected_client_report_id : ", changed_value.value);
      this.setState(
        (prevState) => ({
          selected_agent_report_id: changed_value.value,
          selected_agent_report: changed_value,
        }),
        () => {
          // This callback function is called right after the state is updated
          //localStorage.setItem("serviceValue", changed_value.value);
          //this.reloadServices();
        }
      );
    } else {
      this.setState(
        {
          selected_agent_report_id: null,
          selected_agent_report: null,
        },
        () => {
          console.log("Cleared selection");
          //localStorage.removeItem("serviceValue");
          //this.reloadServices();
        }
      );
    }
  };

  openEditorHtmlModal = () => {
    window.$("#modalEditorHtml").modal("show");
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  formSave = async (e) => {
    e.preventDefault();
    let { admin_dashboard_check, selected_admin_report_id } = this.state;
    console.log(
      "admin_dashboard_check: " +
        admin_dashboard_check +
        " - " +
        typeof(admin_dashboard_check)
    );
    this.setState({
      errorMessageAdmin: "",
      successMessageAdmin: "",
      disabled: true,
    });

    if(!selected_admin_report_id || selected_admin_report_id === ""){
      this.setState({
        errorMessageAdmin: "Please select the report.",
        successMessageAdmin: "",
        disabled: false,
      });
      return false;
    }

    let adminDash = "No";
    if(admin_dashboard_check === true){
      adminDash = "Yes";
    }

    const saveResponce = await updateAdminDashboard(
      auth.getAccount(),
      auth.getToken(),
      adminDash,
      selected_admin_report_id
    );

    console.log("saveResponce.data", saveResponce.data);

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      return this.setState({
        errorMessageAdmin:
          "There is some error while updating the admin dashboard settings.",
        successMessageAdmin: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessageAdmin: "",
        successMessageAdmin: "Admin dashboard settings updated Successfully.",
        disabled: false,
      });
    } else {
      return this.setState({
        errorMessageAdmin:
          "There is some error while updating the admin dashboard settings.",
        disabled: false,
      });
    }
    setTimeout(() => {
      return this.setState({
        errorMessageAdmin: "",
        successMessageAdmin: "",
        disabled: false,
      });
    }, 4000);
  };

  formSaveClients = async (e) => {
    e.preventDefault();
    let {
      client_dashboard_check,
      selected_client_report_id,
      client_accountno,
    } = this.state;
    console.log(
      "admin_dashboard_check: " +
        client_dashboard_check +
        " - " +
        selected_client_report_id
    );
    this.setState({
      errorMessageClient: "",
      successMessageClient: "",
      disabled: true,
    });

    
    let clientDash = "No";
    if(client_dashboard_check === true){
      clientDash = "Yes";
      if(!selected_client_report_id || selected_client_report_id === ""){
        this.setState({
          errorMessageClient: "Please select the report.",
          successMessageClient: "",
          disabled: false,
        });
        return false;
      }
    }

    const saveResponce = await updateClientDashboard(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      selected_client_report_id,
      clientDash
    );

    console.log("saveResponce.data", saveResponce.data);

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      return this.setState({
        errorMessageClient:
          "There is some error while updating the client dashboard settings.",
        successMessageClient: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessageClient: "",
        successMessageClient: "Client dashboard settings updated Successfully.",

        client_accountno: null,
        selected_accountno: null,
      });
      setTimeout(() => {
        this.getClientDashboardSettings();
        window.$("#modalClientDashboard").modal("hide");
      }, 3000);
    } else {
      return this.setState({
        errorMessageClient:
          "There is some error while updating the client dashboard settings.",
        disabled: false,
      });
    }
    setTimeout(() => {
      return this.setState({
        errorMessageAdmin: "",
        successMessageClient: "",
        disabled: false,
      });
    }, 4000);
  };

  formSaveAgents = async (e) => {
    e.preventDefault();
    let {
      agent_dashboard_check,
      selected_agent_report_id,
      agent_accountno,
    } = this.state;
    console.log(
      "agent_dashboard_check: " +
      agent_dashboard_check +
        " - " +
        selected_agent_report_id
    );
    this.setState({
      errorMessageAgents: "",
      successMessageAgents: "",
      disabled: true,
    });

    
    let clientDash = "No";
    if(agent_dashboard_check === true){
      clientDash = "Yes";
      if(!selected_agent_report_id || selected_agent_report_id === ""){
        this.setState({
          errorMessageAgents: "Please select the report.",
          successMessageAgents: "",
          disabled: false,
        });
        return false;
      }
    }

    const saveResponce = await updateAgentDashboard(
      auth.getAccount(),
      auth.getToken(),
      agent_accountno,
      selected_agent_report_id,
      clientDash
    );

    console.log("saveResponce.data", saveResponce.data);

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      return this.setState({
        errorMessageAgents:
          "There is some error while updating the agent dashboard settings.",
        successMessageAgents: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessageAgents: "",
        successMessageAgents: "Agent dashboard settings updated Successfully.",

        agent_accountno: null,
        selected_accountno: null,
      });
      setTimeout(() => {
        this.getAgentDashboardSettings();
        window.$("#modalAgentDashboard").modal("hide");
      }, 3000);
    } else {
      return this.setState({
        errorMessageAgents:
          "There is some error while updating the agent dashboard settings.",
        disabled: false,
      });
    }
    setTimeout(() => {
      return this.setState({
        errorMessageAgents: "",
        successMessageAgents: "",
        disabled: false,
      });
    }, 4000);
  };

  handleCpDefaultChange = () => {
    this.setState((prevState) => ({
      admin_dashboard_check: !prevState.admin_dashboard_check,
    }));
  };

  handleChangeCheckboxKyc = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
      isBlocking: false,
    });

    //console.log("handleChangeCheckbox:", name + " " + value);
  };

  handleCpDefaultChangeClient = () => {
    this.setState((prevState) => ({
      client_dashboard_check: !prevState.client_dashboard_check,
    }));
  };

  render() {
    return (
      <div className="nk-content " id="Cms_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                {/*<Breadcrumbs
                  separator={<b> / </b>}
                  item={NavLink}
                  finalItem={"b"}
                  finalProps={{
                    style: { color: "red" },
                  }}
                />*/}
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>

                  <Typography color="textPrimary" className="">
                    Metabase Settings
                  </Typography>
                </Breadcrumbs>
                {/*<h3 className="nk-block-title page-title">
                  <a
                    href="/cms/Pageslist"
                    class="btn btn-outline-light"
                    style={{ padding: "0.65rem" }}
                  >
                    <em class="icon ni ni-back-ios"></em>
                  </a>
                  <PageTitle name="Edit Page" />
                  {this.props.id}
                </h3>*/}
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-12">
              <div className="card card-bordered">
                <div className="card-inner">
                  <ul class="nav nav-tabs">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        href="#adminDashboardSettings"
                      >
                        <em class="icon ni ni-setting"></em>
                        <span>Admin Dashboard Settings</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        onClick={() => this.getClientDashboardSettings()}
                        href="#clientDashboardSettings"
                      >
                        <em class="icon ni ni-setting"></em>
                        <span>Clients Dashboard Settings</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        onClick={() => this.getAgentDashboardSettings()}
                        href="#agentDashboardSettings"
                      >
                        <em class="icon ni ni-setting"></em>
                        <span>Agents Dashboard Settings</span>
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane active" id="adminDashboardSettings">
                      <div className="col-md-8">
                        {this.state.adminLoader === true ? (
                          FormLoader()
                        ) : (
                          <form
                            method="post"
                            className="pageFormEdit"
                            onSubmit={this.formSave}
                          >
                            <div className="row g-4">
                              <div className="col-lg-12">
                                <div class="nk-block-head nk-block-head-lg">
                                  <div class="nk-block-between-md g-4">
                                    <div class="nk-block-head-content">
                                      <h4 class="nk-block-title fw-normal">
                                        Admin Dashboard Settings
                                      </h4>
                                      <div class="nk-block-des">
                                        <p>
                                          Pick one of your dashboards to serve
                                          as homepage. Admin without dashboard
                                          access will be shown the default
                                          dashboard.{" "}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="nk-block-head-content">
                                      {/*<ul class="nk-block-tools gx-3">
                                <li class="order-md-last">
                                  <a
                                    href="html/subscription/pricing.html"
                                    class="btn btn-white btn-dim btn-outline-primary"
                                  >
                                    <span>View Pricing</span>
                                  </a>
                                </li>
                              </ul>*/}
                                    </div>
                                  </div>
                                </div>
                                {this.state.errorMessageAdmin !== "" ? (
                                  <div className="example-alert mb-2">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessageAdmin}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessageAdmin !== "" ? (
                                  <div className="example-alert mb-2">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessageAdmin}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div class="nk-block">
                                  <div class="card card-bordered sp-plan">
                                    <div class="row no-gutters">
                                      <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="sp-plan-info card-inner">
                                          <div class="row gx-0 gy-3">
                                            <div class="col-xl-12 col-sm-12">
                                              <div class="sp-plan-opt">
                                                <div class="custom-control custom-switch">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-control-input"
                                                    id="admin_dashboard_check"
                                                    checked={
                                                      this.state
                                                        .admin_dashboard_check
                                                    }
                                                    onChange={
                                                      this.handleChangeCheckboxKyc
                                                    }
                                                    name="admin_dashboard_check"
                                                    
                                                    
                                                    value="Yes"
                                                  />
                                                  <label
                                                    class="custom-control-label text-dark"
                                                    for="admin_dashboard_check"
                                                  >
                                                    Enabled
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="sp-plan-desc card-inner">
                                          <div className="form-group mt-4">
                                            <div className="form-label-group">
                                              <label className="form-label">
                                                Choose Report{" "}<sup>*</sup>
                                                <span className="text-danger"></span>
                                              </label>
                                            </div>
                                            <div className="form-control-group">
                                              <Select
                                                options={
                                                  this.state.all_admin_reports
                                                }
                                                name="selected_admin_report"
                                                placeholder="Select a report to show on dashboard"
                                                value={
                                                  this.state
                                                    .selected_admin_report
                                                }
                                                autoComplete="off"
                                                emptyMessage="Reports not available"
                                                onChange={
                                                  this.handleChangeSearch
                                                }
                                                isClearable
                                                isSearchable
                                                components={animatedComponents}
                                                styles={{
                                                  control: (
                                                    baseStyles,
                                                    state
                                                  ) => ({
                                                    ...baseStyles,
                                                    lineHeight: "40px",
                                                  }),
                                                }}
                                                isDisabled={
                                                  this.state
                                                    .admin_dashboard_check ===
                                                  true
                                                    ? false
                                                    : true
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div className="form-group text-right">
                                  <button
                                    type="submit"
                                    className="btn btn-lg btn-primary"
                                    disabled={this.state.disabled}
                                  >
                                    Save Changes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                    <div class="tab-pane" id="clientDashboardSettings">
                      <div className="col-md-12">
                        {this.state.clientLoader === true ? (
                          FormLoader()
                        ) : (
                          <>
                            <div class="nk-block-head nk-block-head-lg">
                              <div class="nk-block-between-md g-4">
                                <div class="nk-block-head-content">
                                  <h4 class="nk-block-title fw-normal">
                                    Client Dashboard Settings
                                  </h4>
                                  <div class="nk-block-des">
                                    <p>
                                      Pick one of the clients dashboards to
                                      serve as homepage. Client without
                                      dashboard access will be shown the default
                                      dashboard.{" "}
                                    </p>
                                  </div>
                                </div>
                                <div class="nk-block-head-content"></div>
                              </div>
                            </div>
                            <DataTable
                              columns={this.state.columnServices}
                              tableData={this.state.tableData}
                              title=""
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div class="tab-pane" id="agentDashboardSettings">
                    <div className="col-md-12">
                        {this.state.clientLoader === true ? (
                          FormLoader()
                        ) : (
                          <>
                            <div class="nk-block-head nk-block-head-lg">
                              <div class="nk-block-between-md g-4">
                                <div class="nk-block-head-content">
                                  <h4 class="nk-block-title fw-normal">
                                    Agents Dashboard Settings
                                  </h4>
                                  <div class="nk-block-des">
                                    <p>
                                      Pick one of the agent's dashboards to
                                      serve as homepage. Agent without
                                      dashboard access will be shown the default
                                      dashboard.{" "}
                                    </p>
                                  </div>
                                </div>
                                <div class="nk-block-head-content"></div>
                              </div>
                            </div>
                            <DataTable
                              columns={this.state.columnServicesAgents}
                              tableData={this.state.tableDataAgents}
                              title=""
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/****  UPDATE CLIENT DASHBOARD  ****/}
            <div class="modal fade" tabindex="-1" id="modalClientDashboard">
              <div
                class="modal-dialog modal-dialog-top modal-xl"
                role="document"
              >
                <div class="modal-content">
                  <a
                    href="#"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em class="icon ni ni-cross"></em>
                  </a>
                  <div class="modal-header">
                    <h5 class="modal-title">
                      <em class="icon ni ni-file-docs"></em> Update dashboard of{" "}
                      <sup class="text-primary">{this.state.client_title}</sup>
                    </h5>
                  </div>
                  <div class="modal-body ">
                    <div className="row g-gs">
                      <div className="col-md-12 invoice_details_popup">
                        <form
                          method="post"
                          className="pageFormEdit2"
                          onSubmit={this.formSaveClients}
                        >
                          <div className="row g-4">
                            <div className="col-lg-12">
                              <div class="nk-block-head nk-block-head-lg">
                                <div class="nk-block-between-md g-4">
                                  <div class="nk-block-head-content">
                                    <h4 class="nk-block-title fw-normal">
                                      Client Dashboard Settings
                                    </h4>
                                    <div class="nk-block-des">
                                      <p>
                                        Pick one of the clients dashboards to
                                        serve as homepage. Client without
                                        dashboard access will be shown the
                                        default dashboard.{" "}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="nk-block-head-content"></div>
                                </div>
                              </div>
                              {this.state.errorMessageClient !== "" ? (
                                <div className="example-alert mb-2">
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.errorMessageClient}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.successMessageClient !== "" ? (
                                <div className="example-alert mb-2">
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.successMessageClient}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div class="nk-block">
                                <div class="card card-bordered sp-plan">
                                  <div class="row no-gutters">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                      <div class="sp-plan-desc card-inner pt-4">
                                        <div className="row">
                                          <div class="col-xl-12 col-sm-12 mb-4">
                                            <div class="sp-plan-opt">
                                              <div class="custom-control custom-switch">
                                                <input
                                                  type="checkbox"
                                                  class="custom-control-input"
                                                  id="client_dashboard_check"
                                                  checked={
                                                    this.state
                                                      .client_dashboard_check
                                                  }
                                                  onChange={
                                                    this
                                                      .handleChangeCheckboxKyc
                                                  }
                                                  name="client_dashboard_check"
                                                    
                                                    
                                                    value="Yes"
                                                />
                                                <label
                                                  class="custom-control-label text-dark"
                                                  for="client_dashboard_check"
                                                >
                                                  Enabled
                                                </label>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-md-12 col-sm-12">
                                            <div className="form-group">
                                              <div className="form-label-group">
                                                <label className="form-label">
                                                  Choose Report{" "}<sup>*</sup>
                                                  <span className="text-danger"></span>
                                                </label>
                                              </div>
                                              <div className="form-control-group">
                                                <Select
                                                  options={
                                                    this.state
                                                      .all_client_reports
                                                  }
                                                  name="selected_client_report"
                                                  placeholder="Select a report to show on dashboard"
                                                  value={
                                                    this.state
                                                      .selected_client_report
                                                  }
                                                  autoComplete="off"
                                                  emptyMessage="Reports not available"
                                                  onChange={
                                                    this
                                                      .handleChangeSearchClientDash
                                                  }
                                                  isClearable
                                                  isSearchable
                                                  components={
                                                    animatedComponents
                                                  }
                                                  styles={{
                                                    control: (
                                                      baseStyles,
                                                      state
                                                    ) => ({
                                                      ...baseStyles,
                                                      lineHeight: "40px",
                                                    }),
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-12 mt-4">
                                            <div className="form-group text-right">
                                              <button
                                                type="submit"
                                                className="btn btn-lg btn-primary"
                                                disabled={this.state.disabled}
                                              >
                                                Save Changes
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/****** UPDATE CLIENT DASHBOARD *****/}
            {/****  UPDATE AGENT DASHBOARD  ****/}
            <div class="modal fade" tabindex="-1" id="modalAgentDashboard">
              <div
                class="modal-dialog modal-dialog-top modal-xl"
                role="document"
              >
                <div class="modal-content">
                  <a
                    href="#"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em class="icon ni ni-cross"></em>
                  </a>
                  <div class="modal-header">
                    <h5 class="modal-title">
                      <em class="icon ni ni-file-docs"></em> Update dashboard of{" "}
                      <sup class="text-primary">{this.state.client_title}</sup>
                    </h5>
                  </div>
                  <div class="modal-body ">
                    <div className="row g-gs">
                      <div className="col-md-12 invoice_details_popup">
                        <form
                          method="post"
                          className="pageFormEdit2"
                          onSubmit={this.formSaveAgents}
                        >
                          <div className="row g-4">
                            <div className="col-lg-12">
                              <div class="nk-block-head nk-block-head-lg">
                                <div class="nk-block-between-md g-4">
                                  <div class="nk-block-head-content">
                                    <h4 class="nk-block-title fw-normal">
                                      Agent Dashboard Settings
                                    </h4>
                                    <div class="nk-block-des">
                                      <p>
                                        Pick one of the agents dashboards to
                                        serve as homepage. Agent without
                                        dashboard access will be shown the
                                        default dashboard.{" "}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="nk-block-head-content"></div>
                                </div>
                              </div>
                              {this.state.errorMessageAgents !== "" ? (
                                <div className="example-alert mb-2">
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.errorMessageAgents}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.successMessageAgents !== "" ? (
                                <div className="example-alert mb-2">
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.successMessageAgents}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div class="nk-block">
                                <div class="card card-bordered sp-plan">
                                  <div class="row no-gutters">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                      <div class="sp-plan-desc card-inner pt-4">
                                        <div className="row">
                                          <div class="col-xl-12 col-sm-12 mb-4">
                                            <div class="sp-plan-opt">
                                              <div class="custom-control custom-switch">
                                                <input
                                                  type="checkbox"
                                                  class="custom-control-input"
                                                  id="agent_dashboard_check"
                                                  checked={
                                                    this.state
                                                      .agent_dashboard_check
                                                  }
                                                  onChange={
                                                    this
                                                      .handleChangeCheckboxKyc
                                                  }
                                                  name="agent_dashboard_check"
                                                    
                                                    
                                                    value="Yes"
                                                />
                                                <label
                                                  class="custom-control-label text-dark"
                                                  for="agent_dashboard_check"
                                                >
                                                  Enabled
                                                </label>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-md-12 col-sm-12">
                                            <div className="form-group">
                                              <div className="form-label-group">
                                                <label className="form-label">
                                                  Choose Report{" "}<sup>*</sup>
                                                  <span className="text-danger"></span>
                                                </label>
                                              </div>
                                              <div className="form-control-group">
                                                <Select
                                                  options={
                                                    this.state
                                                      .all_agent_reports
                                                  }
                                                  name="selected_agent_report"
                                                  placeholder="Select a report to show on dashboard"
                                                  value={
                                                    this.state
                                                      .selected_agent_report
                                                  }
                                                  autoComplete="off"
                                                  emptyMessage="Reports not available"
                                                  onChange={
                                                    this
                                                      .handleChangeSearchAgentDash
                                                  }
                                                  isClearable
                                                  isSearchable
                                                  components={
                                                    animatedComponents
                                                  }
                                                  styles={{
                                                    control: (
                                                      baseStyles,
                                                      state
                                                    ) => ({
                                                      ...baseStyles,
                                                      lineHeight: "40px",
                                                    }),
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-12 mt-4">
                                            <div className="form-group text-right">
                                              <button
                                                type="submit"
                                                className="btn btn-lg btn-primary"
                                                disabled={this.state.disabled}
                                              >
                                                Save Changes
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/****** UPDATE AGENT DASHBOARD *****/}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MetaBaseSettings);
