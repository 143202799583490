import { React } from "react";
import { Component } from "react";

import { COMPANY_NAME } from "../../../config/config";
import axios from "axios";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";

import {
  getSubpoena,
  deleteSubpoenaReport,
} from "./../../../config/subpoena_api_calls";

import {
  dateString,
  APP_URL,
  AGENT_SERVER_URL,
  APP_LIVE_URL,
} from "./../../../config/config";

import $ from "jquery";

import DataTable from "../../../components/Tables/DataTableReports";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Link } from "react-router-dom";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import Tooltip from "@material-ui/core/Tooltip";

import dateFormat, { masks } from "dateformat";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import TextField from "../../../components/InputFields/TextField";
import MutextField from "@material-ui/core/TextField";
import * as XLSX from "xlsx";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  columnsServices,
} from "./../../../config/config";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import { extendMoment } from "moment-range";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const extendedMoment = extendMoment(moment);

const animatedComponents = makeAnimated();

const auth = new HelperClass();

class SubpoenaReports extends Component {
  constructor() {
    var date = (date = new Date()),
      y = date.getFullYear(),
      m = date.getMonth();

    var datestring = new Date().toISOString().slice(0, 10);

    super();
    this.decimalPoint = Number(auth.getDecimalPoint());
    this.billing_decimalPoint = Number(auth.getBillingDecimalPoint());
    const today = extendedMoment();
    const monthAgo = moment().subtract(1, "month");
    this.state = {
      tableData: [],
      tableLoader: true,
      //start_date: monthAgo.format("YYYY-MM-DD"),
      //end_date: today.format("YYYY-MM-DD"),
      start_date: "",
      end_date: "",
      selected_order_accountno: null,
      selected_client_accountno: null,
      selected_agent_accountno: null,
      value: moment.range(monthAgo, today),
      commission_title_array: "get_commission_reports",
      commissionReportsArray: [],
      all_active_agents: [],
      all_active_clients: [],
      all_complete_orders: [],
      deposit_main_section: false,
      depositErrorMessage: "",
      depositSuccessMessage: "",
      emailto: "",
      emailcc: "",
      emailbcc: "",
      emailsubject: "",
      emailcontent: "",
      email_button: false,
      emailErrorMessage: "",
      emailErrorMessageTitle: "",
      emailSuccessMessage: "",
      orderno: "",
      form_title: "",
      formDetails: [],
      formGroups: [],
      is_submitted: "",
      payment_method_title: "",
      selected_payment_method: "",
      paymentDetails: [],
      requestDetails: [],
      request_main_section: true,
      requestErrorMessage: "",
      requestErrorMessageTitle: "",
      requestSuccessMessage: "",
      commission_amount: 0,
      payment_amount: (0).toFixed(this.billing_decimalPoint),
      payment_receipt: "",
      payment_notes: "",
      payment_date: new Date(),
      payment_method_pay: "Quickbooks",
      add_picture: "",
      add_picture_name: "",
      fundErrorMessage: "",
      fundSuccessMessage: "",
      add_funds_button: false,
      agent_pending_commision: 0,
      agent_paid_commision: 0,

      successMessage: "",
      errorMessage: "",
      document_file: "",
      csvData: [],

      selectedRows: [],
      bulkAction: "",

      report_id: "",

      subpoena_enabled: "",

      columnsArray: [
        { name: "title", label: "Title" },
        { name: "subpoena_ren_no", label: "Ref#" },
        { name: "client_name", label: "User Name" },
        {
          name: "created_at",
          label: "Created Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "published_at",
          label: "Published Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "status",
          label: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setStatus(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "report_status",
          label: "Published Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setStatusPublished(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "url",
          label: "Report",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setReportLink(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "id",
          label: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setEditLink(value)}</div>;
            },
          },
        },
      ],
    };
  }

  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    const reportsData = await getSubpoena(
      auth.getAccount(),
      auth.getToken(),
      auth.getUserType(),
      auth.getSubUserAccount(),
      auth.getSubpoenaType()
    );
    console.log("getSubpoena: ", reportsData.data);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      reportsData.data.status === 404 &&
      reportsData.data.message === "not_found"
    ) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
      });
    } else if (
      reportsData.data.status === 404 &&
      reportsData.data.message === "not_available"
    ) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
        subpoena_enabled: "No",
      });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({
        commissionReportsArray: reportsData.data.data,
        tableLoader: false,
      });
    } else {
      console.log("Last: ", reportsData.data);
      //window.location.replace("/error");
    }
    $("#viewBtn").show();
    $("#btnloader").hide();
  }

  format_date = (value) => {
    let date = value;
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy");
    } else {
      return "---";
    }
  };

  setStatus = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === "processing") {
      return (
        <div>
          <span className="badge badge-dim badge-danger">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> In process
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-success">
            {" "}
            <em className="icon ni ni-done "></em> Processed
          </span>
        </div>
      );
    }
  };

  setStatusPublished = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === 0) {
      return (
        <div>
          <span className="badge badge-dim badge-danger">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> Not
            Published
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-success">
            {" "}
            <em className="icon ni ni-done "></em> Published
          </span>
        </div>
      );
    }
  };

  setReportLink = (value, tableMeta) => {
    let domain_str = value;
    let report_id = tableMeta.rowData[8];
    let is_published = tableMeta.rowData[6];
    //console.log("domain_str: ", domain_str);
    if (domain_str && domain_str !== "") {
      if (is_published === 1) {
        return (
          <Tooltip title="View report">
            <a
              href={`${APP_URL}viewSubpoenaReport/${report_id}`}
              title="View report"
              style={{
                cursor: "pointer",
                marginLeft: "5px",
              }}
              target="_blank"
            >
              <em
                class="icon ni ni-reports text-primary"
                style={{ fontSize: "22px" }}
              ></em>
            </a>
          </Tooltip>
        );
      } else {
        return "---";
      }
    } else {
      return "---";
    }
  };

  setEditLink = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str && domain_str !== "") {
      return (
        <>
          <Tooltip title="Edit report">
            <a
              onClick={() =>
                this.addMenuHistory("/editSubpoenaReport/" + domain_str)
              }
              style={{ cursor: "pointer" }}
            >
              <em
                class="icon ni ni-edit text-primary"
                style={{ fontSize: "22px", paddingRight: "5px" }}
              ></em>
            </a>
          </Tooltip>

          <Tooltip title="Delete report">
            <a
              onClick={() => this.deleteReportModal(domain_str)}
              style={{ cursor: "pointer" }}
            >
              <em
                class="icon ni ni-trash text-danger"
                style={{
                  fontSize: "22px",
                  paddingLeft: "5px",
                  borderLeft: "1px solid rgb(0 0 0 / 87%)",
                }}
              ></em>
            </a>
          </Tooltip>
        </>
      );
    } else {
      return "---";
    }
  };

  deleteReportModal = async (report_id) => {
    console.log("deleteReportModal report_id: ", report_id);
    this.setState({
      request_main_section: false,
      report_id: report_id,
      requestErrorMessage: "",
      requestErrorMessageTitle: "",
      requestSuccessMessage: "",
    });
    window.$("#modalRequests").modal("show");
  };

  deleteReport = async () => {
    const { report_id } = this.state;
    this.setState({
      diabled: true,
      report_id: report_id,
      requestErrorMessage: "",
      requestErrorMessageTitle: "",
      requestSuccessMessage: "",
    });
    console.log("report_id: ", report_id);
    const reportsData = await deleteSubpoenaReport(
      auth.getAccount(),
      auth.getToken(),
      report_id
    );
    console.log("deleteReport: ", reportsData.data);

    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      reportsData.data.status === 404 &&
      reportsData.data.message === "Report not found."
    ) {
      //window.location.replace("/error");
      this.setState({
        diabled: false,
        requestErrorMessage: "Report not found.",
        requestErrorMessageTitle: "Error",
        requestSuccessMessage: "",
      });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({
        requestErrorMessage: "",
        requestErrorMessageTitle: "",
        requestSuccessMessage:
          "Request deleted successfully. Please wait while we refresh the page.",
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      console.log("Last: ", reportsData.data);
      this.setState({
        diabled: false,
        requestErrorMessage: "There is some error while deleting the request.",
        requestErrorMessageTitle: "System Error",
        requestSuccessMessage: "",
      });
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  formatOrderno = (value) => {
    return (
      <a
        href={`${APP_URL}ViewSalesOrder/${value}`}
        className="text-primary"
        target="_blank"
      >
        {value}
      </a>
    );
  };

  formatCommission = (value) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    );
  };

  onSelect = (value) => {
    this.setState({
      value,
      start_date: value.start.format("YYYY-MM-DD"),
      end_date: value.end.format("YYYY-MM-DD"),
      isOpen: false,
    });
  };

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  regenerate_report = async () => {};

  open_agent_requests = async (value, tableMeta) => {};

  open_deposit_request = async (value, tableMeta) => {};

  modalHideMarketingPDF = (id) => {
    window.$("#" + id).modal("hide");
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    console.log("handleChange " + name + " " + value);
    this.setState({
      [name]: value,
    });
  };

  handleDateChange = async (date) => {
    await this.handleStatesDynamic(
      "start_date",
      date.toISOString().slice(0, 10)
    );
    console.log("start_date " + this.state.start_date);
  };

  handleDateChangeEnd = async (date) => {
    await this.handleStatesDynamic("end_date", date.toISOString().slice(0, 10));
    console.log("end_date " + this.state.end_date);
  };

  handleStatesDynamic = async (start_date, date) => {
    this.setState({
      [start_date]: date,
    });
  };

  handleStates = async (start_date, end_date) => {
    this.setState({
      start_date: start_date,
      end_date: end_date,
    });
  };

  capitalizeFirstLetters = (str) => {
    return str.toLowerCase().replace(/^\w|\s\w/g, function (letter) {
      return letter.toUpperCase();
    });
  };

  formatAsCurrency = (amount) => {
    //console.log("amount: "+  typeof amount + " - " + amount);
    //console.log("amount: ", typeof amount);
    if (!amount) {
      amount = 0;
    }
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: this.billing_decimalPoint,
      maximumFractionDigits: this.billing_decimalPoint,
    });
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation selectedFiles PDF, DOC, DOCX, ZIP, TXT, PNG, JPEG, JPG
    const allowedFileTypes = [".png", ".jpg", ".jpeg", ".pdf"];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        uploadErrorMessage:
          "Invalid file type. Please select a valid file format.",
        uploadErrorTitle: "Invalid File",
        add_picture: "",
        add_picture_name: "",
      });
      return;
    }

    // File size validation (e.g., limit to 10MB)
    const maxSizeBytes = 10 * 1024 * 1024; // 10MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        uploadErrorMessage:
          "File size exceeds the limit (10MB). Please choose a smaller file.",
        uploadErrorTitle: "Invalid File",
        add_picture: "",
        add_picture_name: "",
      });
      return;
    }

    // Clear any previous errors
    this.setState({
      add_picture: selectedFile,
      uploadErrorMessage: "",
      uploadErrorTitle: "",
      add_picture_name: selectedFile.name,
    });
    //return true;
  };

  handleModalopen = () => {
    window.$("#modalInsertSalesOrder").modal("show");
  };

  handleFileUploadClick = () => {
    document.getElementById("fileInput").click();
  };

  parseExcelData = (data) => {
    try {
      const workbook = XLSX.read(data, { type: "binary" });
      return workbook;
    } catch (error) {
      console.error("Error parsing Excel data:", error);
      return null;
    }
  };

  parseSheetData = (sheet) => {
    const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    const headers = data[0]; // Assuming first row contains headers
    const rows = data.slice(1); // Exclude headers
    return rows.map((row) =>
      headers.reduce((acc, header, index) => {
        acc[header] = row[index];
        return acc;
      }, {})
    );
  };

  downloadPdfRfq = async (d_f_name) => {
    var url = d_f_name;
    //let file_name = this.extractFileNameFromUrl(d_f_name);
    url = APP_LIVE_URL + "files_data/kyc_signup/" + d_f_name;
    //console.log("downloadPdfRfq: ", url);
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = d_f_name;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "There is some error while downloading the attachment.",
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  handleRowSelection = (rows) => {
    // Update state with the selected rows
    this.setState({ selectedRows: rows });
    console.log("Selected rows:", rows);
  };

  handleBulkActionChange = (event) => {
    const action = event.target.value;
    this.setState({ bulkAction: action });

    if (action === "approve") {
      this.handleApprove();
    } else if (action === "delete") {
      this.handleDelete();
    }
  };

  handleApprove = () => {
    const { selectedRows } = this.state;
    console.log("Approve rows:", selectedRows);
    // Add your approve logic here
  };

  handleDelete = () => {
    const { selectedRows } = this.state;
    console.log("Delete rows:", selectedRows);
    // Add your delete logic here
  };

  render() {
    //  let tableTitle = this.props.props.match.params.api_url.replaceAll("_", " ");
    const Theme = {
      palette: {
        primary: {
          contrastText: "#FFFFFF",
          dark: "#000000",
          main: "#000000",
          light: "#000000",
        },
      },
    };
    const { isOpen, value, start_date, end_date } = this.state;
    const formattedDateRange = start_date ? `${start_date} - ${end_date}` : "";
    const options = {
      selectableRows: "multiple", // Enable checkboxes for row selection
    };
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Subpoena Requests"
                        icon="icon ni ni-users"
                      />
                      
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContentAgent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          {this.state.subpoena_enabled !== "No" ? (
                            <li className="nk-block-tools-opt">
                              <a
                                href="addSubpoenaReport"
                                className="btn btn-primary"
                              >
                                <em className="icon ni ni-file-docs"></em>
                                &nbsp;Add New Request
                              </a>
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-4">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}

                      {this.state.subpoena_enabled === "No" ? (
                        <div class="nk-block-content nk-error-ld text-center">
                          <h1 class="nk-error-head">403</h1>
                          <h3 class="nk-error-title">No Access</h3>
                          <p class="nk-error-text">
                            We sincerely apologize for the inconvenience.
                            Subpoena integration is not enabled for your
                            account. Please contact the administrator to enable
                            it.
                          </p>
                          <a href="/" class="btn btn-lg btn-primary mt-2">
                            Back To Home
                          </a>
                        </div>
                      ) : (
                        <>
                          {this.state.tableLoader === true ? (
                            tableLoader()
                          ) : (
                            <DataTable
                              columns={this.state.columnsArray}
                              tableData={this.state.commissionReportsArray}
                              title=""
                              //options={options}
                              //onRowSelection={this.handleRowSelection}
                            />
                          )}
                        </>
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/* Link Modal MODAL START */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalRequests"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em> Delete
                              Request
                            </strong>
                          </div>
                          <a
                            className="close"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.modalHideMarketingPDF("modalRequests");
                            }}
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          {this.state.request_main_section === true ? (
                            FormLoader()
                          ) : (
                            <div class="row g-gs">
                              {this.state.requestErrorMessage !== "" ? (
                                <div class="col-sm-12">
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>
                                          {this.state.requestErrorMessageTitle}
                                        </h4>

                                        <p>{this.state.requestErrorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.requestSuccessMessage !== "" ? (
                                <div class="col-sm-12">
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>

                                        <p>
                                          {this.state.requestSuccessMessage}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div class="col-sm-12">
                                <div className="card card-bordered mt-1">
                                  <div class="card-inner-group">
                                    <div class="card-inner">
                                      <div
                                        class="sp-plan-desc sp-plan-desc-mb"
                                        id="paymentMethodDetails"
                                      >
                                        <div id="no-more-tables-new">
                                          Are you sure you want to permanently
                                          delete this request?
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12">
                                <div class="d-flex justify-content-end">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      this.modalHideMarketingPDF(
                                        "modalRequests"
                                      );
                                    }}
                                    class="btn btn-light mr-2"
                                  >
                                    Cancel
                                  </a>
                                  <button
                                    type="button"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.deleteReport()}
                                    class="btn btn-primary"
                                    disabled={this.state.diabled}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* LINK MODAL End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubpoenaReports);
