import { React, Component } from "react";

import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";
import { COMPANY_NAME, LOGO, THEME_COLOR } from "../../../config/config";
import { ValidateEmail, axiosConfig, normalize } from "../../../config/utility";

import { resendCodeFun } from "../../../config/api_calls";

import TextField from "./TextField";

export default class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      comp_name: "",
      title: "",
      email: "",
      password: "",
      errorEmail: "",
      errorPass: "",
      errorcPass: "",
      errorName: "",
      errorMessage: "",
      errorCompName: "",
      errorTitle: "",
      disabled: false,
      successMessage: "",
      confirm_password: "",
      errorConfirm: "",
      disableResendBtn: false,
      phone: "",
      errorPhone: "",
      fieldsHide: false,
    };
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  fieldValidation = () => {
    let { name, email, password, confirm_password, phone, comp_name, title } =
      this.state;

    if (comp_name === "") {
      this.setState({
        errorCompName: "error",
        errorMessage: "Company Name is required.",
        errorcPass: "",
      });
      return false;
    } else if (name === "") {
      this.setState({
        errorCompName: "",
        errorName: "error",
        errorMessage: "Full Name is required.",
        errorcPass: "",
      });
      return false;
    } else if (title === "") {
      this.setState({
        errorCompName: "",
        errorName: "",
        errorTitle: "error",
        errorMessage: "Title is required.",
        errorcPass: "",
      });
      return false;
    } else if (email === "") {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorTitle: "",
        errorMessage: "Email is required.",
        errorcPass: "",
      });
      return false;
    } else if (phone === "") {
      this.setState({
        errorPhone: "error",
        errorEmail: "",
        errorMessage: "Phone is required.",
        errorTitle: "",
        errorcPass: "",
      });
      return false;
    } else if (normalize(phone) === null) {
      this.setState({
        errorPhone: "error",
        errorEmail: "",
        errorMessage: "Invalid phone format.",
        errorcPass: "",
      });
      return false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
        errorcPass: "",
      });
      return false;
    } else if (password === "") {
      this.setState({
        errorPass: "error",
        errorEmail: "",
        errorcPass: "",
        errorPhone: "",
        errorMessage: "Password is required.",
      });
      return false;
    } else if (password.length < 8) {
      this.setState({
        errorPass: "error",
        errorEmail: "",
        errorcPass: "",
        errorPhone: "",
        errorMessage: "Use 8 characters or more for your password",
      });
      return false;
    } else if (confirm_password === "") {
      this.setState({
        errorPass: "",
        errorcPass: "error",
        errorEmail: "",
        errorPhone: "",
        errorMessage: "Confirm password is required.",
      });
      return false;
    } else if (confirm_password.length < 8) {
      this.setState({
        errorPass: "",
        errorcPass: "error",
        errorEmail: "",

        errorPhone: "",
        errorMessage: "Use 8 characters or more for your confirm password",
      });
      return false;
    } else if (password !== confirm_password) {
      this.setState({
        errorPass: "",
        errorcPass: "error",
        errorEmail: "",

        errorPhone: "",
        errorMessage: "Password and confirm password does not match.",
      });
      return false;
    } else {
      this.setState({
        errorcPass: "",
        errorPass: "",
        errorEmail: "",
        errorMessage: "",
        errorConfirm: "",
        errorPhone: "",
        disabled: true,
      });
    }
    return true;
  };

  formSubmit = (e) => {
    e.preventDefault();
    let { name, email, password, confirm_password, phone, comp_name, title } =
      this.state;

    if (this.fieldValidation() === true) {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "register",
            JSON.stringify({
              name: name,
              email: email,
              password: password,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              phone: phone,
              comp_name: comp_name,
              title: title,
            }),
            axiosConfig
          )
          .then((response) => {
            console.log("response.data : ", response.data);
            if (
              response.data.status === 404 ||
              response.data.status === 422 ||
              response.data.errors !== ""
            ) {
              this.setState({
                errorMessage: "Invalid Email OR Password",
                disabled: false,
              });
            }

            if (
              response.data.status === 200 &&
              response.data.message === "Account Already Exists"
            ) {
              this.setState({
                errorMessage: response.data.message,
                disabled: false,
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "Success"
            ) {
              $("#resendBtn").show();
              $("#signupBtn").hide();

              this.setState({
                successMessage: `Email Sent to your account. To complete
                    verification process please go to inbox.`,
                errorMessage: "",
                fieldsHide: true,
              });
            }
          });
      } catch (error) {
        console.log("catch : ", error);
      }
    }
  };

  resendEmail = async () => {
    let { email } = this.state;

    if (email === "") {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Email is required.",
      });
      return false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
      });
      return false;
    } else {
      this.setState({
        errorEmail: "",
        errorMessage: "",
        disableResendBtn: true,
      });

      let resendRes = await resendCodeFun(email);
      console.log("resendRes: ", resendRes.data);
      if (resendRes.data.status === 404 || resendRes.data.errors !== "") {
        this.setState({
          errorMessage: resendRes.data.errors,
          disableResendBtn: false,
          successMessage: "",
        });
      } else if (
        resendRes.data.status === 200 &&
        resendRes.data.message === "Success" &&
        resendRes.data.errors === ""
      ) {
        this.setState({
          successMessage: `Email Sent.Please check inbox/spam for account activation.`,
          errorMessage: "",
          disableResendBtn: false,
        });
      } else {
        window.location.replace("/error");
      }
    }
  };
  render() {
    console.log("fieldsHide: ", this.state.fieldsHide);
    return (
      <div className="nk-body npc-crypto ui-clean pg-auth">
        <div className="nk-app-root">
          <div className="nk-split nk-split-page nk-split-md">
            <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container w-lg-45">
              <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                <a
                  href="#"
                  className="toggle btn btn-white btn-icon btn-light"
                  data-target="athPromo"
                >
                  <em className="icon ni ni-info"></em>
                </a>
              </div>
              <div className="nk-block nk-block-middle nk-auth-body">
                <div className="brand-logo pb-5">
                  <a href="/" className="logo-link">
                    <img
                      className="logo-light logo-img logo-img-lg"
                      src={LOGO}
                      alt="logo"
                    />
                    <img
                      className="logo-dark logo-img logo-img-lg"
                      src={LOGO}
                      alt="logo-dark"
                    />
                  </a>
                </div>
                <div className="nk-block-head">
                  <div className="nk-block-head-content">
                    <h5 className="nk-block-title">Register</h5>
                    <div className="nk-block-des">
                      {/* Error Start */}
                      {this.state.errorMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {/* Error End */}

                      {/* Resend Email Button Start */}
                      {this.state.successMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {/* Resend Email Button End */}
                    </div>
                  </div>
                </div>
                <form
                  method="post"
                  className="registerForm"
                  onSubmit={this.formSubmit}
                >
                  {this.state.fieldsHide === false ? (
                    <div>
                      <TextField
                        label="Company Name"
                        error={this.state.errorCompName}
                        id="comp_name"
                        name="comp_name"
                        value={this.state.comp_name}
                        placeholder="Company name"
                        change={this.handleChange}
                      />
                      <TextField
                        label="Full Name"
                        error={this.state.errorName}
                        id="name"
                        name="name"
                        value={this.state.name}
                        placeholder="Full Name"
                        change={this.handleChange}
                      />
                      <TextField
                        label="Title"
                        error={this.state.errorTitle}
                        id="title"
                        name="title"
                        value={this.state.title}
                        placeholder="Title"
                        change={this.handleChange}
                      />

                      <TextField
                        label="Email"
                        type="text"
                        error={this.state.errorEmail}
                        id="email"
                        name="email"
                        value={this.state.email}
                        placeholder="Enter your email address"
                        change={this.handleChange}
                      />

                      <TextField
                        label="Phone"
                        type="number"
                        error={this.state.errorPhone}
                        id="phone"
                        name="phone"
                        value={this.state.phone}
                        placeholder="Enter your phone"
                        change={this.handleChange}
                      />
                      <div className="form-group">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                        <div className="form-control-wrap">
                          <a
                            tabIndex="-1"
                            href="#"
                            className="form-icon form-icon-right passcode-switch"
                            data-target="password"
                          >
                            <em className="passcode-icon icon-show icon ni ni-eye"></em>
                            <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                          </a>
                          <input
                            type="password"
                            className={
                              "form-control form-control-lg " +
                              this.state.errorPass
                            }
                            id="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            placeholder="Enter your password"
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="confirm_password"
                        >
                          Confirm Password
                        </label>
                        <div className="form-control-wrap">
                          <a
                            tabIndex="-1"
                            href="#"
                            className="form-icon form-icon-right passcode-switch"
                            data-target="confirm_password"
                          >
                            <em className="passcode-icon icon-show icon ni ni-eye"></em>
                            <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                          </a>
                          <input
                            type="password"
                            className={
                              "form-control form-control-lg " +
                              this.state.errorcPass
                            }
                            id="confirm_password"
                            name="confirm_password"
                            value={this.state.confirm_password}
                            onChange={this.handleChange}
                            placeholder="Confirm your password"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <br />

                  <div className="form-group">
                    <button
                      className="btn btn btn-dim btn-primary"
                      onClick={() => this.resendEmail()}
                      disabled={this.state.disableResendBtn}
                      id="resendBtn"
                      style={{ display: "none" }}
                    >
                      Resend Email
                    </button>{" "}
                    <button
                      id="signupBtn"
                      className="btn btn-lg btn-block"
                      type="submit"
                      disabled={this.state.disabled}
                      style={{ backgroundColor: THEME_COLOR, color: "white" }}
                    >
                      Register
                    </button>
                  </div>
                </form>
                <div className="form-note-s2 pt-4">
                  {" "}
                  Already have an account ?{" "}
                  <a href="/login">
                    <strong>Sign in instead</strong>
                  </a>
                </div>
              </div>
              <div className="nk-block nk-auth-footer">
                <div className="mt-3">
                  <p>
                  ERP/OBS License. All Rights Reserved {new Date().getFullYear()}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right"
              data-content="athPromo"
              data-toggle-screen="lg"
              data-toggle-overlay="true"
            >
              <div className="slider-wrap w-100 w-max-550px p-3 p-sm-5 m-auto">
                <div
                  className="slider-init"
                  data-slick='{"dots":true, "arrows":false}'
                >
                  <div className="slider-item">
                    <div className="nk-feature nk-feature-center">
                      <div className="nk-feature-img">
                        <img className="round" src={PROMO_A} alt="" />
                      </div>
                      <div className="nk-feature-content py-4 p-sm-5">
                        <h4>{COMPANY_NAME}</h4>
                        <p>
                          Enter details here to signup and start using our
                          exciting product.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="slider-item">
                    <div className="nk-feature nk-feature-center">
                      <div className="nk-feature-img">
                        <img className="round" src={PROMO_B} alt="" />
                      </div>
                      <div className="nk-feature-content py-4 p-sm-5">
                        <h4>{COMPANY_NAME}</h4>
                        <p>
                          You can start to create your products easily with its
                          user-friendly design & most completed responsive
                          layout.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="slider-item">
                    <div className="nk-feature nk-feature-center">
                      <div className="nk-feature-img">
                        <img className="round" src={PROMO_C} alt="" />
                      </div>
                      <div className="nk-feature-content py-4 p-sm-5">
                        <h4>{COMPANY_NAME}</h4>
                        <p>
                          You can start to create your products easily with its
                          user-friendly design & most completed responsive
                          layout.
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="slider-dots"></div>
                <div className="slider-arrows"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
