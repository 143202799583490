import { React, Fragment } from "react";
import { Component, createRef } from "react";
import { connect } from "react-redux";
import {
  AGENT_SERVER_URL,
  APP_URL,
  AGENT_PORTAL_URL,
} from "../../../config/config";
import { withRouter } from "react-router-dom";

import {
  listAgentAllowedServices,
  getAgentInfo,
  updateAgentAllowedServices,
  listAssignedServicesAgreementsCp,
  deleteClientAssignedAgreementCp,
  getClientArchivedAgreementsCp,
  getAgentArchivedAgreementsSingle,
  reassignAgentArchivedAgreement,
  deleteAgentArchivedAgreement,
  sendEmailNew,
  revokeAgentSignedAgreements,
  approveAgentSignedAgreement,
  getAgentLogin,
  getSingleAgent,
  editAgent,
  resendActivationAgent,
  getSingleAgentLoginDetail,
  getAgentAssignedForms,
  viewAgentFormSubmission,
  approveAgentSubmission,
  rejectAgentSubmission,
  allAgentRejections,
  listAllFormsAgent,
  assignFormToAgents,
  getAgentArchivedForms,
  getEditAgentEmailPreview,
} from "./../../../config/api_calls";
import {
  ListAgentAssignRoles,
  editAgentAssignRole,
} from "../../../config/agent_role_api_calls";
import { getAgentAgreementSigneeDetailsNew } from "../../../config/signed_agreements_api_calls";
import { addSettings, addMsaInfo } from "../../../actions";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import HelperClass from "./../../../config/helperClass";
import makeAnimated from "react-select/animated";
import countryList from "react-select-country-list";
import { PhoneNumberUtil } from "google-libphonenumber";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import dateFormat, { masks } from "dateformat";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import MutextField from "@material-ui/core/TextField";
import { ValidateEmail } from "../../../config/utility";
import { PhoneInput } from "react-international-phone";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import PasswordStrengthBar from "react-password-strength-bar";
import Select from "react-select";
import TextField from "../../../components/InputFields/TextField";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import Autocomplete from "@material-ui/lab/Autocomplete";

const animatedComponents = makeAnimated();
const auth = new HelperClass();
class ViewAgent extends Component {
  constructor() {
    super();
    this.state = {
      serviceAssignedData: [],
      showServiceLoader: true,
      assignedLoader: true,
      checkAll: false,
      update_service_button: false,
      agent_accountno: "",
      client_update_name: "",
      client_comp_name: "",
      client_update_email: "",
      client_name_initials: "",
      client_status: "",
      assignedErrorMessage: "",
      assignedSuccessMessage: "",
      loginErrorMessage: "",
      login_url: "",
      activeTab: "agreements",

      errorMessageAgreements: "",
      successMessageAgreements: "",
      errorMessageArchiveAgreements: "",
      successMessageArchiveAgreements: "",
      signedData: [],
      unsignedData: [],
      selected_agent_accountno: "",
      removeErrorTitle: "",
      removeErrorMessage: "",
      removeSuccessMessage: "",
      remove_agreement_button: false,
      selected_agreement_id: "",
      errorMessageArchivedDetails: "",
      reassign_archive_id: "",
      errorMessageReassign: "",
      successMessageReassign: "",
      uploadErrorTitle: "",
      uploadErrorMessage: "",
      uploadSuccessMessage: "",
      upload_agreement_button: false,
      add_picture: "",
      add_picture_name: "",
      signee_name: "",
      signee_email: "",
      signee_title: "",
      signee_comment: "",
      charLimit: 1000,
      characterExceededMessage: "",
      errorMessageSignee: "",
      successMessageSignee: "",
      eSuccessMessage: "",
      eErrorMessage: "",
      isRevokeReasonActive: false,
      revoke_reason: "",
      isApproveActive: false,
      approve_agreement_id: "",
      approve_agreement_accountno: "",

      signee_details: [],
      agent_email: "",
      agent_phone: "",
      agent_company: "",
      agent_status: "",
      agent_password: "",
      agent_address: "",
      agent_address2: "",
      agent_city: "",
      agent_state: "",
      agent_zip: "",
      agent_country: "",
      sharelink_limit: "",
      errorMessageEdit: "",
      successMessageEdit: "",
      errorName: "",
      errorAgentStatus: "",
      errorAgentCompany: "",
      errorAgentEmail: "",
      errorAgentPhone: "",
      errorAgentAddress: "",
      errorAgentCity: "",
      errorAgentState: "",
      errorAgentZip: "",
      errorAgentCountry: "",
      errorAgentPassword: "",
      errorCPassword: "",
      useGooglePlaces: true,
      toggleButtonText: "Allow PO Box Address",
      notifyCheckBox: false,
      activation_button: false,
      activation_tick: false,
      activation_cross: false,

      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      assignRoleData: [],
      assignSubRoleData: [],
      changedRolesRights: [],
      changedSubRolesRights: [],
      errorMessageSubAgentRole: "",
      successMessageSubAgentRole: "",
      subassignRoleData: [],
      subassignSubRoleData: [],
      subchangedRolesRights: [],
      subchangedSubRolesRights: [],
      checkedRoleIds: [],
      checkedSubRoleIds: [],
      checkedName: "",
      checkedEmail: "",
      statusChanged: false,
      formsErrorMessage: "",
      formsSuccessMessage: "",
      errorMessageTotals: "",
      errorMessageTotalsTitle: "",
      form_title: "",
      form_accountno: "",
      agent_submission_id: "",
      agent_submission_type: "",
      approveAgentErrorMessage: "",
      approveAgentSuccessMessage: "",
      form_token: "",
      approveAgentErrorMessage: "",
      approveAgentSuccessMessage: "",
      approve_agent_button: false,
      rejectAgentErrorMessage: "",
      rejectAgentSuccessMessage: "",
      reject_agent_reason: "",
      allAgentRejectionsListing: [],
      agentsList: [],
      selectedForms: [],
      selectAllAgents: false,
      assignModalLoader: true,
      errorMessageAssignModal: "",
      successMessageAssignModal: "",
      errorMessageArchiveForms: "",
      archivedForms: [],
      formsAssignedData: [],
    };
    this.countryOptions = countryList()
      .getData()
      .filter(
        (country) => country.label !== "United States Minor Outlying Islands"
      );
    this.phoneInputRef = createRef();
  }

  async componentDidMount() {
    let agent_accountno = this.props.props.match.params.agent_accountno;
    this.setState({
      agent_accountno: agent_accountno,
    });
    const getClientInfoResponce = await getAgentInfo(
      auth.getAccount(),
      auth.getToken(),
      agent_accountno
    );
    console.log(
      "getClientInfoResponce.data.data : ",
      getClientInfoResponce.data
    );
    if (
      getClientInfoResponce.data.data.status === 403 ||
      getClientInfoResponce.data.data.errors === "authentication missing" ||
      getClientInfoResponce.data.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      getClientInfoResponce.data.data.status === 404 ||
      getClientInfoResponce.data.data.note === "missing"
    ) {
      // window.location.replace("/error");
      console.log("settings missing");
      this.setState({
        tableLoader: false,
      });
    } else if (
      getClientInfoResponce.data.data.status === 200 &&
      getClientInfoResponce.data.data.message === "success"
    ) {
      const client_initials = getClientInfoResponce.data.data.name
        .split(" ")
        .map((word) => {
          const firstChar = word.charAt(0);
          return /^[a-zA-Z]+$/.test(firstChar) ? firstChar : "";
        })
        .join("");
      let kyc_percentage =
        (100 / getClientInfoResponce.data.data.steps_allowed) *
        getClientInfoResponce.data.data.steps_completed;

      const enableStatus =
        getClientInfoResponce.data.data.clientIntegrationPermission &&
        getClientInfoResponce.data.data.clientIntegrationPermission.length > 0
          ? getClientInfoResponce.data.data.clientIntegrationPermission[0]
              .enable
          : "No";
      const isEnableChecked = enableStatus === "Yes";

      const enableStatusMeta =
        getClientInfoResponce.data.data.clientIntegrationPermissionMeta &&
        getClientInfoResponce.data.data.clientIntegrationPermissionMeta.length >
          0
          ? getClientInfoResponce.data.data.clientIntegrationPermissionMeta[0]
              .enable
          : "No";
      const isEnableCheckedMeta = enableStatusMeta === "Yes";

      this.setState({
        client_comp_name: getClientInfoResponce.data.data.comp_name,
        client_update_name: getClientInfoResponce.data.data.name,
        client_title: getClientInfoResponce.data.data.title,
        client_update_email: getClientInfoResponce.data.data.email,
        kyc_email_address: getClientInfoResponce.data.data.email,
        client_phone: getClientInfoResponce.data.data.phone,
        client_address: getClientInfoResponce.data.data.address,
        client_address2: getClientInfoResponce.data.data.address2,
        client_country: getClientInfoResponce.data.data.country,
        client_city: getClientInfoResponce.data.data.city,
        client_state: getClientInfoResponce.data.data.state,
        client_zip: getClientInfoResponce.data.data.zip,
        client_id: getClientInfoResponce.data.data.id,
        client_sharelink_limit: getClientInfoResponce.data.data.sharelink_limit,
        client_status: getClientInfoResponce.data.data.isVerified,
        client_name_initials: client_initials,
        totalClientAgreements: getClientInfoResponce.data.data.totalAgreements,
        signedClientAgreements:
          getClientInfoResponce.data.data.signedAgreements,
        unsignedClientAgreements:
          getClientInfoResponce.data.data.unsignedAgreements,
        totalSalesOrders: getClientInfoResponce.data.data.totalOrders,
        paidSalesOrders: getClientInfoResponce.data.data.paidOrders,
        completedSalesOrders: getClientInfoResponce.data.data.completedOrders,
        kyc_percentage: kyc_percentage.toFixed(0),
        client_isVerified: getClientInfoResponce.data.data.isVerified,
        loginDetails: getClientInfoResponce.data.data.agentLoginDetails,
        signee_name: getClientInfoResponce.data.data.name,
        signee_title: getClientInfoResponce.data.data.title,
        signee_email: getClientInfoResponce.data.data.email,
      });
      //console.log("this.state.kyc_percentage: ", this.state.kyc_percentage);
    } else {
      // window.location.replace("/error");
    }
    this.showAssignedServices();
    this.agentAgreements();
  }

  showAssignedServices = async () => {
    const { agent_accountno } = this.state;
    const servicesResponce = await listAgentAllowedServices(
      auth.getAccount(),
      auth.getToken(),
      agent_accountno
    );
    console.log("showAssignedServices: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        showServiceLoader: false,
        assignedLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceAssignedData: servicesResponce.data.data,
        showServiceLoader: false,
        assignedLoader: false,
      });
    } else {
      this.setState({
        showServiceLoader: false,
        assignedLoader: false,
      });
    }
  };

  handleCheckboxChange = (id) => {
    const serviceAssignedData = this.state.serviceAssignedData.map((checkbox) =>
      checkbox.id === id
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    );
    this.setState({ serviceAssignedData });
  };

  handleCheckAllChange = () => {
    const { checkAll, serviceAssignedData } = this.state;
    const updatedCheckboxes = serviceAssignedData.map((checkbox) => ({
      ...checkbox,
      checked: !checkAll,
    }));
    this.setState({
      serviceAssignedData: updatedCheckboxes,
      checkAll: !checkAll,
    });
  };

  updateAssignedServices = async () => {
    const { checkAll, serviceAssignedData, agent_accountno } = this.state;
    this.setState({
      update_service_button: true,
    });
    console.log(
      "updateAssignedServices serviceAssignedData: ",
      serviceAssignedData
    );
    const servicesResponce = await updateAgentAllowedServices(
      auth.getAccount(),
      auth.getToken(),
      agent_accountno,
      JSON.stringify(serviceAssignedData)
    );
    console.log("updateAssignedServices: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        update_service_button: false,
        assignedErrorMessage:
          "There is some error while assigning the services to the channel partner.",
        assignedSuccessMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        assignedErrorMessage: "",
        assignedSuccessMessage:
          "Services / Products assigned successfully to the channel partner.",
        update_service_button: false,
      });
      setTimeout(() => {
        this.showAssignedServices();
      }, 3000);
    } else {
      this.setState({
        update_service_button: false,
        assignedErrorMessage:
          "There is some error while assigning the services to the channel partner.",
        assignedSuccessMessage: "",
      });
    }
    setTimeout(() => {
      this.setState({
        update_service_button: false,
        assignedErrorMessage: "",
        assignedSuccessMessage: "",
      });
    }, 4000);
  };

  editClientModal = async () => {
    const { client_accountno } = this.state;
    const getClientInfoResponce = await getAgentInfo(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    console.log(
      "getClientInfoResponce.data.data : ",
      getClientInfoResponce.data.data
    );
    if (
      getClientInfoResponce.data.data.status === 403 ||
      getClientInfoResponce.data.data.errors === "authentication missing" ||
      getClientInfoResponce.data.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      getClientInfoResponce.data.data.status === 404 ||
      getClientInfoResponce.data.data.note === "missing"
    ) {
      // window.location.replace("/error");
      console.log("settings missing");
      this.setState({
        tableLoader: false,
      });
    } else if (
      getClientInfoResponce.data.data.status === 200 &&
      getClientInfoResponce.data.data.message === "success"
    ) {
      window.$("#modalUpdateClient").modal("show");
      this.setState({
        update_client_account_no: client_accountno,
        client_comp_name: getClientInfoResponce.data.data.comp_name,
        client_update_name: getClientInfoResponce.data.data.name,
        client_title: getClientInfoResponce.data.data.title,
        client_update_email: getClientInfoResponce.data.data.email,
        client_phone: getClientInfoResponce.data.data.phone,
        client_address: getClientInfoResponce.data.data.address,
        client_address2: getClientInfoResponce.data.data.address2,
        client_country: getClientInfoResponce.data.data.country,
        client_city: getClientInfoResponce.data.data.city,
        client_state: getClientInfoResponce.data.data.state,
        client_zip: getClientInfoResponce.data.data.zip,
        client_account_status: getClientInfoResponce.data.data.account_status,
        client_isVerified: getClientInfoResponce.data.data.isVerified,
      });
    } else {
      // window.location.replace("/error");
    }
    // console.log('edit client account_no : ',tableMeta.rowData[0]);
  };

  handleAddressSelect = async (place) => {
    const { description } = place.value;
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          description
        )}&key=${process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY}`
      );
      const data = await response.json();

      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        const formattedAddress = data.results[0].formatted_address;
        let city = null;
        let state = null;
        let country = null;
        let zipCode = null;
        let streetAddress = "";
        let neighborhood = null;
        let locality = null;
        let address2 = "";
        addressComponents.forEach((component) => {
          if (component.types.includes("neighborhood")) {
            neighborhood = component.long_name;
          } else if (component.types.includes("locality")) {
            locality = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          } else if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          } else if (component.types.includes("subpremise")) {
            address2 = component.long_name;
          } else if (
            ![
              "neighborhood",
              "sublocality",
              "sublocality_level_1",
              "sublocality_level_2",
              "administrative_area_level_2",
              "administrative_area_level_3",
              "postal_code_suffix",
            ].includes(component.types[0])
          ) {
            if (streetAddress !== "") {
              streetAddress += " ";
            }
            streetAddress += component.long_name;
          }
        });
        // Check if neighborhood is part of the formatted address
        if (neighborhood && formattedAddress.includes(neighborhood)) {
          city = neighborhood;
        } else {
          city = locality;
        }
        this.setState({
          agent_city_city: city && city !== "" ? city : "",
          agent_state: state && state !== "" ? state : "",
          agent_zip: zipCode && zipCode !== "" ? zipCode : "",
          agent_country: country && country !== "" ? country : "",
          agent_address:
            streetAddress && streetAddress !== "" ? streetAddress : "",
          agent_address2: address2 && address2 !== "" ? address2 : "",
        });
      } else {
        console.error("Reverse geocoding request failed:", data.status);
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data:", error);
    }
  };

  phoneUtil = PhoneNumberUtil.getInstance();

  isPhoneValid = (phone) => {
    try {
      return this.phoneUtil.isValidNumber(
        this.phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  handleFocus = () => {
    // Move cursor to the end of the input value
    const input = this.phoneInputRef.current;
    if (input) {
      const value = input.value;
      input.setSelectionRange(value.length, value.length);
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  reloadAgentInfo = async () => {
    const getClientInfoResponce = await getAgentInfo(
      auth.getAccount(),
      auth.getToken(),
      this.state.agent_accountno
    );
    console.log(
      "getClientInfoResponce.data.data : ",
      getClientInfoResponce.data
    );
    if (
      getClientInfoResponce.data.data.status === 403 ||
      getClientInfoResponce.data.data.errors === "authentication missing" ||
      getClientInfoResponce.data.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      getClientInfoResponce.data.data.status === 404 ||
      getClientInfoResponce.data.data.note === "missing"
    ) {
      // window.location.replace("/error");
      console.log("settings missing");
      this.setState({
        tableLoader: false,
      });
    } else if (
      getClientInfoResponce.data.data.status === 200 &&
      getClientInfoResponce.data.data.message === "success"
    ) {
      const client_initials = getClientInfoResponce.data.data.name
        .split(" ")
        .map((word) => {
          const firstChar = word.charAt(0);
          return /^[a-zA-Z]+$/.test(firstChar) ? firstChar : "";
        })
        .join("");
      let kyc_percentage =
        (100 / getClientInfoResponce.data.data.steps_allowed) *
        getClientInfoResponce.data.data.steps_completed;

      const enableStatus =
        getClientInfoResponce.data.data.clientIntegrationPermission &&
        getClientInfoResponce.data.data.clientIntegrationPermission.length > 0
          ? getClientInfoResponce.data.data.clientIntegrationPermission[0]
              .enable
          : "No";
      const isEnableChecked = enableStatus === "Yes";

      const enableStatusMeta =
        getClientInfoResponce.data.data.clientIntegrationPermissionMeta &&
        getClientInfoResponce.data.data.clientIntegrationPermissionMeta.length >
          0
          ? getClientInfoResponce.data.data.clientIntegrationPermissionMeta[0]
              .enable
          : "No";
      const isEnableCheckedMeta = enableStatusMeta === "Yes";

      this.setState({
        client_comp_name: getClientInfoResponce.data.data.comp_name,
        client_update_name: getClientInfoResponce.data.data.name,
        client_title: getClientInfoResponce.data.data.title,
        client_update_email: getClientInfoResponce.data.data.email,
        kyc_email_address: getClientInfoResponce.data.data.email,
        client_phone: getClientInfoResponce.data.data.phone,
        client_address: getClientInfoResponce.data.data.address,
        client_address2: getClientInfoResponce.data.data.address2,
        client_country: getClientInfoResponce.data.data.country,
        client_city: getClientInfoResponce.data.data.city,
        client_state: getClientInfoResponce.data.data.state,
        client_zip: getClientInfoResponce.data.data.zip,
        client_id: getClientInfoResponce.data.data.id,
        client_sharelink_limit: getClientInfoResponce.data.data.sharelink_limit,
        client_status: getClientInfoResponce.data.data.isVerified,
        client_name_initials: client_initials,
        totalClientAgreements: getClientInfoResponce.data.data.totalAgreements,
        signedClientAgreements:
          getClientInfoResponce.data.data.signedAgreements,
        unsignedClientAgreements:
          getClientInfoResponce.data.data.unsignedAgreements,
        totalSalesOrders: getClientInfoResponce.data.data.totalOrders,
        paidSalesOrders: getClientInfoResponce.data.data.paidOrders,
        completedSalesOrders: getClientInfoResponce.data.data.completedOrders,
        kyc_percentage: kyc_percentage.toFixed(0),
        client_isVerified: getClientInfoResponce.data.data.isVerified,
        loginDetails: getClientInfoResponce.data.data.agentLoginDetails,
        signee_name: getClientInfoResponce.data.data.name,
        signee_title: getClientInfoResponce.data.data.title,
        signee_email: getClientInfoResponce.data.data.email,
      });
      //console.log("this.state.kyc_percentage: ", this.state.kyc_percentage);
    } else {
      // window.location.replace("/error");
    }
  };

  changeActiveTab = (tab) => {
    this.setState({
      activeTab: tab,
      assignedLoader: true,
    });
  };

  format_date = (value, tableMeta) => {
    let date = value;
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy");
    } else {
      return "---";
    }
  };

  agentAgreements = async (event) => {
    let company_id = this.state.agent_accountno;
    this.setState({
      isRevokeReasonActive: false,
      revoke_reason: "",
      errorMessage: "",
      successMessage: "",
      agent_accountno: company_id,
    });
    let clientAgreementsInfo = [];
    const servicesResponce = await listAssignedServicesAgreementsCp(
      auth.getAccount(),
      auth.getToken(),
      company_id
    );

    console.log("agentAgreements: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      if (
        servicesResponce.data.clientAgreementsInfo &&
        servicesResponce.data.clientAgreementsInfo.length > 0
      ) {
        clientAgreementsInfo = servicesResponce.data.clientAgreementsInfo;
      }
      const combinedAgreements = [
        ...clientAgreementsInfo,
        ...servicesResponce.data.unSignedAgreements,
      ];
      this.setState({
        signedData: combinedAgreements,
        unsignedData: servicesResponce.data.unSignedAgreements,
        errorMessage: "",
        successMessage: "",
        assignedLoader: false,
      });
    } else {
      this.setState({
        signedData: [],
        unsignedData: [],
        errorMessage: "",
        successMessage: "",
        assignedLoader: false,
      });
    }
  };

  downloadPdf = async (d_f_name, title) => {
    if (d_f_name) {
      var url =
        process.env.REACT_APP_AGENT_PORTAL_URL +
        "files_data/agreements/" +
        d_f_name;
      //console.log("File URL: ", url);
      var pdf_name = title.replace(/[^a-zA-Z ]/g, "");
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = pdf_name + ".pdf";
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage: "There is some error while downloading the PDF.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    }
  };

  removeContract = (agreement_accountno) => {
    const { agent_accountno } = this.state;
    let selected_agent_accountno = agent_accountno;
    let selected_agreement_id = agreement_accountno;
    this.setState({
      selected_agent_accountno: selected_agent_accountno,
      selected_agreement_id: selected_agreement_id,
      removeErrorTitle: "",
      removeErrorMessage: "",
      removeSuccessMessage: "",
      remove_agreement_button: false,
    });
    window.$("#modalRemoveContract").modal("show");
  };

  modalHideRemove = () => {
    window.$("#modalRemoveContract").modal("hide");
  };

  remove_agreement = async () => {
    const { selected_agreement_id, selected_agent_accountno } = this.state;
    this.setState({
      removeSuccessMessage: "",
      removeErrorMessage: "",
      disabled: true,
      remove_agreement_button: true,
    });
    const agreementResponce = await deleteClientAssignedAgreementCp(
      auth.getAccount(),
      auth.getToken(),
      selected_agent_accountno,
      selected_agreement_id
    );

    console.log("deleteAgreement: ", agreementResponce.data);
    if (
      agreementResponce.data.status === 403 ||
      agreementResponce.data.errors === "authentication missing" ||
      agreementResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      agreementResponce.data.status === 404 &&
      agreementResponce.data.message === "fail" &&
      agreementResponce.data.note !== ""
    ) {
      this.setState({
        removeErrorMessage: "There is some error while removing the agreement.",
        removeSuccessMessage: "",
        disabled: false,
        remove_agreement_button: false,
      });
    } else if (
      agreementResponce.data.status === 200 &&
      agreementResponce.data.message === "success"
    ) {
      this.setState({
        removeErrorMessage: "",
        removeSuccessMessage: "Agreement removed successfully.",
      });

      setTimeout(() => {
        window.$("#modalRemoveContract").modal("hide");
        this.agentAgreements();
        this.reloadAgentInfo();
      }, 3000);
    } else {
      this.setState({
        removeErrorMessage: "There is some error while processing the request.",
        removeSuccessMessage: "",
        disabled: false,
        remove_agreement_button: false,
      });
    }
  };

  openSigneeDetails = async (agreement_accountno) => {
    const { agent_accountno } = this.state;
    let selected_agent_accountno = agent_accountno;
    let selected_agreement_id = agreement_accountno;
    this.setState({
      selected_agent_accountno: selected_agent_accountno,
      selected_agreement_id: selected_agreement_id,
      signee_details: [],
    });
    const servicesResponce = await getAgentAgreementSigneeDetailsNew(
      auth.getAccount(),
      auth.getToken(),
      selected_agent_accountno,
      selected_agreement_id
    );
    console.log("openSigneeDetails.data: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        approveErrorMessage:
          "There is an error while displaying the signee details. Please try again.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "signee_not_found"
    ) {
      this.setState({
        approveErrorMessage: "Signee details not found.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "agent_not_found"
    ) {
      this.setState({
        approveErrorMessage: "Agent not found.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signee_details: servicesResponce.data.data,
      });
    } else {
      this.setState({
        approveErrorMessage:
          "There is an error while displaying the signee details. Please try again.",
        disabled: false,
      });
    }
    //
    window.$("#modalOpenSignee").modal("show");
  };

  archived_agreements = async (agent_accountno) => {
    console.log("archived_agreements agent_accountno: ", agent_accountno);

    const kycResponce = await getClientArchivedAgreementsCp(
      auth.getAccount(),
      auth.getToken(),
      agent_accountno
    );
    console.log("archived_agreements: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        errorMessageAgreements:
          "There is an error while getting the credit applications. Please try again later.",
        processing_request_archived: false,
        assignedLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        errorMessageAgreements: "",
        processing_request_archived: false,
        assignedLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        archivedAgreements: kycResponce.data.data,
        processing_request_archived: false,
        assignedLoader: false,
        columnServices: [
          { name: "agreement_title", label: "Agreement Name" },
          {
            name: "archived_date",
            label: "Archived date",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_date(value)}</div>;
              },
            },
          },
          {
            name: "signed_date",
            label: "Signed Date",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_date(value)}</div>;
              },
            },
          },
          {
            name: "approved",
            label: "Approved",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_approved(value)}</div>;
              },
            },
          },
          {
            name: "pdf_path",
            label: "Download",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_download(value, tableMeta)}</div>;
              },
            },
          },

          {
            name: "id",
            label: "Actions",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div>
                    <div
                      className="nk-tb-col nk-tb-col-tools"
                      style={{ padding: "0px" }}
                    >
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <div className="drodown">
                            <a
                              style={{ cursor: "pointer" }}
                              title="Other Actions"
                              className="dropdown-toggle btn btn-icon  btn-outline-light"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right customStyle_dropdown-menu-md_forms">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <Tooltip title="View Details" placement="top">
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.get_archived_details(
                                          value,
                                          tableMeta
                                        )
                                      }
                                    >
                                      <em class="icon ni ni-card-view"></em>
                                      <span>View Details</span>
                                    </a>
                                  </Tooltip>
                                </li>
                                <li>
                                  <Tooltip
                                    title="Reassign Agreement"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.reassing_archived_details(
                                          value,
                                          tableMeta
                                        )
                                      }
                                      disabled={
                                        this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                          ? true
                                          : false
                                      }
                                    >
                                      <em className="icon ni ni-repeat"></em>
                                      <span>Reassign</span>
                                    </a>
                                  </Tooltip>
                                </li>
                                <li>
                                  <Tooltip
                                    title="Delete Agreement"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.delete_archived_details(
                                          value,
                                          tableMeta
                                        )
                                      }
                                      disabled={
                                        this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                          ? true
                                          : false
                                      }
                                    >
                                      <em className="icon ni ni-edit"></em>
                                      <span>Delete</span>
                                    </a>
                                  </Tooltip>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          },
        ],
      });
    } else {
      this.setState({
        errorMessageAgreements: "",
        processing_request_archived: false,
        assignedLoader: false,
      });
    }
  };

  format_approved = (value) => {
    if (value === "Yes") {
      return (
        <Tooltip title="This agreement is approved." placement="top">
          <span className="badge badge-outline-success">
            <em class="icon ni ni-done"></em> Yes
          </span>
        </Tooltip>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
          <em className="icon ni ni-caution"></em> No
        </span>
      );
    }
  };

  format_download = (value, tableMeta) => {
    if (value !== "") {
      return (
        <a
          onClick={() => this.downloadPdf(value, tableMeta.rowData[0])}
          title="Download Agreement"
          style={{
            cursor: "pointer",
          }}
        >
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-download"></em>
          </span>
        </a>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
          <em className="icon ni ni-caution"></em> --
        </span>
      );
    }
  };

  get_archived_details = async (value, tableMeta) => {
    const { agent_accountno } = this.state;
    let archive_id = tableMeta.rowData[5];
    console.log("get_archived_details archive_id: ", archive_id);

    const kycResponce = await getAgentArchivedAgreementsSingle(
      auth.getAccount(),
      auth.getToken(),
      agent_accountno,
      archive_id
    );
    console.log("get_archived_details: ", kycResponce.data);
    window.$("#modalArchivedDetails").modal("show");
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        errorMessageArchivedDetails:
          "There is an error while getting the credit applications. Please try again later.",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        errorMessageArchivedDetails: "",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        archivedAgreementsDetails: kycResponce.data.data,
      });
    } else {
      this.setState({
        errorMessageArchivedDetails: "",
      });
    }
  };

  reassing_archived_details = async (value, tableMeta) => {
    const { client_accountno } = this.state;
    let archive_id = tableMeta.rowData[5];
    this.setState({
      reassign_archive_id: archive_id,
      successMessageDelete: "",
      errorMessageDelete: "",
      disabled: false,
    });
    window.$("#modalReassignAlert").modal("show");
  };

  modalHideReassign = () => {
    window.$("#modalReassignAlert").modal("hide");
  };

  reassign_archived_agreement = async () => {
    const { reassign_archive_id, agent_accountno } = this.state;
    this.setState({
      successMessageReassign: "",
      errorMessageReassign: "",
      disabled: true,
    });
    const agreementResponce = await reassignAgentArchivedAgreement(
      auth.getAccount(),
      auth.getToken(),
      agent_accountno,
      reassign_archive_id
    );

    console.log("reassignAgreement: ", agreementResponce.data);
    //$("#btnloader").show();
    if (
      agreementResponce.data.status === 403 ||
      agreementResponce.data.errors === "authentication missing" ||
      agreementResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      agreementResponce.data.status === 404 &&
      agreementResponce.data.message === "fail" &&
      agreementResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessageReassign:
          "There is some error while processing the request.",
        successMessageReassign: "",
        disabled: false,
      });
      //$("#btnloader").hide();
    } else if (
      agreementResponce.data.status === 200 &&
      agreementResponce.data.message === "Agreement successfully reassigned."
    ) {
      //await this.reLoadClients();
      this.setState({
        errorMessageReassign: "",
        successMessageReassign: "Agreement successfully reassigned.",
        disabled: false,
      });

      setTimeout(() => {
        window.$("#modalReassignAlert").modal("hide");
        this.archived_agreements(agent_accountno);
        this.reloadAgentInfo();
      }, 3000);
      //$("#btnloader").hide();
    } else {
      this.setState({
        errorMessageReassign:
          "There is some error while processing the request.",
        successMessageReassign: "",
        disabled: false,
      });
      //$("#btnloader").hide();
      //window.location.replace("/error");
    }
  };

  delete_archived_details = async (value, tableMeta) => {
    const { client_accountno } = this.state;
    let archive_id = tableMeta.rowData[5];
    this.setState({
      delete_archive_id: archive_id,
      successMessageDelete: "",
      errorMessageDelete: "",
      disabled: false,
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  delete_archived_agreement = async () => {
    const { delete_archive_id, agent_accountno } = this.state;
    this.setState({
      successMessageDelete: "",
      errorMessageDelete: "",
      disabled: true,
    });
    const agreementResponce = await deleteAgentArchivedAgreement(
      auth.getAccount(),
      auth.getToken(),
      agent_accountno,
      delete_archive_id
    );

    console.log("deleteAgreement: ", agreementResponce.data);
    if (
      agreementResponce.data.status === 403 ||
      agreementResponce.data.errors === "authentication missing" ||
      agreementResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      agreementResponce.data.status === 404 &&
      agreementResponce.data.message === "fail" &&
      agreementResponce.data.note !== ""
    ) {
      this.setState({
        errorMessageDelete: "There is some error while processing the request.",
        successMessageDelete: "",
        disabled: false,
      });
    } else if (
      agreementResponce.data.status === 200 &&
      agreementResponce.data.message === "success"
    ) {
      this.setState({
        errorMessageDelete: "",
        successMessageDelete: "Archived agreement deleted successfully.",
      });

      setTimeout(() => {
        window.$("#modalDeleteAlert").modal("hide");
        this.archived_agreements(agent_accountno);
      }, 3000);
    } else {
      this.setState({
        errorMessageDelete: "There is some error while processing the request.",
        successMessageDelete: "",
        disabled: false,
      });
    }
  };

  openUploadContract = async (agreement_accountno) => {
    const { agent_accountno } = this.state;
    let selected_agent_accountno = agent_accountno;
    let selected_agreement_id = agreement_accountno;
    this.setState({
      selected_agent_accountno: selected_agent_accountno,
      selected_agreement_id: selected_agreement_id,
      uploadErrorTitle: "",
      uploadErrorMessage: "",
      uploadSuccessMessage: "",
      upload_agreement_button: false,
      add_picture: "",
      add_picture_name: "",
    });
    window.$("#modalOpenUploadContract").modal("show");
  };

  submitClientContract = async () => {
    let {
      selected_agent_accountno,
      selected_agreement_id,
      add_picture,
      add_picture_name,
    } = this.state;
    this.setState({
      upload_agreement_button: true,
    });
    if (add_picture_name === "") {
      this.setState({
        uploadErrorMessage: "Agreement file is required.",
        uploadErrorTitle: "Missing Fields",
        upload_agreement_button: false,
      });
      setTimeout(() => {
        this.setState({
          uploadErrorTitle: "",
          uploadErrorMessage: "",
        });
      }, 4000);
      return false;
    } else {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("accountno", auth.getAccount());
      formData.append("file", add_picture);
      formData.append("fileName", add_picture_name);
      formData.append("agent_accountno", Number(selected_agent_accountno));
      formData.append("agreement_id", Number(selected_agreement_id));

      try {
        axios
          .post(
            AGENT_SERVER_URL +
              "signed_agreements_external_api_calls/upload_agent_agreement_new",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": true,
              },
            },
            {}
          )
          .then((response) => {
            console.log("submitSignatory response.data : ", response.data);
            if (
              response.data.status === 404 &&
              response.data.message === "no_record"
            ) {
              this.setState({
                uploadErrorMessage: "Record not found.",
                uploadErrorTitle: "System Error",
                upload_agreement_button: false,
                uploadSuccessMessage: "",
              });
            } else if (response.data.status === 403) {
              this.setState({
                uploadErrorTitle: "System Error",
                uploadErrorMessage: response.data.message,
                upload_agreement_button: false,
                uploadSuccessMessage: "",
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                uploadSuccessMessage: "Agreement added successfully.",
                uploadErrorMessage: "",
                uploadErrorTitle: "",
                upload_agreement_button: true,
              });
              //this.reloadClients();
              setTimeout(() => {
                this.agentAgreements();
                this.reloadAgentInfo();
                window.$("#modalOpenUploadContract").modal("hide");
              }, 4000);
            } else {
              this.setState({
                uploadErrorTitle: "System Error",
                uploadErrorMessage:
                  "There is some error while adding the agreement.",
                uploadSuccessMessage: "",
                upload_agreement_button: false,
              });
            }
          });
      } catch (error) {
        this.setState({
          uploadErrorTitle: "System Error",
          uploadErrorMessage: "There is some error while adding the agreement.",
          disabled: false,
          uploadSuccessMessage: "",
          upload_agreement_button: false,
        });

        console.log("catch : ", error);
      }
      setTimeout(() => {
        this.setState({
          uploadErrorMessage: "",
          uploadErrorTitle: "",
        });
      }, 4000);
    }
  };

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation selectedFiles PDF, DOC, DOCX, ZIP, TXT, PNG, JPEG, JPG
    const allowedFileTypes = [
      ".png",
      ".jpg",
      ".jpeg",
      ".doc",
      ".docx",
      ".pdf",
      ".zip",
      ".txt",
      ".rtf",
    ];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        uploadErrorMessage:
          "Invalid file type. Please select a valid file format.",
        uploadErrorTitle: "Invalid File",
        add_picture: "",
        add_picture_name: "",
      });
      e.target.value = null;
      return;
    }

    // File size validation (e.g., limit to 10MB)
    const maxSizeBytes = 10 * 1024 * 1024; // 10MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        uploadErrorMessage:
          "File size exceeds the limit (10MB). Please choose a smaller file.",
        uploadErrorTitle: "Invalid File",
        add_picture: "",
        add_picture_name: "",
      });
      e.target.value = null;
      return;
    }

    // Clear any previous errors
    this.setState({
      add_picture: selectedFile,
      uploadErrorMessage: "",
      uploadErrorTitle: "",
      add_picture_name: selectedFile.name,
    });
    e.target.value = null;
    //return true;
  };

  open_signee = async (agreement_accountno) => {
    let selected_agreement_id = agreement_accountno;
    this.setState({
      errorMessageSignee: "",
      successMessageSignee: "",
      characterExceededMessage: "",
      signee_comment: "",
      selected_agreement_id: selected_agreement_id,
    });
    window.$("#modalSendSignee").modal("show");
  };

  close_signee = async () => {
    window.$("#modalSendSignee").modal("hide");
  };

  handleChangeTextArea = (event) => {
    let { value } = event.target;
    const { charLimit } = this.state;

    // Update state only if the character limit is not exceeded

    if (value.length <= charLimit) {
      this.setState({ signee_comment: value, characterExceededMessage: "" });
    } else {
      //value = value.slice(0, charLimit);
      this.setState({
        characterExceededMessage: `Character limit exceeded. Maximum 500 characters are allowed. you have entered ${value.length} characters.`,
      });
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.value : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    //console.log("handleChange: ", name + " " + value);
  };

  send_email = async () => {
    let { signee_name, signee_email, signee_title, signee_comment } =
      this.state;
    this.setState({
      successMessageSignee: "",
      errorMessageSignee: "",
    });
    let is_validated = true;
    let errorMessage = "";
    if (!signee_name.trim()) {
      is_validated = false;
      errorMessage = "Signee name is required.";
    } else if (!signee_email.trim()) {
      is_validated = false;
      errorMessage = "Signee email is required.";
    } else if (ValidateEmail(signee_email) === false) {
      is_validated = false;
      errorMessage = "Invalid email format.";
    } else if (!signee_title.trim()) {
      is_validated = false;
      errorMessage = "Signee title is required.";
    } else if (!signee_comment.trim()) {
      is_validated = false;
      errorMessage = "Signee comment is required.";
    }

    if (!is_validated) {
      this.setState({
        errorMessageSignee: errorMessage,
      });
    }

    if (is_validated) {
      let apiResponce = await sendEmailNew(
        auth.getAccount(),
        auth.getToken(),
        this.state.agent_accountno,
        this.state.signee_name,
        this.state.signee_email,
        this.state.signee_title,
        this.state.signee_comment,
        this.state.selected_agreement_id
      );
      console.log("apiResponce.send_email: ", apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "success"
      ) {
        this.setState({
          successMessageSignee: "Email sent successfully.",
          errorMessageSignee: "",
          disabled: false,
          signee_comment: "",
        });
        setTimeout(() => {
          window.$("#modalSendSignee").modal("hide");
        }, 3000);
      } else {
        this.setState({
          errorMessageSignee: "Something went wrong,try again later.",
          disabled: false,
          successMessageSignee: "",
        });
      }
    } else {
      this.setState({
        successMessageSignee: "",
        errorMessageSignee: "Please enter the email address.",
      });
    }
    setTimeout(() => {
      this.setState({
        successMessageSignee: "",
        errorMessageSignee: "",
      });
    }, 3000);
  };

  handleRevokeValueChange = (event) => {
    const { target } = event;
    const value = target.value;

    this.setState({
      revoke_reason: value,
    });
  };

  revokeAgreement = async (agreement_id, agreement_accountno) => {
    this.setState({
      isRevokeReasonActive: true,
      revoke_reason: "",
      errorMessage: "",
      successMessage: "",
      revokeAgreementData: {
        agreement_id: agreement_id,
        agreement_accountno: agreement_accountno,
      },
    });
  };

  handleAgentAgreementRevokeSubmit = async (
    agreement_id,
    agreement_accountno,
    agent_id,
    revoke_reason
  ) => {
    let is_validated = true;
    if (revoke_reason === "") {
      is_validated = false;
      this.setState({
        errorCompName: "error",
        errorMessage: "Revoke Reason is required.",
      });
    }
    if (is_validated === true) {
      this.setState({
        successMessageAgreements: "",
        errorMessageAgreements: "",
        disabled: true,
      });

      const servicesResponce = await revokeAgentSignedAgreements(
        auth.getAccount(),
        auth.getToken(),
        agent_id,
        agreement_id,
        agreement_accountno,
        revoke_reason
      );
      console.log(
        "handleAgentAgreementRevokeSubmit.data: ",
        servicesResponce.data
      );
      this.setState({
        disabled: false,
      });
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      } else if (servicesResponce.data.status === 406) {
        this.setState({
          errorMessageAgreements:
            "There is an error while revoking an agreement. Please try again.",
        });
        //window.location.replace("/error");
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessageAgreements:
            "There is an error while revoking an agreement. Please try again.",
        });
        //window.location.replace("/error");
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          successMessageAgreements: "Agreement is revoked successfully",
          revoke_reason: "",
          isRevokeReasonActive: false,
        });
      } else {
        this.setState({
          errorMessageAgreements:
            "There is an error while revoking an agreement. Please try again.",
        });
      }
    }
    setTimeout(() => {
      this.setState({
        successMessageAgreements: "",
        errorMessageAgreements: "",
      });
      this.agentAgreements();
      this.reloadAgentInfo();
    }, 4000);
  };

  approveAgreement = async (agent_id) => {
    const { approve_agreement_accountno, approve_agreement_id } = this.state;
    this.setState({
      successMessageAgreements: "",
      errorMessageAgreements: "",
      disabled: true,
    });

    const servicesResponce = await approveAgentSignedAgreement(
      auth.getAccount(),
      auth.getToken(),
      agent_id,
      approve_agreement_id,
      approve_agreement_accountno
    );
    console.log("approveAgreement.data: ", servicesResponce.data);
    this.setState({
      disabled: false,
    });
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessageAgreements:
          "There is an error while approving an agreement. Please try again.",
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessageAgreements:
          "There is an error while approving an agreement. Please try again.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.agentAgreements();
      this.reloadAgentInfo();
      this.setState({
        successMessageAgreements: "Agreement approved successfully",
        approve_agreement_id: "",
        approve_agreement_accountno: "",
        isApproveActive: false,
      });
    } else {
      this.setState({
        errorMessageAgreements:
          "There is an error while approving an agreement. Please try again.",
      });
    }

    setTimeout(() => {
      this.setState({
        successMessageAgreements: "",
        errorMessageAgreements: "",
      });
    }, 4000);
  };

  agentLogin = async () => {
    this.setState({
      loginErrorMessage: "",
      login_url: "",
    });
    window.$("#modalDefaultLogin").modal("show");
    const servicesResponce = await getAgentLogin(
      auth.getAccount(),
      auth.getToken(),
      this.state.client_id
    );
    console.log("agentLogin.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "inactive"
    ) {
      this.setState({
        loginErrorMessage: "Account is not active.",
      });
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "notVerified"
    ) {
      this.setState({
        loginErrorMessage:
          "Account is not verifed. Please check your email and click verify link.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        login_url: servicesResponce.data.login_url,
      });

      window.open(servicesResponce.data.login_url, "_blank");
      window.$("#modalDefaultLogin").modal("hide");
    } else {
      this.setState({
        loginErrorMessage:
          "There is some error while authenticating the account.",
      });
    }
  };

  editAgentForm = async (value, tableMeta) => {
    const servicesResponce = await getSingleAgent(
      auth.getAccount(),
      auth.getToken(),
      this.state.client_id
    );
    console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let agent_status = "0";
      if (
        servicesResponce.data.data[0][6] &&
        servicesResponce.data.data[0][6] === "active"
      ) {
        agent_status = "1";
      }
      this.setState({
        title: servicesResponce.data.data[0][0],
        name: servicesResponce.data.data[0][1],
        agent_email: servicesResponce.data.data[0][2],
        agent_phone: servicesResponce.data.data[0][3],
        agent_id: servicesResponce.data.data[0][4],
        agent_company: servicesResponce.data.data[0][5],
        agent_status: agent_status,
        sharelink_limit: servicesResponce.data.data[0][7],
        agent_address: servicesResponce.data.data[0][8],
        agent_address2: servicesResponce.data.data[0][9],
        agent_city: servicesResponce.data.data[0][10],
        agent_state: servicesResponce.data.data[0][11],
        agent_zip: servicesResponce.data.data[0][12],
        agent_country: servicesResponce.data.data[0][13],
        agent_isVerified: servicesResponce.data.data[0][14],
        tableLoader: false,
      });
      window.$("#modalFormDetail").modal("show");
    } else {
      // window.location.replace("/error");
    }
  };

  formUpdateSubmit = async () => {
    let {
      title,
      name,
      agent_email,
      agent_password,
      c_password,
      agent_phone,
      agent_company,
      agent_status,
      sharelink_limit,
      agent_address,
      agent_address2,
      agent_city,
      agent_state,
      agent_zip,
      agent_country,
      statusChanged,
      agent_isVerified,
    } = this.state;

    this.setState({
      errorMessageEdit: "",
      errorName: "",
      errorStatus: "",
      errorAgentCompany: "",
      errorAgentEmail: "",
      errorAgentPhone: "",
      errorAgentAddress: "",
      errorAgentCity: "",
      errorAgentState: "",
      errorAgentZip: "",
      errorAgentCountry: "",
      errorAgentPassword: "",
      errorCPassword: "",
      errorPass: "",
      errorAgentSharelink: "",
    });
    let is_validated = true;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    if (this.state.name === "") {
      this.setState({
        errorName: "error",
        errorMessageEdit: "Channel partner Name is required.",
      });
      is_validated = false;
    } else if (this.state.agent_company === "") {
      this.setState({
        errorAgentCompany: "error",
        errorMessageEdit: "Company name is required.",
      });
      is_validated = false;
    } else if (this.state.agent_phone === "") {
      this.setState({
        errorAgentPhone: "error",
        errorMessageEdit: "Phone is required.",
      });
      is_validated = false;
    } else if (!this.isPhoneValid(this.state.agent_phone)) {
      this.setState({
        errorAgentPhone: "error",
        errorMessageEdit: "Invalid phone format.",
      });
      is_validated = false;
    } else if (ValidateEmail(this.state.agent_email) === false) {
      this.setState({
        errorAgentEmail: "error",
        errorMessageEdit: "Invalid email format.",
      });
      is_validated = false;
    } else if (this.state.agent_password !== "") {
      if (this.state.agent_password.length < 8) {
        this.setState({
          errorAgentPassword: "error",
          errorMessageEdit: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (!passwordRegex.test(agent_password)) {
        this.setState({
          errorAgentPassword: "error",
          errorMessageEdit:
            "Password must be at least 8 characters, with uppercase, lowercase, number & special character.",
        });
        is_validated = false;
      } else if (this.state.c_password === "") {
        this.setState({
          errorCPassword: "error",
          errorMessageEdit: "Confirm password is required.",
        });
        is_validated = false;
      } else if (this.state.c_password.length < 8) {
        this.setState({
          errorCPassword: "error",
          errorMessageEdit:
            "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (
        this.state.agent_password.trim() != this.state.c_password.trim()
      ) {
        this.setState({
          errorCPassword: "error",
          errorMessageEdit: "Password and confirm password does not match.",
        });
        is_validated = false;
      }
    } else if (this.state.agent_address === "") {
      this.setState({
        errorAgentAddress: "error",
        errorMessageEdit: "Address is required.",
      });
      is_validated = false;
    } else if (this.state.agent_city === "") {
      this.setState({
        errorAgentCity: "error",
        errorMessageEdit: "City is required.",
      });
      is_validated = false;
    } else if (this.state.agent_state === "") {
      this.setState({
        errorAgentState: "error",
        errorMessageEdit: "State is required.",
      });
      is_validated = false;
    } else if (this.state.agent_zip === "") {
      this.setState({
        errorAgentZip: "error",
        errorMessageEdit: "Zip/postal code is required.",
      });
      is_validated = false;
    } else if (this.state.agent_country === "") {
      this.setState({
        errorAgentCountry: "error",
        errorMessageEdit: "Country is required.",
      });
      is_validated = false;
    } else if (this.state.sharelink_limit === "") {
      this.setState({
        errorAgentSharelink: "error",
        errorMessageEdit: "Share link limit is required.",
      });
      is_validated = false;
    }
    if (is_validated) {
      let apiResponce = await editAgent(
        auth.getAccount(),
        auth.getToken(),
        title,
        name,
        agent_email,
        agent_password,
        agent_phone,
        this.state.client_id,
        agent_company,
        agent_status,
        sharelink_limit,
        agent_address,
        agent_address2,
        agent_city,
        agent_state,
        agent_zip,
        agent_country,
        this.state.notifyCheckBox.toString(),
        statusChanged,
        agent_isVerified
      );
      //console.log("Edit Agent RES: ", apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 409 &&
        apiResponce.data.message === "Address validation failed"
      ) {
        const formattedErrorMessage = apiResponce.data.errors
          ? String(apiResponce.data.errors).replace(/,/g, "<br />")
          : "Address validation failed";
        this.setState({
          errorMessageEdit: formattedErrorMessage,
          tableLoader: false,
        });
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "Success"
      ) {
        this.setState({
          successMessageEdit: "Channel partner updated successfully.",
          errorMessageEdit: "",
          tableLoader: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorMessageEdit: "Something went wrong,try again later.",
          successMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else {
        this.setState({
          errorMessageEdit: "Something went wrong,try again later.",
          successMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        //auth.logout();
      }
    }
    setTimeout(() => {
      this.setState({
        errorMessageEdit: "",
        successMessageEdit: "",
        disabled: false,
        tableLoader: false,
        notifyCheckBox: false,
      });
    }, 3000);
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      useGooglePlaces: !prevState.useGooglePlaces,
      toggleButtonText:
        prevState.toggleButtonText === "Allow PO Box Address"
          ? "Allow Simple Address"
          : "Allow PO Box Address",
    }));
  };

  handleChangeCountry = (event) => {
    const country = event.label;
    this.setState({
      agent_country: country,
    });
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  togglePassword = () => {
    this.setState({
      passwordShown: !this.state.passwordShown,
    });
  };

  toggleConfirmPassword = () => {
    this.setState({
      confirmPasswordShown: !this.state.confirmPasswordShown,
    });
  };

  resendActivationAgent = async () => {
    let { agent_accountno } = this.state;
    this.setState({
      activation_button: true,
      activation_tick: false,
      activation_cross: false,
    });
    window.$("#modalAlertResend").modal("show");
    const kycResponce = await resendActivationAgent(
      auth.getAccount(),
      auth.getToken(),
      agent_accountno
    );
    //console.log("resendActivationAgent: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        activation_button: false,
        activation_tick: true,
        activation_cross: false,
      });
    } else {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
    }
    setTimeout(() => {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: false,
      });
      window.$("#modalAlertResend").modal("hide");
    }, 4000);
  };

  agentAssignRole = async () => {
    const agentId = this.state.client_id;
    const agentEmail = this.state.client_update_email;
    const agentComp = this.state.client_comp_name;
    const agentName = this.state.client_update_name;
    this.setState({
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      assignRoleData: [],
      assignSubRoleData: [],
      changedRolesRights: [],
      changedSubRolesRights: [],
      agentRoleLoader: true,
      disableRoleBtn: false,
      disableSubRoleBtn: false,
      rolesAgentName: agentName + " (" + agentComp + ")",
      checkedName: agentName,
      checkedEmail: agentEmail,
    });
    window.$("#modalAgentAssignRole").modal("show");
    this.getAgentRoleData(agentId, agentEmail);
  };

  getAgentRoleData = async (agentId, agentEmail) => {
    const listAgentAssignRoles = await ListAgentAssignRoles(
      auth.getAccount(),
      auth.getToken(),
      agentId,
      agentEmail
    );

    console.log("ListAgentRoles Res: ", listAgentAssignRoles.data.data);
    if (
      listAgentAssignRoles.data.status === 403 ||
      listAgentAssignRoles.data.errors === "authentication missing" ||
      listAgentAssignRoles.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listAgentAssignRoles.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listAgentAssignRoles.data.status === 200 &&
      listAgentAssignRoles.data.message === "success"
    ) {
      const assignRoleData = listAgentAssignRoles.data.data.roles;
      const assignSubRoleData = listAgentAssignRoles.data.data.subroles;
      const allRoleData = listAgentAssignRoles.data.data.Allroles.map(
        (role) => {
          const isActiveRole = assignRoleData.some(
            (assignedRole) =>
              assignedRole.alt === role.alt && assignedRole.isactive === 1
          );
          return {
            ...role,
            isChecked: isActiveRole,
          };
        }
      );

      const allSubRoleData = listAgentAssignRoles.data.data.Allsubroles.map(
        (subRole) => {
          const isActiveSubRole = assignSubRoleData.some(
            (assignedSubRole) =>
              assignedSubRole.alt === subRole.alt &&
              assignedSubRole.isactive === 1
          );
          return {
            ...subRole,
            isChecked: isActiveSubRole,
          };
        }
      );

      this.setState({
        assignRoleData,
        assignSubRoleData,
        allRoleData,
        allSubRoleData,
        agentRoleLoader: false,
      });

      const initialActiveRoles = assignRoleData
        .filter((role) => role.isactive === 1)
        .map((role) => ({
          alt: role.alt,
          isChecked: true,
        }));

      const initialActiveSubRoles = assignSubRoleData
        .filter((subRole) => subRole.isactive === 1)
        .map((subRole) => ({
          id: subRole.id,
          isChecked: true,
        }));

      this.setState({
        changedRolesRights: initialActiveRoles,
        changedSubRolesRights: initialActiveSubRoles,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  changedAllRolesRights = (event) => {
    const { name, checked } = event.target;

    // Update checkedRoleIds in the state
    const { checkedRoleIds } = this.state;
    const updatedCheckedRoleIds = checked
      ? [...checkedRoleIds, name] // add ID if checked
      : checkedRoleIds.filter((id) => id !== name); // remove ID if unchecked

    this.setState({ checkedRoleIds: updatedCheckedRoleIds });

    // Update allRoleData with isChecked property
    const { allRoleData, allSubRoleData } = this.state;
    const updatedAllRoleData = allRoleData.map((role) => {
      if (role.id.toString() === name) {
        return {
          ...role,
          isChecked: checked,
        };
      }
      return role;
    });

    // Update associated sub roles
    const updatedAllSubRoleData = allSubRoleData.map((subRole) => {
      if (subRole.role_id.toString() === name) {
        return {
          ...subRole,
          isChecked: checked,
        };
      }
      return subRole;
    });

    this.setState({
      allRoleData: updatedAllRoleData,
      allSubRoleData: updatedAllSubRoleData,
    });
  };

  changedAllSubRolesRights = (event) => {
    const { name, checked } = event.target;

    // Update checkedSubRoleIds in the state
    const { checkedSubRoleIds } = this.state;
    const updatedCheckedSubRoleIds = checked
      ? [...checkedSubRoleIds, name] // add ID if checked
      : checkedSubRoleIds.filter((id) => id !== name); // remove ID if unchecked

    this.setState({ checkedSubRoleIds: updatedCheckedSubRoleIds });

    // Update allSubRoleData with isChecked property
    const { allSubRoleData } = this.state;
    const updatedAllSubRoleData = allSubRoleData.map((subRole) => {
      if (subRole.tabdetailid.toString() === name) {
        return {
          ...subRole,
          isChecked: checked,
        };
      }
      return subRole;
    });

    this.setState({ allSubRoleData: updatedAllSubRoleData });
  };

  handleSubmitRoleCheckbox = async (name, email) => {
    this.setState({
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      disableRoleBtn: true,
    });

    const { allRoleData, allSubRoleData } = this.state;

    // Prepare the CheckRoleFields array with IDs and isactive status
    const CheckRoleFields = allRoleData.map((role) => ({
      id: role.id,
      isactive: role.isChecked ? 1 : 0,
    }));

    const CheckSubRoleFields = allSubRoleData.map((role) => ({
      id: role.tabdetailid,
      isactive: role.isChecked ? 1 : 0,
    }));

    try {
      // Call the backend API to save the roles
      const saveResponce = await editAgentAssignRole(
        auth.getAccount(),
        auth.getToken(),
        CheckRoleFields,
        CheckSubRoleFields,
        name,
        email
      );

      // Handle response status
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        this.setState({
          errorMessageAgentRole:
            "There is some error while updating the Channel Partner menu.",
          successMessageAgentRole: "",
          disableRoleBtn: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessageAgentRole: "",
          successMessageAgentRole: "Channel Partner menu updated successfully.",
          disableRoleBtn: false,
        });
        setTimeout(() => {
          window.$("#modalAgentAssignRole").modal("hide");
        }, 3000);
      } else {
        this.setState({
          errorMessageAgentRole: saveResponce.data.message,
          successMessageAgentRole: "",
          disableRoleBtn: false,
        });
      }
    } catch (error) {
      console.error("Error while saving roles:", error);
      this.setState({
        errorMessageAgentRole: "An error occurred while saving menu.",
        successMessageAgentRole: "",
        disableRoleBtn: false,
      });
    }
  };

  openLinkInNewTab = (link) => {
    window.open(link, "_blank");
  };

  loginDetailView = async (value, tableMeta) => {
    let agent_id = this.state.client_id;
    const servicesResponce = await getSingleAgentLoginDetail(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        loginDetails:
          servicesResponce.data.data && servicesResponce.data.data.length > 0
            ? [servicesResponce.data.data[0]]
            : [],
        tableLoader: false,
      });
      window.$("#modalLoginDetail").modal("show");
    } else {
      // window.location.replace("/error");
    }
  };

  agent_assigned_forms = async () => {
    const { agent_accountno } = this.state;
    console.log("archived_agreements agent_accountno: ", agent_accountno);
    const kycResponce = await getAgentAssignedForms(
      auth.getAccount(),
      auth.getToken(),
      agent_accountno
    );
    console.log("agent_assigned_forms: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        errorMessageAgreements:
          "There is an error while getting the assigned forms. Please try again later.",
        processing_request_archived: false,
        assignedLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        errorMessageAgreements: "",
        processing_request_archived: false,
        assignedLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        formsAssignedData: kycResponce.data.data,
        processing_request_archived: false,
        assignedLoader: false,
        columnForms: [
          {
            name: "form_title",
            label: "Form Title",
          },
          {
            name: "submitter_name",
            label: "Sent To",
          },
          {
            name: "is_submitted",
            label: "Submitted",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_submitted(value)}</div>;
              },
            },
          },
          {
            name: "submitted_on",
            label: "Submitted On",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_date(value)}</div>;
              },
            },
          },
          {
            name: "sent_by_email",
            label: "Sent By",
          },
          {
            name: "pdf_name",
            label: "Download",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_download_form(value, tableMeta)}</div>;
              },
            },
          },
          {
            name: "submission_status",
            label: "Status",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div>{this.format_submission_status(value, tableMeta)}</div>
                );
              },
            },
          },
          {
            name: "form_accountno",
            label: "Actions",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div>
                    <div
                      className="nk-tb-col nk-tb-col-tools"
                      style={{ padding: "0px" }}
                    >
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <div className="drodown">
                            <a
                              style={{ cursor: "pointer" }}
                              title="Other Actions"
                              className="dropdown-toggle btn btn-icon  btn-outline-light"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right customStyle_dropdown-menu-md_forms">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <Tooltip title="View Details" placement="top">
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.viewAgentSubmission(
                                          value,
                                          tableMeta
                                        )
                                      }
                                    >
                                      <em class="icon ni ni-card-view"></em>
                                      <span>View Details</span>
                                    </a>
                                  </Tooltip>
                                </li>
                                {tableMeta.rowData[2] === 1 ? (
                                  <li>
                                    <Tooltip
                                      title="Edit Submission"
                                      placement="top"
                                    >
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.redirectToEditResponse(
                                            "cp",
                                            tableMeta
                                          )
                                        }
                                      >
                                        <em class="icon ni ni-card-view"></em>
                                        <span>Edit Submission</span>
                                      </a>
                                    </Tooltip>
                                  </li>
                                ) : null}
                                {tableMeta.rowData[2] === 1 &&
                                tableMeta.rowData[6] === 0 ? (
                                  <>
                                    <li>
                                      <Tooltip
                                        title="Approve Submission"
                                        placement="top"
                                      >
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.openApproveClientAlert(
                                              value,
                                              "agents",
                                              tableMeta
                                            )
                                          }
                                        >
                                          <em class="icon ni ni-done text-success"></em>
                                          <span class="text-success">
                                            Approve
                                          </span>
                                        </a>
                                      </Tooltip>
                                    </li>
                                    <li>
                                      <Tooltip
                                        title="Reject Submission"
                                        placement="top"
                                      >
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.openRejectionAgentAlert(
                                              value,
                                              "agents",
                                              tableMeta
                                            )
                                          }
                                          class="text-danger"
                                        >
                                          <em class="icon ni ni-cross-circle text-danger"></em>
                                          <span class="text-danger">
                                            Reject
                                          </span>
                                        </a>
                                      </Tooltip>
                                    </li>
                                  </>
                                ) : tableMeta.rowData[2] === 1 &&
                                  tableMeta.rowData[6] === 2 ? (
                                  <li>
                                    <Tooltip
                                      title="Approve Submission"
                                      placement="top"
                                    >
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.openApproveClientAlert(
                                            value,
                                            "agents",
                                            tableMeta
                                          )
                                        }
                                      >
                                        <em class="icon ni ni-done text-success"></em>
                                        <span class="text-success">
                                          Approve
                                        </span>
                                      </a>
                                    </Tooltip>
                                  </li>
                                ) : tableMeta.rowData[2] === 1 &&
                                  tableMeta.rowData[6] === 1 ? (
                                  <li>
                                    <Tooltip
                                      title="Reject Submission"
                                      placement="top"
                                    >
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.openRejectionAgentAlert(
                                            value,
                                            "agents",
                                            tableMeta
                                          )
                                        }
                                        class="text-danger"
                                      >
                                        <em class="icon ni ni-cross-circle text-danger"></em>
                                        <span class="text-danger">Reject</span>
                                      </a>
                                    </Tooltip>
                                  </li>
                                ) : null}

                                <li>
                                  <Tooltip
                                    title="View all rejections"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.modalAllRejectionsAgent(
                                          value,
                                          "agents",
                                          tableMeta
                                        )
                                      }
                                      class="text-danger"
                                    >
                                      <em class="icon ni ni-cross-circle"></em>
                                      <span>View Rejections</span>
                                    </a>
                                  </Tooltip>
                                </li>

                                {tableMeta.rowData[8] &&
                                tableMeta.rowData[8] === 1 ? (
                                  <li>
                                    <Tooltip title="Link Only" placement="top">
                                      <a
                                        style={{ cursor: "pointer" }}
                                        href={tableMeta.rowData[9]}
                                        target="_blank"
                                        class="text-danger"
                                      >
                                        <em class="icon ni ni-link-alt text-warning"></em>
                                        <span class="text-warning">
                                          Link Only
                                        </span>
                                      </a>
                                    </Tooltip>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          },
          {
            name: "link_form",
            label: "Link Only",
            options: {
              display: false,
            },
          },
          {
            name: "external_link",
            label: "Link",
            options: {
              display: false,
            },
          },
          {
            name: "form_token",
            label: "Token",
            options: {
              display: false,
            },
          },
        ],
      });
    } else {
      this.setState({
        errorMessageAgreements: "",
        processing_request_archived: false,
        assignedLoader: false,
      });
    }
  };

  format_submitted = (value) => {
    if (value === 1) {
      return (
        <span className="badge badge-outline-success">
          <em class="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
          <em className="icon ni ni-caution"></em> No
        </span>
      );
    }
  };

  format_download_form = (value, tableMeta) => {
    //console.log("format_download value: ", value);
    let form_token = tableMeta.rowData[10];
    if (value && value !== "") {
      return (
        <>
          <a
            onClick={() => this.downloadPdfClient(value, this.state.form_title)}
            title="Download PDF"
            style={{
              cursor: "pointer",
            }}
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-download"></em>
            </span>
          </a>
          <a
            href={`${APP_URL}forms/view_agent_submission/${form_token}`}
            title="Submission View"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
            }}
            target="_blank"
          >
            <span className="badge badge-outline-primary">
              <em class="icon ni ni-table-view"></em>
            </span>
          </a>
        </>
      );
    } else {
      return <span className="badge badge-outline-light">---</span>;
    }
  };

  format_submission_status = (value, tableMeta) => {
    const is_submitted = tableMeta.rowData[2];
    if (is_submitted === 1) {
      if (value === 0) {
        return (
          <span className="badge badge-outline-primary">
            <em class="icon ni ni-done"></em> Pending Approval
          </span>
        );
      } else if (value === 1) {
        return (
          <span className="badge badge-outline-success">
            <em className="icon ni ni-caution"></em> Approved
          </span>
        );
      } else if (value === 2) {
        return (
          <span className="badge badge-outline-danger">
            <em className="icon ni ni-caution"></em> Rejected
          </span>
        );
      } else if (value === 3) {
        return (
          <span className="badge badge-outline-danger">
            <em class="icon ni ni-file"></em> Archived
          </span>
        );
      } else {
        return (
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-caution"></em> Pending Approval
          </span>
        );
      }
    } else {
      if (value === 2) {
        return (
          <span className="badge badge-outline-danger">
            <em className="icon ni ni-caution"></em> Rejected
          </span>
        );
      } else if (value === 3) {
        return (
          <span className="badge badge-outline-warning">
            <em class="icon ni ni-file"></em> Drafted
          </span>
        );
      } else {
        return "---";
      }
    }
  };

  downloadPdfClient = async (d_f_name) => {
    //var url = d_f_name;
    if (d_f_name && d_f_name !== "") {
      let file_name = d_f_name;
      var url =
        AGENT_PORTAL_URL + "files_data/form_response_uploads/" + d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage:
              "There is some error while downloading the attachment.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    } else {
      this.setState({
        errorMessage: "Attachement not found.",
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 4000);
    }
  };

  viewAgentSubmission = async (id, tableMeta) => {
    const { agent_accountno } = this.state;
    const form_token = tableMeta.rowData[10];
    this.setState({
      viewLoader: true,
      viewClientSubmissions: [],
      errorMessageTotals: "",
    });
    const servicesResponse = await viewAgentFormSubmission(
      auth.getAccount(),
      auth.getToken(),
      id,
      form_token,
      agent_accountno
    );

    window.$("#modalAgentSubmission").modal("show");
    console.log("viewAgentSubmission: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        errorMessageTotalsTitle: "No Submission",
        errorMessageTotals: "There are no submissions right now.",
        viewLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      let extra_files = [];
      let progressPercentage = 0;
      if (servicesResponse.data.data && servicesResponse.data.data.length > 0) {
        if (servicesResponse.data.data[0].extra_files) {
          extra_files = JSON.parse(servicesResponse.data.data[0].extra_files);
        }
        if (servicesResponse.data.data[0].progress) {
          let progress = servicesResponse.data.data[0].progress;
          let [completed, total] = progress.split("/").map(Number);
          progressPercentage = (completed / total) * 100;
        }
      }
      this.setState({
        viewClientSubmissions: servicesResponse.data.data,
        extra_files_clients: extra_files,
        viewLoader: false,
        progressPercentage: progressPercentage.toFixed(2),
      });

      //
    } else {
      {
        this.setState({
          errorMessageTotalsTitle: "No Submission",
          errorMessageTotals: "There are no submissions right now.",
          viewLoader: false,
        });
      }
    }
  };

  redirectToEditResponse = (user, tableMeta) => {
    const url = `${APP_URL}forms/edit_response/${user}/${this.state.agent_accountno}/${tableMeta.rowData[7]}/${tableMeta.rowData[10]}`;
    window.location.href = url;
  };

  openApproveClientAlert = (id, type, tableMeta) => {
    const form_token = tableMeta.rowData[10];
    this.setState({
      agent_submission_id: form_token,
      form_accountno: id,
      agent_submission_type: type,
      approveAgentErrorMessage: "",
      approveAgentSuccessMessage: "",
      approve_agent_button: false,
      form_token: form_token,
      form_title: tableMeta.rowData[0],
    });
    window.$("#modalApproveAgentAlert").modal("show");
  };

  approve_client_form = async () => {
    const {
      agent_submission_id,
      agent_submission_type,
      form_accountno,
      form_title,
      agent_accountno,
      form_token,
    } = this.state;
    this.setState({
      approveAgentErrorMessage: "",
      approveAgentSuccessMessage: "",
      approve_agent_button: true,
    });

    const servicesResponse = await approveAgentSubmission(
      auth.getAccount(),
      auth.getToken(),
      form_accountno,
      agent_submission_id,
      form_title,
      agent_submission_type,
      agent_accountno
    );

    console.log("approveAgentSubmission: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 400 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        approveAgentErrorMessage:
          "There is some error while approving the submission.",
        approveAgentSuccessMessage: "",
        approve_client_button: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState(
        {
          approveAgentErrorMessage: "",
          approveAgentSuccessMessage: "Submission approved successfully.",

          reject_general_reason: "",
        },
        () => {
          setTimeout(() => {
            if (agent_submission_type === "agents") {
              this.agent_assigned_forms();
            }

            window.$("#modalApproveAgentAlert").modal("hide");
            this.setState({
              approveAgentErrorMessage: "",
              approveAgentSuccessMessage: "",
            });
            //window.location.reload();
          }, 3000);
        }
      );
    } else {
      this.setState({
        approve_client_button: false,
        approveAgentErrorMessage:
          "There is some error while approving the submission.",
        approveAgentSuccessMessage: "",
      });
    }
  };

  modalHideAlll = async (id) => {
    window.$("#" + id).modal("hide");
  };

  openRejectionAgentAlert = (id, type, tableMeta) => {
    let rejection_agent_title = "Reject Submission";
    if (type === "agents") {
      rejection_agent_title = "Reject Channel Partner Submission";
    }
    const form_token = tableMeta.rowData[10];

    this.setState({
      agent_submission_id: form_token,
      form_accountno: id,
      rejectAgentErrorMessage: "",
      rejectAgentSuccessMessage: "",
      reject_agent_reason: "",
      reject_agent_button: false,
      rejection_agent_title: rejection_agent_title,
      agent_submission_type: type,
      form_token: form_token,
      form_title: tableMeta.rowData[0],
    });
    console.log("resendForm: ", id);
    window.$("#modalRejectionAgentAlert").modal("show");
  };

  reject_agent_form = async () => {
    const {
      agent_submission_id,
      reject_agent_reason,
      form_accountno,
      form_title,
      agent_submission_type,
      agent_accountno,
      form_token,
    } = this.state;
    this.setState({
      reject_agent_button: true,
      rejectAgentErrorMessage: "",
      rejectAgentSuccessMessage: "",
    });
    let is_validated = true;
    if (reject_agent_reason === "") {
      is_validated = false;
    }
    if (is_validated === true) {
      const servicesResponse = await rejectAgentSubmission(
        auth.getAccount(),
        auth.getToken(),
        form_accountno,
        agent_submission_id,
        reject_agent_reason,
        form_title,
        agent_submission_type,
        agent_accountno
      );

      console.log("reject_agent_form: ", servicesResponse.data);

      if (
        servicesResponse.data.status === 403 ||
        servicesResponse.data.errors === "authentication missing" ||
        servicesResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        servicesResponse.data.status === 400 &&
        servicesResponse.data.message === "form_not_found"
      ) {
        this.setState({
          rejectClientErrorMessage:
            "There is some error while rejecting the submission.",
          rejectClientSuccessMessage: "",
          reject_client_button: false,
        });
      } else if (
        servicesResponse.data.status === 200 &&
        servicesResponse.data.message === "success"
      ) {
        this.setState(
          {
            rejectAgentErrorMessage: "",
            rejectAgentSuccessMessage: "Submission rejected successfully.",
            reject_agent_button: false,
            reject_general_reason: "",
          },
          () => {
            setTimeout(() => {
              if (agent_submission_type === "agents") {
                this.agent_assigned_forms();
              }

              window.$("#modalRejectionAgentAlert").modal("hide");
              this.setState({
                rejectAgentErrorMessage: "",
                rejectAgentSuccessMessage: "Submission rejected successfully.",
              });
              //window.location.reload();
            }, 3000);
          }
        );
      } else {
        this.setState({
          reject_client_button: false,
          rejectClientErrorMessage:
            "There is some error while rejecting the submission.",
          rejectClientSuccessMessage: "",
        });
      }
    } else {
      this.setState({
        reject_client_button: false,
        rejectClientErrorMessage: "Please enter the reason.",
        rejectClientSuccessMessage: "",
      });
    }
  };

  modalAllRejectionsAgent = async (id, type, tableMeta) => {
    const { agent_accountno } = this.state;
    const form_token = tableMeta.rowData[10];
    const form_title = tableMeta.rowData[0];
    let rejection_agent_title = "All Rejections";
    if (type === "agents") {
      rejection_agent_title = "Channel Partner Rejections";
    }
    this.setState({
      agent_submission_id: id,
      rejectAgentErrorMessage: "",
      rejectAgentSuccessMessage: "",
      reject_agent_reason: "",
      reject_agent_button: false,
      rejection_agent_title: rejection_agent_title,
      agent_submission_type: type,
      form_title: form_title,
    });
    this.setState({
      rejectGeneralErrorMessage: "",
      rejectGeneralSuccessMessage: "",
      allAgentRejectionsListing: [],
      rejectionLoader: true,
    });
    window.$("#modalRejectionAgents").modal("show");
    const servicesResponse = await allAgentRejections(
      auth.getAccount(),
      auth.getToken(),
      id,
      form_token,
      agent_accountno,
      type
    );

    console.log("allAgentRejections: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        approveGeneralErrorMessage: "Submission not found.",
        approveGeneralSuccessMessage: "",
        rejectionLoader: false,
      });
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "rejection_not_found"
    ) {
      this.setState({
        approveGeneralErrorMessage:
          "There are no rejections against this submission.",
        approveGeneralSuccessMessage: "",
        rejectionLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState({
        approveGeneralErrorMessage: "",
        approveGeneralSuccessMessage: "",
        allAgentRejectionsListing: servicesResponse.data.data,
        rejectionLoader: false,
      });
    } else {
      this.setState({
        rejectionLoader: false,
        approveGeneralErrorMessage:
          "There is some error while getting the rejections.",
        approveGeneralSuccessMessage: "",
      });
    }
  };

  getAllForms = async () => {
    const { agent_accountno } = this.state;
    this.setState({ disableAssignBtn: false });
    const listAgentsResponse = await listAllFormsAgent(
      auth.getAccount(),
      auth.getToken(),
      agent_accountno
    );

    console.log("getAllForms: ", listAgentsResponse.data);

    if (
      listAgentsResponse.data.status === 403 ||
      listAgentsResponse.data.errors === "authentication missing" ||
      listAgentsResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listAgentsResponse.data.status === 404) {
      // Handle 404 error if needed
      this.setState({
        assignModalLoader: false,
      });
    } else if (
      listAgentsResponse.data.status === 200 &&
      listAgentsResponse.data.message === "success"
    ) {
      let agents = listAgentsResponse.data.data.map((agent) => ({
        value: agent.form_accountno,
        label: agent.form_title,
      }));

      const selectedForms = listAgentsResponse.data.selected_forms.map(
        (agent) => agent.form_accountno
      );

      const selectAllAgents =
        agents.length > 0 &&
        agents.every((agent) => selectedForms.includes(agent.value));

      this.setState({
        agentsList: agents,
        selectedForms: selectedForms,
        selectAllAgents: selectAllAgents,
        assignModalLoader: false,
      });
    } else {
      this.setState({
        assignModalLoader: false,
      });
      // Handle other responses or errors
    }
    window.$("#modalAssignFormAgent").modal("show");
  };

  handleSubmitAssignFormAgent = async () => {
    const { selectedForms, agent_accountno } = this.state;

    this.setState({ disableAssignBtn: true, formsAssignedData: [] });
    console.log("selectedForms: ", selectedForms);
    const assignFormResponse = await assignFormToAgents(
      auth.getAccount(),
      auth.getToken(),
      JSON.stringify(selectedForms),
      agent_accountno
    );

    console.log("assignFormResponse: ", assignFormResponse.data);

    if (
      assignFormResponse.data.status === 403 ||
      assignFormResponse.data.errors === "authentication missing" ||
      assignFormResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      assignFormResponse.data.status === 404 &&
      assignFormResponse.data.message === "assigning_error"
    ) {
      this.setState({
        errorMessageAssignModal:
          "There was some error while assigning the forms to the channel partner.",
        disableAssignBtn: false,
      });
    } else if (
      assignFormResponse.data.status === 200 &&
      assignFormResponse.data.message === "success"
    ) {
      this.setState({
        successMessageAssignModal:
          "Forms assigned to selected channel partner.",
      });
      this.agent_assigned_forms();
      setTimeout(() => {
        this.setState({
          successMessageAssignModal: "",
          errorMessageAssignModal: "",
        });
        window.$("#modalAssignFormAgent").modal("hide");
      }, 3000);
    } else {
      this.setState({
        errorMessageAssignModal:
          "There was some error while assigning the forms to the channel partner.",
        disableAssignBtn: false,
      });
    }
    setTimeout(() => {
      this.setState({
        successMessageAssignModal: "",
        errorMessageAssignModal: "",
      });
    }, 3500);
  };

  handleClientChange = (event, newSelectedOptions) => {
    const newselectedForms = newSelectedOptions.map((option) => option.value);

    const selectAllAgents =
      this.state.agentsList.length > 0 &&
      this.state.agentsList.length === newselectedForms.length;

    this.setState({
      selectedForms: newselectedForms,
      selectAllAgents: selectAllAgents,
    });
  };

  agent_archived_forms = async () => {
    const { agent_accountno } = this.state;
    console.log("archived_agreements agent_accountno: ", agent_accountno);
    const kycResponce = await getAgentArchivedForms(
      auth.getAccount(),
      auth.getToken(),
      agent_accountno
    );
    console.log("agent_archived_forms: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        errorMessageAgreements:
          "There is an error while getting the archived. Please try again later.",
        processing_request_archived: false,
        assignedLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        errorMessageAgreements: "",
        processing_request_archived: false,
        assignedLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        archivedForms: kycResponce.data.data,
        processing_request_archived: false,
        assignedLoader: false,
        columnArchivedForms: [
          {
            name: "form_title",
            label: "Form Title",
          },
          {
            name: "submitter_name",
            label: "Sent To",
          },
          {
            name: "is_submitted",
            label: "Submitted",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_submitted(value)}</div>;
              },
            },
          },
          {
            name: "submitted_on",
            label: "Submitted On",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_date(value)}</div>;
              },
            },
          },
          {
            name: "sent_by_email",
            label: "Sent By",
          },
          {
            name: "pdf_name",
            label: "Download",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div>
                    {this.format_download_form_archived(value, tableMeta)}
                  </div>
                );
              },
            },
          },
          {
            name: "submission_status",
            label: "Status",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div>{this.format_submission_status(value, tableMeta)}</div>
                );
              },
            },
          },
          {
            name: "form_accountno",
            label: "Actions",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div>
                    <div
                      className="nk-tb-col nk-tb-col-tools"
                      style={{ padding: "0px" }}
                    >
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <div className="drodown">
                            <a
                              style={{ cursor: "pointer" }}
                              title="Other Actions"
                              className="dropdown-toggle btn btn-icon  btn-outline-light"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right customStyle_dropdown-menu-md_forms">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <Tooltip title="View Details" placement="top">
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.viewClientArchivedSubmission(
                                          value,
                                          tableMeta
                                        )
                                      }
                                    >
                                      <em class="icon ni ni-card-view"></em>
                                      <span>View Details</span>
                                    </a>
                                  </Tooltip>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          },
          {
            name: "link_form",
            label: "Link Only",
            options: {
              display: false,
            },
          },
          {
            name: "external_link",
            label: "Link",
            options: {
              display: false,
            },
          },
          {
            name: "form_token",
            label: "Token",
            options: {
              display: false,
            },
          },
          {
            name: "archive_date",
            label: "Archive Date",
            options: {
              display: false,
            },
          },
        ],
      });
    } else {
      this.setState({
        errorMessageAgreements: "",
        processing_request_archived: false,
        assignedLoader: false,
      });
    }
  };

  format_download_form_archived = (value, tableMeta) => {
    //console.log("format_download value: ", value);
    let form_token = tableMeta.rowData[10];
    if (value && value !== "") {
      return (
        <>
          <a
            onClick={() => this.downloadPdfClient(value, this.state.form_title)}
            title="Download PDF"
            style={{
              cursor: "pointer",
            }}
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-download"></em>
            </span>
          </a>
          <a
            href={`${APP_URL}forms/view_agent_archived_submission/${form_token}`}
            title="Submission View"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
            }}
            target="_blank"
          >
            <span className="badge badge-outline-primary">
              <em class="icon ni ni-table-view"></em>
            </span>
          </a>
        </>
      );
    } else {
      return <span className="badge badge-outline-light">---</span>;
    }
  };

  viewClientArchivedSubmission = async (value, tableMeta) => {
    const archive_date = tableMeta.rowData[11];
    this.setState({
      form_title: tableMeta.rowData[0],
      archive_date: archive_date,
    });

    window.$("#modalClientArchivedSubmission").modal("show");
  };

  preViewDefault = async () => {
    const getEmailResponse = await getEditAgentEmailPreview(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("getEmailResponse: ", getEmailResponse.data);
    if (
      getEmailResponse.data.status === 403 ||
      getEmailResponse.data.errors === "authentication missing" ||
      getEmailResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (getEmailResponse.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      getEmailResponse.data.status === 200 &&
      getEmailResponse.data.message === "success"
    ) {
      let emailTemplate = getEmailResponse.data.data[0][3];
      let logoUrl = getEmailResponse.data.data[0]["reseller_logo"];

      if (emailTemplate && emailTemplate !== "") {
        // Replace company logo
        emailTemplate = emailTemplate.replace(/{{comp_logo}}/g, logoUrl);

        // Replace placeholders with user input values
        emailTemplate = emailTemplate.replace(
          /{{client_name}}/g,
          this.state.name || "{{client_name}}"
        );
        emailTemplate = emailTemplate.replace(
          /{{comp_name}}/g,
          auth.getCompName() || "{{comp_name}}"
        );
        emailTemplate = emailTemplate.replace(
          /{{client_email}}/g,
          this.state.agent_email || "{{client_email}}"
        );

        // Clean up any empty paragraphs
        emailTemplate = emailTemplate.replace(/<p>&nbsp;<\/p>/g, "");
      }

      this.setState({
        email_id: getEmailResponse.data.data[0][0],
        email_title: getEmailResponse.data.data[0][1],
        email_subject: getEmailResponse.data.data[0][2],
        email_template: emailTemplate,
        tableLoader: false,
        isEditorReady: true,
      });
      window.$("#modalPreviewDefaultModal").modal("show");
    } else {
      //window.location.replace("/error");
    }
  };

  closePreviewModal = () => {
    // Close the preview modal
    window.$("#modalPreviewDefaultModal").modal("hide");
  };

  render() {
    const { useGooglePlaces, toggleButtonText } = this.state;
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const fetchToken = async () => {
      try {
        const response = await fetch(
          AGENT_SERVER_URL + "agents/editor_token_url",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              request_type: "ckeditor-tokenUrl",
              user: auth.getAccount(),
            }),
          }
        );
        const data = await response.json();
        return data.token;
      } catch (error) {
        console.error("Failed to fetch token:", error);
        return null;
      }
    };
    return (
      <div className="nk-content " id="Clients_Block">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}
          {/* MSA WARNING END */}

          <div class="nk-content-inner">
            <div class="nk-content-body">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between g-3">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      Channel Partner /{" "}
                      <strong class="text-primary small">
                        {this.state.client_update_name} (
                        {this.state.client_comp_name})
                      </strong>
                    </h3>
                    <div class="nk-block-des text-soft">
                      <ul class="list-inline">
                        <li>
                          Channel Partner ID:{" "}
                          <span class="text-base">
                            {this.state.agent_accountno}
                          </span>
                        </li>
                        {/*<li>
                          Last Login:{" "}
                          <span class="text-base">15 Feb, 2019 01:02 PM</span>
                        </li>*/}
                      </ul>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <a
                      onClick={() => this.addMenuHistory("/agents")}
                      style={{ cursor: "pointer" }}
                      class="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                      <span>Back</span>
                    </a>
                    <a
                      onClick={() => this.addMenuHistory("/agents")}
                      style={{ cursor: "pointer" }}
                      class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                    </a>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="card card-bordered">
                  <div class="card-aside-wrap clientsView">
                    <div class="card-content">
                      <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card viewClient_nav-tabs_customStyle">
                        <li
                          class="nav-item viewClients_nav-item_customStyle"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "agreements"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("agreements");
                              this.agentAgreements();
                            }}
                          >
                            <em class="icon ni ni-file-text"></em>
                            <span>Agreements</span>
                          </a>
                        </li>
                        <li
                          class="nav-item viewClients_nav-item_customStyle"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "assigned_products"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("assigned_products");
                              this.showAssignedServices();
                            }}
                          >
                            <em class="icon ni ni-file-text"></em>
                            <span>Assigned Products</span>
                          </a>
                        </li>
                        <li
                          class="nav-item viewClients_nav-item_customStyle"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "forms" ? "active" : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("forms");
                              this.agent_assigned_forms();
                            }}
                          >
                            <em class="icon ni ni-file-text"></em>
                            <span>Forms</span>
                          </a>
                        </li>
                        <li
                          class="nav-item viewClients_nav-item_customStyle"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            className="nav-link"
                            onClick={() => this.agentAssignRole()}
                          >
                            <em class="icon ni ni-users"></em>
                            <span>Channel Partner Menu</span>
                          </a>
                        </li>
                        <li
                          class="nav-item viewClients_nav-item_customStyle"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            className="nav-link"
                            onClick={() =>
                              this.addMenuHistory(
                                "/agents/commissions/" + this.state.client_id
                              )
                            }
                          >
                            <em class="icon ni ni-signin"></em>
                            <span>Commissions</span>
                          </a>
                        </li>
                        <li
                          class="nav-item viewClients_nav-item_customStyle"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            className="nav-link"
                            onClick={() =>
                              this.openLinkInNewTab(
                                "/agents/subagents/" + this.state.client_id
                              )
                            }
                          >
                            <em class="icon ni ni-users"></em>
                            <span>Sub Users</span>
                          </a>
                        </li>
                        <li
                          class="nav-item viewClients_nav-item_customStyle"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            className="nav-link"
                            onClick={() => this.loginDetailView()}
                          >
                            <em class="icon ni ni-signin"></em>
                            <span>Login Details</span>
                          </a>
                        </li>
                      </ul>

                      <div class="tab-content">
                        <div
                          className={
                            this.state.activeTab === "assigned_products"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemProducts"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <h5 class="title">
                                  Assigned Products & Services
                                </h5>
                                <p>
                                  This list comprises the products or services
                                  allocated or permitted for this channel
                                  partner.
                                </p>
                              </div>
                              {this.state.assignedErrorMessage !== "" ? (
                                <div class="alert alert-pro alert-danger">
                                  <div class="alert-text">
                                    <h6>Error</h6>
                                    <p>{this.state.assignedErrorMessage}</p>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.assignedSuccessMessage !== "" ? (
                                <div class="alert alert-pro alert-success">
                                  <div class="alert-text">
                                    <h6>Success</h6>
                                    <p>{this.state.assignedSuccessMessage}</p>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.assignedLoader === true ? (
                                FormLoader()
                              ) : (
                                <div class="table-responsive">
                                  {this.state.serviceAssignedData &&
                                  this.state.serviceAssignedData.length > 0 ? (
                                    <>
                                      <table class="table table-striped">
                                        <thead>
                                          <tr>
                                            <th>
                                              <div class="custom-control custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  checked={this.state.checkAll}
                                                  class="custom-control-input"
                                                  name="selected_products_1"
                                                  onChange={
                                                    this.handleCheckAllChange
                                                  }
                                                  id="product_ids"
                                                />
                                                <label
                                                  class="custom-control-label"
                                                  htmlFor="product_ids"
                                                ></label>
                                              </div>
                                            </th>
                                            <th>Item</th>
                                            <th>SKU</th>
                                            <th>Title</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.serviceAssignedData.map(
                                            (row, index) => (
                                              <tr key={`prokey${index}`}>
                                                <td>
                                                  <div class="custom-control custom-checkbox">
                                                    <input
                                                      type="checkbox"
                                                      checked={row.checked}
                                                      class="custom-control-input"
                                                      name="selected_products"
                                                      onChange={() =>
                                                        this.handleCheckboxChange(
                                                          row.id
                                                        )
                                                      }
                                                      value={row.id}
                                                      id={`product_id_${index}`}
                                                    />
                                                    <label
                                                      class="custom-control-label"
                                                      htmlFor={`product_id_${index}`}
                                                    ></label>
                                                  </div>
                                                </td>
                                                <td>{row.id}</td>
                                                <td>
                                                  {row.sku && row.sku !== ""
                                                    ? row.sku
                                                    : "---"}
                                                </td>
                                                <td>{row.service_title}</td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                      <div className="col-sm-12 mt-4 d-flex justify-content-end">
                                        <div class="nk-block-head-content">
                                          <ul class="nk-block-tools gx-3">
                                            {this.state
                                              .update_service_button ===
                                            true ? (
                                              <button
                                                className="btn btn-primary"
                                                type="button"
                                                disabled
                                              >
                                                <span
                                                  className="spinner-grow spinner-grow-sm"
                                                  role="status"
                                                  aria-hidden="true"
                                                ></span>
                                                <span> Processing... </span>
                                              </button>
                                            ) : (
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    this.updateAssignedServices()
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#fff",
                                                  }}
                                                  class="btn btn-primary"
                                                >
                                                  <em class="icon ni ni-check-round-cut"></em>{" "}
                                                  <span>Update</span>{" "}
                                                </a>
                                              </li>
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div class="alert alert-pro alert-primary">
                                      <div class="alert-text">
                                        <h6>No Service Assigned</h6>
                                        <p>
                                          No service has been assigned to this
                                          client.
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.activeTab === "agreements"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemAgreements"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <div className="nk-block-between mb-4">
                                  <div className="nk-block-head-content">
                                    <h5 className="nk-block-title page-title">
                                      Agreements
                                    </h5>
                                  </div>
                                  <div
                                    className="nk-block-head-content"
                                    id="HeadContent"
                                  >
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                        data-target="pageMenu"
                                      >
                                        <em className="icon ni ni-more-v"></em>
                                      </a>
                                      <div
                                        className="toggle-expand-content"
                                        data-content="pageMenu"
                                      >
                                        <ul className="nk-block-tools g-3">
                                          <li className="nk-block-tools-opt">
                                            <a
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onClick={(e) => {
                                                this.changeActiveTab(
                                                  "archived_agreements"
                                                );
                                                this.archived_agreements(
                                                  this.state.agent_accountno
                                                );
                                              }}
                                              className="btn btn-white btn-dim btn-outline-primary"
                                            >
                                              <em class="icon ni ni-archived"></em>
                                              &nbsp; Archived Agreements
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {this.state.assignedLoader === true ? (
                                  FormLoader()
                                ) : (
                                  <>
                                    <div className="caption-text">
                                      <div
                                        className="tab-pane active"
                                        id="tabItemSigned"
                                      >
                                        {this.state.errorMessageAgreements !==
                                        "" ? (
                                          <div className="example-alert example-alert-revoke mb-2">
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .errorMessageAgreements
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {this.state.successMessageAgreements !==
                                        "" ? (
                                          <div className="example-alert example-alert-revoke mb-2">
                                            <div className="alert alert-pro alert-success">
                                              <div className="alert-text">
                                                <h4>Success</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .successMessageAgreements
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {this.state.isRevokeReasonActive ? (
                                          <div id="revokeReasonItems">
                                            <div className="tb-odr-id">
                                              Reason to revoke
                                            </div>
                                            <div className="card card-bordered card-preview mt-1">
                                              <TextareaAutosize
                                                maxrows={4}
                                                name="revoke_reason"
                                                id="revoke_reason"
                                                aria-label=""
                                                placeholder=""
                                                value={this.state.revoke_reason}
                                                helperText="Reason to revoke this agreement"
                                                onChange={
                                                  this.handleRevokeValueChange
                                                }
                                                style={{
                                                  width: "100%",
                                                  height: "100px",
                                                  borderColor:
                                                    "rgba(0, 0, 0, 0.125)",
                                                  borderRadius: "4px",
                                                }}
                                                variant="outlined"
                                              />
                                            </div>
                                            <div
                                              className="form-group text-right"
                                              style={{
                                                marginTop: "15px",
                                                marginBottom: "15px",
                                              }}
                                            >
                                              <button
                                                type="button"
                                                style={{ marginRight: 5 }}
                                                className="btn btn-light"
                                                onClick={() => {
                                                  this.setState({
                                                    isRevokeReasonActive: false,
                                                    revoke_reason: "",
                                                    errorMessage: "",
                                                    successMessage: "",
                                                  });
                                                }}
                                              >
                                                Cancel
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                  this.handleAgentAgreementRevokeSubmit(
                                                    this.state
                                                      .revokeAgreementData
                                                      .agreement_id,
                                                    this.state
                                                      .revokeAgreementData
                                                      .agreement_accountno,
                                                    this.state.client_id,
                                                    this.state.revoke_reason
                                                  );
                                                }}
                                                disabled={this.state.disabled}
                                              >
                                                Submit
                                              </button>
                                            </div>
                                          </div>
                                        ) : null}

                                        {this.state.isApproveActive ? (
                                          <div className="row g-gs">
                                            <div className="col-md-12 mb-3">
                                              <div class="card card-bordered">
                                                <div class="card-inner">
                                                  <h5 class="card-title">
                                                    Approve Agreement
                                                  </h5>

                                                  <p class="card-text">
                                                    Are you sure you want to
                                                    approve this agreement?
                                                  </p>

                                                  <div className="col-md-12">
                                                    <div className="form-group text-right">
                                                      <button
                                                        type="button"
                                                        onClick={() =>
                                                          this.setState({
                                                            isApproveActive: false,
                                                            approve_agreement_accountno:
                                                              "",
                                                            approve_agreement_id:
                                                              "",
                                                          })
                                                        }
                                                        className="btn btn-light"
                                                        disabled={
                                                          this.state.disabled
                                                        }
                                                        style={{
                                                          marginRight: "15px",
                                                        }}
                                                      >
                                                        No
                                                      </button>
                                                      <button
                                                        type="button"
                                                        onClick={() =>
                                                          this.approveAgreement(
                                                            this.state.client_id
                                                          )
                                                        }
                                                        className="btn btn-success"
                                                        disabled={
                                                          this.state.disabled
                                                        }
                                                        title=""
                                                      >
                                                        Yes
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}

                                        <div className="card card-bordered card-preview">
                                          <table className="table table-orders viewClient_table-orders_customStyle">
                                            <thead className="tb-odr-head">
                                              <tr className="tb-odr-item">
                                                <th>
                                                  <span className="tb-odr-id">
                                                    Agreement Name
                                                  </span>
                                                </th>
                                                <th>
                                                  <span className="tb-odr-date d-none d-md-inline-block">
                                                    Signed Date
                                                  </span>
                                                </th>
                                                <th>
                                                  <span className="tb-odr-total">
                                                    Approved
                                                  </span>
                                                </th>
                                                <th>
                                                  <span className="tb-odr-total">
                                                    Status
                                                  </span>
                                                </th>
                                                <th>
                                                  <span className="tb-odr-status d-none d-md-inline-block">
                                                    Download
                                                  </span>
                                                </th>
                                                <th className="tb-odr-action">
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody className="tb-odr-body">
                                              {this.state.signedData &&
                                              this.state.signedData.length >
                                                0 ? (
                                                <>
                                                  {this.state.signedData.map(
                                                    (signed, index) => (
                                                      <tr
                                                        className="tb-odr-item"
                                                        key={`sign${index}`}
                                                      >
                                                        <td>
                                                          <span className="tb-odr-id">
                                                            {
                                                              signed.agreement_title
                                                            }
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span className="tb-odr-date">
                                                            {signed.signed_date &&
                                                            signed.signed_date !==
                                                              ""
                                                              ? this.format_date(
                                                                  signed.signed_date
                                                                )
                                                              : "---"}
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span className="tb-odr-status">
                                                            {signed.approved ===
                                                            "Yes" ? (
                                                              <span className="badge badge-outline-success">
                                                                Yes
                                                              </span>
                                                            ) : (
                                                              <span className="badge badge-outline-danger">
                                                                No
                                                              </span>
                                                            )}
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span className="tb-odr-status">
                                                            {signed.is_signed ===
                                                            "Yes" ? (
                                                              <span className="badge badge-outline-success">
                                                                Signed
                                                              </span>
                                                            ) : (
                                                              <span className="badge badge-outline-danger">
                                                                Unsigned
                                                              </span>
                                                            )}
                                                          </span>
                                                        </td>

                                                        <td>
                                                          {signed.pdf_path &&
                                                          signed.pdf_path !==
                                                            "" ? (
                                                            <a
                                                              onClick={() =>
                                                                this.downloadPdf(
                                                                  signed.pdf_path,
                                                                  signed.agreement_title
                                                                )
                                                              }
                                                              title="Download Agreement"
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                            >
                                                              <span className="badge badge-outline-primary">
                                                                <em className="icon ni ni-download"></em>
                                                              </span>
                                                            </a>
                                                          ) : (
                                                            <span className="badge badge-outline-light">
                                                              ---
                                                            </span>
                                                          )}
                                                        </td>
                                                        <td className="tb-odr-action">
                                                          <div className="dropdown">
                                                            <a
                                                              className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                                              data-toggle="dropdown"
                                                              data-offset="-8,0"
                                                            >
                                                              <em className="icon ni ni-more-h"></em>
                                                            </a>
                                                            <div
                                                              className="dropdown-menu dropdown-menu-right dropdown-menu-xs"
                                                              style={{
                                                                padding: "0px",
                                                              }}
                                                            >
                                                              <ul className="link-list-plain">
                                                                {signed.is_signed ===
                                                                  "Yes" &&
                                                                signed.approved ===
                                                                  "No" ? (
                                                                  <li>
                                                                    <a
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      className="text-success"
                                                                      onClick={() =>
                                                                        this.setState(
                                                                          {
                                                                            isApproveActive: true,
                                                                            isRevokeReasonActive: false,
                                                                            approve_agreement_id:
                                                                              signed.agreement_id,
                                                                            approve_agreement_accountno:
                                                                              signed.agreement_accountno,
                                                                          }
                                                                        )
                                                                      }
                                                                    >
                                                                      <em class="icon ni ni-done"></em>
                                                                      <span>
                                                                        Approve
                                                                      </span>
                                                                    </a>
                                                                  </li>
                                                                ) : null}
                                                                {signed.is_signed ===
                                                                "Yes" ? (
                                                                  <li>
                                                                    <a
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        this.revokeAgreement(
                                                                          signed.agreement_id,
                                                                          signed.agreement_accountno
                                                                        )
                                                                      }
                                                                    >
                                                                      <em class="icon ni ni-na"></em>
                                                                      <span>
                                                                        Revoke
                                                                      </span>
                                                                    </a>
                                                                  </li>
                                                                ) : null}
                                                                {signed.is_signed ===
                                                                "Yes" ? (
                                                                  <li>
                                                                    <a
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        this.openSigneeDetails(
                                                                          signed.agreement_accountno
                                                                        )
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="top"
                                                                      title="Show signee details"
                                                                    >
                                                                      <em class="icon ni ni-table-view"></em>
                                                                      <span>
                                                                        Signee
                                                                        Details
                                                                      </span>
                                                                    </a>
                                                                  </li>
                                                                ) : null}
                                                                <li>
                                                                  <a
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                      this.openUploadContract(
                                                                        signed.agreement_accountno
                                                                      )
                                                                    }
                                                                    data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    title="Upload new contract"
                                                                  >
                                                                    <em class="icon ni ni-upload"></em>
                                                                    <span>
                                                                      Upload
                                                                      Agreement
                                                                    </span>
                                                                  </a>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                      this.removeContract(
                                                                        signed.agreement_accountno
                                                                      )
                                                                    }
                                                                    data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    title="Upload new contract"
                                                                  >
                                                                    <em class="icon ni ni-minus-circle"></em>{" "}
                                                                    <span>
                                                                      Remove
                                                                      Agreement
                                                                    </span>
                                                                  </a>
                                                                </li>
                                                                {signed.is_signed ===
                                                                "No" ? (
                                                                  <li>
                                                                    <a
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        this.open_signee(
                                                                          signed.agreement_accountno
                                                                        )
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="top"
                                                                      title="Upload new contract"
                                                                    >
                                                                      <em class="icon ni ni-mail"></em>{" "}
                                                                      <span>
                                                                        Send
                                                                        Email
                                                                      </span>
                                                                    </a>
                                                                  </li>
                                                                ) : null}
                                                              </ul>
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </>
                                              ) : (
                                                <tr>
                                                  <td
                                                    colSpan="6"
                                                    className="text-center"
                                                  >
                                                    No Assigned Agreements
                                                  </td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.activeTab === "forms"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemForms"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <div class="nk-block-between-md g-4">
                                  <div class="nk-block-head-content">
                                    <h5 class="nk-block-title">
                                      Assigned Forms
                                    </h5>
                                  </div>
                                  <div class="nk-block-head-content">
                                    <ul class="nk-block-tools g-4 flex-wrap">
                                      <li class="order-md-last">
                                        <a
                                          onClick={() => this.getAllForms()}
                                          href="#"
                                          class="btn btn-white btn-dim btn-outline-primary"
                                        >
                                          <em class="icon ni ni-note-add-c"></em>
                                          <span>Assign Forms</span>
                                        </a>
                                      </li>
                                      <li class="order-md-last">
                                        <a
                                          onClick={(e) => {
                                            this.changeActiveTab(
                                              "archived_forms"
                                            );
                                            this.agent_archived_forms(
                                              this.state.agent_accountno
                                            );
                                          }}
                                          href="#"
                                          class="btn btn-white btn-dim btn-outline-primary"
                                        >
                                          <em class="icon ni ni-archived"></em>
                                          <span>Archived Submissions</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {this.state.formsErrorMessage !== "" ? (
                                <div class="alert alert-pro alert-danger">
                                  <div class="alert-text">
                                    <h6>Error</h6>
                                    <p>{this.state.formsErrorMessage}</p>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.formsSuccessMessage !== "" ? (
                                <div class="alert alert-pro alert-success">
                                  <div class="alert-text">
                                    <h6>Success</h6>
                                    <p>{this.state.formsSuccessMessage}</p>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.assignedLoader === true ? (
                                FormLoader()
                              ) : (
                                <>
                                  <div className="caption-text">
                                    <div
                                      className="tab-pane active"
                                      id="tabItemSigned"
                                    >
                                      <div className="card card-bordered card-preview">
                                        <DataTableExtended
                                          columns={this.state.columnForms}
                                          tableData={
                                            this.state.formsAssignedData
                                          }
                                          title=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        {/***** Archived Agreements *****/}
                        <div
                          className={
                            this.state.activeTab === "archived_agreements"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemArchivedAgreements"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <div className="modal-title mb-4" style={{}}>
                                  <h5>
                                    <em className="icon ni ni-card-view"></em>{" "}
                                    Archived Agreements
                                  </h5>
                                </div>
                                {this.state.assignedLoader === true ? (
                                  FormLoader()
                                ) : (
                                  <>
                                    <div className="caption-text">
                                      <div
                                        className="tab-pane active"
                                        id="tabItemSigned"
                                      >
                                        {this.state
                                          .errorMessageArchiveAgreements !==
                                        "" ? (
                                          <div className="example-alert example-alert-revoke">
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .errorMessageArchiveAgreements
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}

                                        <div className="card card-bordered card-preview">
                                          <DataTableExtended
                                            columns={this.state.columnServices}
                                            tableData={
                                              this.state.archivedAgreements
                                            }
                                            title=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/***** Archived AGreements *****/}
                        {/***** Archived Forms *****/}
                        <div
                          className={
                            this.state.activeTab === "archived_forms"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemArchivedForms"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <div className="modal-title mb-4" style={{}}>
                                  <h5>
                                    <em className="icon ni ni-card-view"></em>{" "}
                                    Archived Form Submissions
                                  </h5>
                                </div>
                                {this.state.assignedLoader === true ? (
                                  FormLoader()
                                ) : (
                                  <>
                                    <div className="caption-text">
                                      <div
                                        className="tab-pane active"
                                        id="tabItemSigned"
                                      >
                                        {this.state.errorMessageArchiveForms !==
                                        "" ? (
                                          <div className="example-alert example-alert-revoke">
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .errorMessageArchiveForms
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}

                                        <div className="card card-bordered card-preview">
                                          <DataTableExtended
                                            columns={
                                              this.state.columnArchivedForms
                                            }
                                            tableData={this.state.archivedForms}
                                            title=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/***** Archived Forms *****/}
                      </div>
                    </div>
                    <div
                      class="card-aside card-aside-right user-aside"
                      data-content="userAside"
                      data-toggle-screen="xxl"
                      data-toggle-overlay="true"
                      data-toggle-body="true"
                    >
                      <div class="card-inner-group" data-simplebar>
                        <div
                          class="card-inner"
                          style={{ borderBottom: "1px solid #dbdfea" }}
                        >
                          <div class="user-card user-card-s2">
                            <div class="user-avatar lg bg-primary">
                              <span>{this.state.client_name_initials}</span>
                            </div>
                            <div class="user-info">
                              {this.state.client_status &&
                              this.state.client_status === "Yes" ? (
                                <div class="badge badge-outline-success badge-pill ucap">
                                  Verified
                                </div>
                              ) : (
                                <div class="badge badge-outline-danger badge-pill ucap">
                                  Not Verified
                                </div>
                              )}
                              <h5>{this.state.client_update_name}</h5>
                              <span class="sub-text">
                                {this.state.client_update_email}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          borderBottom: "1px solid #dbdfea",
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "stretch",
                          gap: "10px",
                          fontSize: "20px",
                        }}
                      >
                        <div
                          style={{
                            margin: "5px 0px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <a
                            data-toggle="tooltip"
                            data-placement="left"
                            onClick={() => this.editAgentForm()}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <em
                              className="icon ni ni-edit"
                              style={{ fontSize: "14px" }}
                            ></em>
                            <span
                              style={{ fontSize: "12px", marginLeft: "5px" }}
                            >
                              Edit Channel Partner
                            </span>
                          </a>
                        </div>

                        {/* Divider */}
                        <div
                          style={{
                            borderLeft: "1px solid #dbdfea",
                            height: "36px",
                            margin: "0 3px",
                          }}
                        ></div>
                        {this.state.client_status &&
                        this.state.client_status === "No" ? (
                          <div
                            style={{
                              margin: "5px 0px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <a
                              data-toggle="tooltip"
                              data-placement="left"
                              onClick={() => this.resendActivationAgent()}
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <em
                                className="icon ni ni-signin"
                                style={{ fontSize: "14px" }}
                              ></em>
                              <span
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "5px",
                                }}
                              >
                                Resend Activation
                              </span>
                            </a>
                          </div>
                        ) : (
                          <div
                            style={{
                              margin: "5px 0px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <a
                              data-toggle="tooltip"
                              data-placement="left"
                              onClick={() => this.agentLogin()}
                              title="Dashboard"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <em
                                className="icon ni ni-signin"
                                style={{ fontSize: "14px" }}
                              ></em>
                              <span
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "5px",
                                }}
                              >
                                Dashboard
                              </span>
                            </a>
                          </div>
                        )}
                      </div>

                      <div class="card-inner">
                        <a
                          class="overline-title-alt"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            this.changeActiveTab("agreements");
                            this.agentAgreements();
                            this.reloadAgentInfo();
                          }}
                        >
                          Agreements
                        </a>
                        <div class="row text-center">
                          <div class="col-4">
                            <div
                              class="profile-stats"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                this.changeActiveTab("agreements");
                                this.agentAgreements();
                                this.reloadAgentInfo();
                              }}
                            >
                              <span class="amount">
                                {this.state.totalClientAgreements}
                              </span>
                              <span class="sub-text">Total</span>
                            </div>
                          </div>
                          <div class="col-4">
                            <div
                              class="profile-stats"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                this.changeActiveTab("agreements");
                                this.agentAgreements();
                                this.reloadAgentInfo();
                              }}
                            >
                              <span class="amount">
                                {this.state.signedClientAgreements}
                              </span>
                              <span class="sub-text">Signed</span>
                            </div>
                          </div>
                          <div class="col-4">
                            <div
                              class="profile-stats"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                this.changeActiveTab("agreements");
                                this.agentAgreements();
                                this.reloadAgentInfo();
                              }}
                            >
                              <span class="amount">
                                {this.state.unsignedClientAgreements}
                              </span>
                              <span class="sub-text">Unsigned</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Remove Contract */}
                    <div
                      className="modal fade"
                      tabIndex="-1"
                      id="modalRemoveContract"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <a
                            style={{ cursor: "pointer" }}
                            className="close"
                            onClick={() => {
                              this.modalHideRemove();
                            }}
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                          <div className="modal-body modal-body-lg text-center">
                            <div className="nk-modal">
                              <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                              <h4 className="nk-modal-title">
                                Remove agreement?
                              </h4>
                              <div className="nk-modal-text">
                                {this.state.removeErrorMessage !== "" ? (
                                  <div
                                    className="example-alert"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.removeErrorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.removeSuccessMessage !== "" ? (
                                  <div
                                    className="example-alert"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.removeSuccessMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div className="caption-text">
                                  Are you sure you want to delete this
                                  agreement?
                                </div>
                              </div>
                              <div className="nk-modal-action">
                                {this.state.remove_agreement_button === true ? (
                                  <button
                                    class="btn btn-lg btn-primary"
                                    type="button"
                                    disabled
                                  >
                                    <span
                                      class="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    <span> Loading... </span>
                                  </button>
                                ) : (
                                  <>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      className="btn btn-lg btn-mw btn-primary"
                                      onClick={() => {
                                        this.modalHideRemove();
                                      }}
                                      disabled={this.state.disabled}
                                    >
                                      CANCEL
                                    </a>
                                    {"  "}
                                    <a
                                      style={{ cursor: "pointer" }}
                                      className="btn btn-lg btn-mw btn-danger"
                                      onClick={() => {
                                        this.remove_agreement();
                                      }}
                                      disabled={this.state.disabled}
                                    >
                                      REMOVE
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Remove Contract */}
                    {/* View Archived Details */}
                    <div
                      className="modal fade zoom"
                      tabIndex="-1"
                      id="modalArchivedDetails"
                    >
                      <div
                        className="modal-dialog modal-xl modal-dialog-top"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <div
                              className="modal-title"
                              style={{ textAlign: "center", fontSize: "20px" }}
                            >
                              <strong>
                                <em className="icon ni ni-list-round"></em>{" "}
                                Agreement Details <sup></sup>
                              </strong>
                            </div>
                            <a
                              href="#"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <em className="icon ni ni-cross"></em>
                            </a>
                          </div>

                          <div className="modal-body">
                            {/* NRC DIVS START */}

                            {this.state.viewLoader === true ? (
                              FormLoader()
                            ) : (
                              <>
                                {this.state.errorMessageArchivedDetails !==
                                "" ? (
                                  <div className="example-alert example-alert-revoke">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>
                                          {
                                            this.state
                                              .errorMessageArchivedDetails
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div>
                                  <div className="card card-bordered">
                                    <div className="card-inner-group">
                                      {this.state.archivedAgreementsDetails &&
                                      this.state.archivedAgreementsDetails
                                        .length > 0 ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              Agreement Details
                                            </h6>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <table className="table table-bordered table-striped">
                                              {this.state.archivedAgreementsDetails.map(
                                                (general, index) => (
                                                  <tbody
                                                    key={index}
                                                    className="text-dark"
                                                  >
                                                    <tr>
                                                      <td>Agreement Name</td>
                                                      <td>
                                                        {
                                                          general.agreement_title
                                                        }
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Archived Date</td>
                                                      <td>
                                                        {this.format_date(
                                                          general.archived_date
                                                        )}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Approved Date</td>
                                                      <td>
                                                        {this.format_date(
                                                          general.approved_date
                                                        )}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Document Uploaded</td>
                                                      <td>
                                                        {general.is_uploaded &&
                                                        general.is_uploaded ===
                                                          "Yes"
                                                          ? "Document Uploaded"
                                                          : "---"}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Signee name</td>
                                                      <td>
                                                        {general.signee_name}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Signee Email</td>
                                                      <td>
                                                        {general.signee_email &&
                                                        general.signee_email !==
                                                          ""
                                                          ? general.signee_email
                                                          : "---"}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Signee Title</td>
                                                      <td>
                                                        {general.signee_title &&
                                                        general.signee_title !==
                                                          ""
                                                          ? general.signee_title
                                                          : "---"}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Reason</td>
                                                      <td>
                                                        {general.revoke_reason}
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                )
                                              )}
                                            </table>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* View Archived Details */}
                    {/* MODAL REASSIGN AGREEMENT */}
                    <div
                      className="modal fade"
                      tabIndex="-1"
                      id="modalReassignAlert"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <a
                            style={{ cursor: "pointer" }}
                            className="close"
                            onClick={() => {
                              this.modalHideReassign();
                            }}
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                          <div className="modal-body modal-body-lg text-center">
                            <div className="nk-modal">
                              <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-repeat bg-success"></em>
                              <h4 className="nk-modal-title">
                                Reassign the agreement?
                              </h4>
                              <div className="nk-modal-text">
                                {this.state.errorMessageReassign !== "" ? (
                                  <div
                                    className="example-alert"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessageReassign}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessageReassign !== "" ? (
                                  <div
                                    className="example-alert"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>
                                          {this.state.successMessageReassign}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div className="caption-text">
                                  Reassigning this archived agreement will
                                  replace the existing one if it's already
                                  assigned to the channel partner.
                                </div>
                              </div>
                              <div className="nk-modal-action">
                                {this.state.disabled === true ? (
                                  <button
                                    class="btn btn-lg btn-primary"
                                    type="button"
                                    disabled
                                  >
                                    <span
                                      class="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    <span> Loading... </span>
                                  </button>
                                ) : (
                                  <>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      className="btn btn-lg btn-mw btn-primary"
                                      onClick={() => {
                                        this.modalHideReassign();
                                      }}
                                      disabled={this.state.disabled}
                                    >
                                      CANCEL
                                    </a>
                                    {"  "}
                                    <a
                                      style={{ cursor: "pointer" }}
                                      className="btn btn-lg btn-mw btn-success"
                                      onClick={() => {
                                        this.reassign_archived_agreement();
                                      }}
                                      disabled={this.state.disabled}
                                    >
                                      Submit
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* MODAL REASSIGN AGREEMENT */}
                    {/** Login Modal Start **/}
                    <div
                      className="modal fade"
                      tabIndex="-1"
                      id="modalDefaultLogin"
                    >
                      <div
                        className="modal-dialog modal-dialog-top modal-xl"
                        role="document"
                      >
                        <div className="modal-content">
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                          <div className="modal-header">
                            <h5 className="modal-title">
                              <em
                                className="icon ni ni-user-list"
                                style={{ marginRight: "15px" }}
                              ></em>{" "}
                              Login to the Agent
                            </h5>
                          </div>
                          <div className="modal-body">
                            {this.state.loginErrorMessage === "" ? (
                              <div className="alert alert-pro alert-primary">
                                <div className="alert-text">
                                  <h6>Connecting.....</h6>
                                  <p>
                                    Please wait while we are connecting to the
                                    agent Login. You will be redirected to the
                                    Agent dashboard after the authentication.
                                  </p>
                                </div>
                              </div>
                            ) : null}
                            {this.state.loginErrorMessage !== "" ? (
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h6>Login Error</h6>
                                  <p>{this.state.loginErrorMessage}</p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/** Login Modal End **/}
                    {/* DETAIL MODAL End */}
                    <div
                      className="modal fade"
                      tabIndex="-1"
                      id="modalFormDetail"
                    >
                      <div
                        className="modal-dialog modal-xl modal-dialog-top"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">
                              Edit Channel Partner
                            </h5>
                            <a
                              href="#"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <em className="icon ni ni-cross"></em>
                            </a>
                          </div>

                          <div className="modal-body">
                            {/* Error Start */}
                            {this.state.errorMessageEdit !== "" ? (
                              <div
                                className="example-alert"
                                style={{ marginBottom: "20px" }}
                              >
                                <div className="alert alert-pro alert-danger">
                                  <div className="alert-text">
                                    <h4>Error</h4>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: this.state.errorMessageEdit,
                                      }}
                                    ></p>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.successMessageEdit !== "" ? (
                              <div
                                className="example-alert"
                                style={{ marginBottom: "20px" }}
                              >
                                <div className="alert alert-pro alert-success">
                                  <div className="alert-text">
                                    <h4>Success</h4>
                                    <p>{this.state.successMessageEdit}</p>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <br />
                            <div className="row g-4">
                              <form method="post" encType="multipart/form-data">
                                <div className="row g-4">
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <TextField
                                        label="Company Name"
                                        error={this.state.errorAgentCompany}
                                        id="agent_company"
                                        name="agent_company"
                                        value={this.state.agent_company}
                                        placeholder="Company Name"
                                        change={this.handleChange}
                                        type="text"
                                        maxLength={50}
                                      />
                                      <small
                                        className="form-text text-muted"
                                        style={{
                                          position: "absolute",
                                          top: "80px",
                                          color: "#555",
                                          fontSize: "0.75rem",
                                          fontWeight: "400",
                                          fontFamily:
                                            '"Roboto", "Helvetica", "Arial", sans-serif',
                                        }}
                                      >
                                        Allowed characters: (&nbsp; _ &nbsp; .
                                        &nbsp; - &nbsp; , &nbsp; &amp; &nbsp; (
                                        ) )
                                      </small>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <TextField
                                        label="Channel Partner Name"
                                        error={this.state.errorName}
                                        id="name"
                                        name="name"
                                        value={this.state.name}
                                        placeholder="Full Name"
                                        change={this.handleChange}
                                        maxLength={50}
                                      />
                                      <small
                                        className="form-text text-muted"
                                        style={{
                                          position: "absolute",
                                          top: "80px",
                                          color: "#555",
                                          fontSize: "0.75rem",
                                          fontWeight: "400",
                                          fontFamily:
                                            '"Roboto", "Helvetica", "Arial", sans-serif',
                                        }}
                                      >
                                        Allowed characters: (&nbsp; ' &nbsp; -
                                        &nbsp; . &nbsp; , )
                                      </small>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <TextField
                                        label="Channel Partner Title"
                                        id="title"
                                        name="title"
                                        value={this.state.title}
                                        placeholder="Title"
                                        change={this.handleChange}
                                        maxLength={30}
                                      />
                                      <small
                                        className="form-text text-muted"
                                        style={{
                                          position: "absolute",
                                          top: "80px",
                                          color: "#555",
                                          fontSize: "0.75rem",
                                          fontWeight: "400",
                                          fontFamily:
                                            '"Roboto", "Helvetica", "Arial", sans-serif',
                                        }}
                                      >
                                        Allowed characters: (&nbsp; _ &nbsp; -
                                        &nbsp; . &nbsp; &amp; &nbsp; , &nbsp; (
                                        ) )
                                      </small>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <TextField
                                        label="Email"
                                        type="text"
                                        id="agent_email"
                                        name="agent_email"
                                        value={this.state.agent_email}
                                        placeholder="Enter email address"
                                        change={this.handleChange}
                                        disabled={true}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <PhoneInput
                                        required
                                        defaultCountry={"us"}
                                        inputProps={{
                                          name: "agent_phone",
                                          id: "agent_phone",
                                          maxLength: 20,
                                          style: this.state.errorAgentPhone
                                            ? { borderColor: "red" }
                                            : {},
                                        }}
                                        style={{
                                          marginTop: "30px",
                                          height: "45px",
                                        }}
                                        value={
                                          this.state.isLoading
                                            ? ""
                                            : this.state.agent_phone
                                        }
                                        placeholder={
                                          this.state.isLoading
                                            ? "Loading..."
                                            : "Enter the phone number"
                                        }
                                        onChange={(value) =>
                                          this.handleChange({
                                            target: {
                                              name: "agent_phone",
                                              value,
                                            },
                                          })
                                        }
                                        helperText="Enter the phone number."
                                        label="Client Phone Number"
                                        ref={this.phoneInputRef}
                                        onFocus={this.handleFocus}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label
                                        className="text-dark"
                                        style={{
                                          marginBottom: "0.5rem",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Account Status
                                      </label>
                                      <select
                                        class="form-control form-control-lg"
                                        id="agent_status"
                                        name="agent_status"
                                        value={this.state.agent_status}
                                        onChange={this.handleChange}
                                      >
                                        <option key="status1" value="1">
                                          Active
                                        </option>
                                        <option key="status0" value="0">
                                          Inactive
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div class="nk-block-between-md g-4">
                                      <div class="nk-block-head-content">
                                        <label
                                          htmlFor="googleLocation-autocomplete"
                                          style={{
                                            color: "black",
                                            fontWeight: "500",
                                            marginBottom: "8px",
                                          }}
                                        >
                                          Address *
                                        </label>
                                      </div>
                                      <div class="nk-block-head-content">
                                        <ul class="nk-block-tools gx-3">
                                          <li>
                                            <a
                                              onClick={this.handleToggle}
                                              class="text-primary fs-11"
                                              style={{
                                                cursor: "pointer",
                                                fontSize: "11px",
                                              }}
                                            >
                                              {toggleButtonText}
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      {useGooglePlaces ? (
                                        <GooglePlacesAutocomplete
                                          apiKey={
                                            process.env
                                              .REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY
                                          }
                                          selectProps={{
                                            value: this.state.agent_address
                                              ? {
                                                  label:
                                                    this.state.agent_address,
                                                  value:
                                                    this.state.agent_address,
                                                }
                                              : null,
                                            onChange: this.handleAddressSelect,
                                            placeholder: this.state
                                              .agent_address
                                              ? null
                                              : "Enter your address..",
                                            styles: {
                                              control: (provided) => ({
                                                ...provided,
                                                minHeight: "43px",
                                              }),
                                            },
                                          }}
                                          className="googleLocation-autocomplete"
                                        />
                                      ) : (
                                        <input
                                          type="text"
                                          className="form-control form-control-lg"
                                          id="agent_address"
                                          name="agent_address"
                                          value={this.state.agent_address}
                                          onChange={this.handleChange}
                                          placeholder="PO Box Address"
                                          //maxLength={50}
                                          inputProps={{ maxLength: 50 }}
                                          fullWidth
                                          error={
                                            this.state.errorAgentAddress ===
                                            "error"
                                          }
                                        />
                                      )}
                                      {/*  */}
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <TextField
                                        label="Apartment, Suite, Etc."
                                        type="text"
                                        id="agent_address2"
                                        name="agent_address2"
                                        value={this.state.agent_address2}
                                        placeholder=""
                                        change={this.handleChange}
                                        maxLength={50}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label
                                        className="text-dark"
                                        style={{
                                          marginBottom: "0.5rem",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Verify Account
                                      </label>
                                      <select
                                        class="form-control form-control-lg"
                                        id="agent_isVerified"
                                        name="agent_isVerified"
                                        value={this.state.agent_isVerified}
                                        onChange={this.handleChange}
                                      >
                                        <option key="status1" value="Yes">
                                          Yes
                                        </option>
                                        <option key="status0" value="No">
                                          No
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className="col-lg-2">
                                    <div className="form-group">
                                      <TextField
                                        label="City"
                                        type="text"
                                        error={this.state.errorAgentCity}
                                        id="agent_city"
                                        name="agent_city"
                                        value={this.state.agent_city}
                                        placeholder="Enter city"
                                        change={this.handleChange}
                                        maxLength={32}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-2">
                                    <div className="form-group">
                                      <TextField
                                        label="State"
                                        type="text"
                                        error={this.state.errorAgentState}
                                        id="agent_state"
                                        name="agent_state"
                                        value={this.state.agent_state}
                                        placeholder="Enter state"
                                        change={this.handleChange}
                                        maxLength={32}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <TextField
                                        label="Zip Code"
                                        type="text"
                                        error={this.state.errorAgentZip}
                                        id="agent_zip"
                                        name="agent_zip"
                                        value={this.state.agent_zip}
                                        placeholder="Enter zip code"
                                        change={this.handleChange}
                                        maxLength={9}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label
                                        className="text-dark"
                                        style={{
                                          marginBottom: "0.5rem",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Country
                                      </label>
                                      <Select
                                        id="agent_country"
                                        name="agent_country"
                                        className="customCountrySelect"
                                        options={this.countryOptions}
                                        value={{
                                          label: this.state.agent_country,
                                          value: this.state.agent_country,
                                        }}
                                        onChange={this.handleChangeCountry}
                                        placeholder="Select country"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <TextField
                                        label={
                                          <Tooltip
                                            title="Password must be at least 8 characters, with uppercase, lowercase, number & special character."
                                            placement="top"
                                          >
                                            <span>
                                              <span>
                                                <em
                                                  className="icon ni ni-info"
                                                  style={{ marginRight: 5 }}
                                                ></em>
                                              </span>
                                              Password
                                            </span>
                                          </Tooltip>
                                        }
                                        type={
                                          this.state.passwordShown
                                            ? "text"
                                            : "password"
                                        }
                                        error={this.state.errorClientPass}
                                        id="agent_password"
                                        name="agent_password"
                                        value={this.state.agent_password}
                                        placeholder="Enter password"
                                        change={this.handleChange}
                                        maxLength={30}
                                      />
                                      <a
                                        style={{
                                          left: "auto",
                                          right: "2px",
                                          top: "53px",
                                          position: "absolute",
                                          cursor: "pointer",
                                        }}
                                        tabIndex="-1"
                                        onClick={this.togglePassword}
                                        className="form-icon form-icon-right passcode-switch"
                                        data-target="password"
                                      >
                                        {this.state.passwordShown ? (
                                          <em className="icon ni ni-eye-off"></em>
                                        ) : (
                                          <em className="icon ni ni-eye"></em>
                                        )}
                                      </a>
                                      <PasswordStrengthBar
                                        password={this.state.agent_password}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <TextField
                                        label={
                                          <Tooltip
                                            title="Confirm Password must be at least 8 characters, with uppercase, lowercase, number & special character. Confirm Password must match the Password."
                                            placement="top"
                                          >
                                            <span>
                                              <span>
                                                <em
                                                  className="icon ni ni-info"
                                                  style={{ marginRight: 5 }}
                                                ></em>
                                              </span>
                                              Confirm Password
                                            </span>
                                          </Tooltip>
                                        }
                                        type={
                                          this.state.confirmPasswordShown
                                            ? "text"
                                            : "password"
                                        }
                                        error={this.state.errorClientPass}
                                        id="c_password"
                                        name="c_password"
                                        value={this.state.c_password}
                                        placeholder="Enter confirm password"
                                        change={this.handleChange}
                                        maxLength={30}
                                      />
                                      <a
                                        style={{
                                          left: "auto",
                                          right: "2px",
                                          top: "53px",
                                          position: "absolute",
                                          cursor: "pointer",
                                        }}
                                        tabIndex="-1"
                                        onClick={() => {
                                          this.toggleConfirmPassword();
                                        }}
                                        className="form-icon form-icon-right passcode-switch"
                                        data-target="password"
                                      >
                                        {this.state.confirmPasswordShown ? (
                                          <em className="icon ni ni-eye-off"></em>
                                        ) : (
                                          <em className="icon ni ni-eye"></em>
                                        )}
                                      </a>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <TextField
                                        label="Sharelink Limit"
                                        type="text"
                                        error={
                                          this.state.errorAgentSharelink ===
                                          "error"
                                        }
                                        id="sharelink_limit"
                                        name="sharelink_limit"
                                        value={this.state.sharelink_limit}
                                        placeholder="Enter zip code"
                                        change={this.handleChange}
                                        maxLength={2}
                                        onKeyPress={() => {
                                          this.checkNumber();
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group col-md-4">
                                    <div
                                      class="custom-control custom-control-lg custom-checkbox"
                                      style={{ padding: 0, marginLeft: "3rem" }}
                                    >
                                      <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="customCheck2"
                                        onChange={() => {
                                          const isChecked =
                                            !this.state.notifyCheckBox;
                                          this.setState({
                                            notifyCheckBox: isChecked,
                                          });
                                          console.log(
                                            "Notify Checkbox Checked:",
                                            isChecked
                                          );
                                        }}
                                      />

                                      <label
                                        class="custom-control-label"
                                        for="customCheck2"
                                      >
                                        Notify Agent
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    className="col-lg-12 no-padding-margin-preview"
                                    style={{
                                      padding: 0,
                                      cursor: "pointer",
                                      color: "#0066cc",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.preViewDefault();
                                    }}
                                  >
                                    <span>Preview Notification Email </span>
                                  </div>
                                  <div style={{ clear: "both" }}></div>
                                  <hr />
                                  <div className="form-group text-right col-md-12">
                                    <button
                                      className="btn btn-lg btn-primary"
                                      type="button"
                                      disabled={this.state.disabled}
                                      onClick={this.formUpdateSubmit}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>

                            {/* </form> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* DETAIL MODAL START */}
                    {/* RESEND ACTIVATION MODAL START */}
                    <div
                      className="modal fade"
                      tabIndex="-1"
                      id="modalAlertResend"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <a href="#" className="close" data-dismiss="modal">
                            <em className="icon ni ni-cross"></em>
                          </a>
                          <div className="modal-body modal-body-lg text-center">
                            <div className="nk-modal">
                              {this.state.activation_button === true ? (
                                <>
                                  <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-warning"></em>
                                  <h4 className="nk-modal-title">Sending!</h4>
                                  <div className="nk-modal-text">
                                    <div className="caption-text">
                                      Please wait while we are processing your
                                      request
                                    </div>
                                    <span className="sub-text-sm"></span>
                                  </div>
                                  <div className="nk-modal-action"></div>
                                </>
                              ) : null}
                              {this.state.activation_tick === true ? (
                                <>
                                  <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                                  <h4 className="nk-modal-title">
                                    Congratulations!
                                  </h4>
                                  <div className="nk-modal-text">
                                    <div className="caption-text">
                                      Activation email has been sent
                                      successfully.
                                    </div>
                                    <span className="sub-text-sm"></span>
                                  </div>
                                  <div className="nk-modal-action"></div>
                                </>
                              ) : null}
                              {this.state.activation_cross === true ? (
                                <>
                                  <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                                  <h4 className="nk-modal-title">Oops!</h4>
                                  <div className="nk-modal-text">
                                    <div className="caption-text">
                                      There is some error while sending
                                      activation email. Please try again later
                                    </div>
                                    <span className="sub-text-sm"></span>
                                  </div>
                                  <div className="nk-modal-action"></div>
                                </>
                              ) : null}
                            </div>
                          </div>
                          <div className="modal-footer bg-lighter">
                            <div className="text-center w-100">
                              <a
                                href="#"
                                className="btn btn-lg btn-mw btn-primary"
                                data-dismiss="modal"
                              >
                                Close
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* RESEND ACTIVATION MODAL END */}
                    {/* DELETE ARCHIVE */}
                    <div
                      className="modal fade"
                      tabIndex="-1"
                      id="modalDeleteAlert"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <a
                            style={{ cursor: "pointer" }}
                            className="close"
                            onClick={() => {
                              this.modalHideDel();
                            }}
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                          <div className="modal-body modal-body-lg text-center">
                            <div className="nk-modal">
                              <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                              <h4 className="nk-modal-title">
                                Delete archived agreement?
                              </h4>
                              <div className="nk-modal-text">
                                {this.state.errorMessageDelete !== "" ? (
                                  <div
                                    className="example-alert"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessageDelete}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessageDelete !== "" ? (
                                  <div
                                    className="example-alert"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessageDelete}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div className="caption-text">
                                  Are you sure you want to delete this archived
                                  agreement?
                                </div>
                              </div>
                              <div className="nk-modal-action">
                                <a
                                  style={{ cursor: "pointer" }}
                                  className="btn btn-lg btn-mw btn-primary"
                                  onClick={() => {
                                    this.modalHideDel();
                                  }}
                                  disabled={this.state.disabled}
                                >
                                  CANCEL
                                </a>
                                {"  "}
                                <a
                                  style={{ cursor: "pointer" }}
                                  className="btn btn-lg btn-mw btn-danger"
                                  onClick={() => {
                                    this.delete_archived_agreement();
                                  }}
                                  disabled={this.state.disabled}
                                >
                                  DELETE
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* DELETE ARCHIVE */}
                    {/**** Open Contract Modal ***/}
                    <div
                      class="modal fade"
                      tabindex="-1"
                      id="modalOpenUploadContract"
                    >
                      <div
                        class="modal-dialog modal-xl modal-dialog-top"
                        role="document"
                      >
                        <div class="modal-content">
                          <a
                            href="#"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em class="icon ni ni-cross"></em>
                          </a>
                          <div class="modal-header">
                            <h5 class="modal-title">Upload Agreement</h5>
                          </div>
                          <div class="modal-body">
                            <div className="row g-gs">
                              <div className="col-md-12 mb-3">
                                {this.state.uploadErrorMessage !== "" ? (
                                  <div class="alert alert-pro alert-danger mb-2">
                                    <div class="alert-text">
                                      <h6>Error</h6>
                                      <p>{this.state.uploadErrorMessage} </p>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.uploadSuccessMessage !== "" ? (
                                  <div class="alert alert-pro alert-success mb-2">
                                    <div class="alert-text">
                                      <h6>Success</h6>
                                      <p>{this.state.uploadSuccessMessage} </p>
                                    </div>
                                  </div>
                                ) : null}

                                <div
                                  className="col-md-12 mb-2"
                                  style={{
                                    marginBottom: "20px",
                                    clear: "both",
                                  }}
                                >
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">
                                        <Tooltip
                                          title="Upload agreement directly."
                                          placement="right"
                                        >
                                          <em
                                            className="icon ni ni-info"
                                            style={{
                                              color: "#6576ff",
                                            }}
                                          ></em>
                                        </Tooltip>{" "}
                                        Upload Agreement <sup>*</sup>
                                        <span className="text-danger"></span>
                                      </label>
                                    </div>
                                    <div className="form-control-group signatory_upload mb-4">
                                      <div class="custom-file">
                                        <input
                                          type="file"
                                          class="custom-file-input"
                                          id="add_picture"
                                          name="add_picture"
                                          defaultValue={this.state.add_picture}
                                          accept=".png, .jpg, .jpeg, .doc, .docx, .pdf, .txt, .rtf, .zip"
                                          onChange={this.handleFileChange}
                                        />
                                        <label
                                          class="custom-file-label"
                                          for="add_picture"
                                        >
                                          Choose file
                                        </label>
                                      </div>
                                    </div>
                                    <div class="form-note">
                                      {this.state.add_picture_name}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mt-5">
                                  <div className="example-alert">
                                    <div className="alert alert-light">
                                      <ul className="list">
                                        <li>
                                          Accepted Formats: PDF, DOC, DOCX, ZIP,
                                          TXT, RTF, PNG, JPEG, JPG
                                        </li>
                                        <li>Recommended Size: 10MB</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="modal-footer"
                            style={{ justifyContent: "right" }}
                          >
                            <div class="d-flex justify-content-end">
                              {this.state.upload_agreement_button === true ? (
                                <button
                                  className="btn btn-lg btn-primary"
                                  disabled
                                >
                                  <em className="icon ni ni-swap"></em> Saving
                                  <div
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  class="btn btn-lg btn-primary"
                                  onClick={() => this.submitClientContract()}
                                >
                                  Save Agreement
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/**** Open Contract Modal ***/}
                    {/**** Open Signee Details Modal ***/}
                    <div class="modal fade" tabindex="-1" id="modalOpenSignee">
                      <div
                        class="modal-dialog modal-xl modal-dialog-top"
                        role="document"
                      >
                        <div class="modal-content">
                          <a
                            href="#"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em class="icon ni ni-cross"></em>
                          </a>
                          <div class="modal-header">
                            <h5 class="modal-title">Signee Details</h5>
                          </div>
                          <div class="modal-body">
                            <div class="g-4">
                              <div id="no-more-tables-new">
                                <table class="table table-bordered">
                                  <thead class="thead-light">
                                    <tr>
                                      <th class="tb-col-os">
                                        <span class="overline-title">Name</span>
                                      </th>
                                      <th class="tb-col-os">
                                        <span class="overline-title">
                                          Email
                                        </span>
                                      </th>
                                      <th class="tb-col-os">
                                        <span class="overline-title">
                                          Title
                                        </span>
                                      </th>
                                      <th class="tb-col-ip">
                                        <span class="overline-title">
                                          Signed Date
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.signee_details &&
                                    this.state.signee_details.length > 0 ? (
                                      <>
                                        {this.state.signee_details.map(
                                          (details, index) => (
                                            <tr key={`idx${index}`}>
                                              <td class="tb-col-os">
                                                {details.signee_name}
                                              </td>
                                              <td class="tb-col-os">
                                                {details.signee_email}
                                              </td>
                                              <td class="tb-col-os">
                                                {details.signee_title &&
                                                details.signee_title !== ""
                                                  ? details.signee_title
                                                  : "---"}
                                              </td>
                                              <td class="tb-col-ip">
                                                {details.signed_date &&
                                                details.signed_date !== ""
                                                  ? this.format_date(
                                                      details.signed_date
                                                    )
                                                  : "---"}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </>
                                    ) : (
                                      <tr>
                                        <td class="tb-col-os" colSpan={4}>
                                          <strong className="text-dark">
                                            Uploded By Admin.
                                          </strong>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/**** Open Signee Details Modal ***/}
                    {/* PREVIEW DEFAULT MODAL  */}
                    <div
                      className="modal fade"
                      tabIndex="-1"
                      id="modalPreviewDefaultModal"
                      style={{ zIndex: "9999" }}
                    >
                      <div
                        className="modal-dialog modal-dialog-top modal-xl"
                        role="document"
                      >
                        <div className="modal-content">
                          <div
                            className="modal-header"
                            style={{ borderBottom: "1px solid #e5e9f2" }}
                          >
                            <h5
                              className="modal-title"
                              style={{
                                fontFamily: "'DM Sans', sans-serif",
                                fontWeight: "700",
                                fontSize: "1.25rem",
                                color: "#364a63",
                              }}
                            >
                              Preview Notification Email
                            </h5>
                            <a
                              style={{ cursor: "pointer" }}
                              className="close"
                              onClick={() => this.closePreviewModal()}
                            >
                              <em className="icon ni ni-cross"></em>
                            </a>
                          </div>
                          <div
                            className="modal-body"
                            style={{ padding: "1.5rem" }}
                          >
                            <div className="nk-block">
                              <div className="card card-bordered">
                                <div className="card-inner">
                                  <div
                                    className="email-preview"
                                    style={{
                                      maxHeight: "60vh",
                                      overflow: "auto",
                                      padding: "1rem",
                                      backgroundColor: "#f5f6fa",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    {this.state.isEditorReady ? (
                                      <div
                                        className="email-preview-content"
                                        style={{
                                          fontFamily: "'Roboto', sans-serif",
                                          fontSize: "14px",
                                          lineHeight: "1.65",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: this.state.email_template,
                                        }}
                                      />
                                    ) : (
                                      tableLoader()
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="modal-footer"
                            style={{
                              borderTop: "1px solid #e5e9f2",
                              padding: "1rem 1.5rem",
                            }}
                          >
                            <button
                              onClick={() => this.closePreviewModal()}
                              className="btn btn-primary"
                              style={{
                                fontFamily: "'DM Sans', sans-serif",
                                fontWeight: "500",
                              }}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* PREVIEW DEFAULT MODAL  */}
                    {/*** Signee Modal ***/}
                    <div
                      className="modal fade"
                      tabindex="-1"
                      id="modalSendSignee"
                    >
                      <div
                        className="modal-dialog modal-dialog-top modal-xl"
                        role="document"
                      >
                        <div className="modal-content">
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                          <div className="modal-header">
                            <h5 className="modal-title">Send Email</h5>
                          </div>
                          <div className="modal-body">
                            {this.state.errorMessageSignee !== "" ? (
                              <div className="example-alert">
                                <div className="alert alert-pro alert-danger">
                                  <div className="alert-text">
                                    <h4>Error</h4>
                                    <p>{this.state.errorMessageSignee}</p>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.successMessageSignee !== "" ? (
                              <div className="example-alert">
                                <div className="alert alert-pro alert-success">
                                  <div className="alert-text">
                                    <h4>Success</h4>
                                    <p>{this.state.successMessageSignee}</p>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <br />

                            <div className="row g-4">
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <div className="form-group">
                                    <MutextField
                                      required
                                      id="signee_name"
                                      name="signee_name"
                                      type="text"
                                      label="Full Name"
                                      value={this.state.signee_name}
                                      onChange={this.handleChange}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <MutextField
                                    id="signee_title"
                                    name="signee_title"
                                    type="text"
                                    label="Title"
                                    value={this.state.signee_title}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <MutextField
                                    required
                                    id="signee_email"
                                    name="signee_email"
                                    type="text"
                                    label="Email Address"
                                    value={this.state.signee_email}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div className="form-group">
                                  <textarea
                                    class="form-control"
                                    id="signee_comment"
                                    name="signee_comment"
                                    onChange={this.handleChangeTextArea}
                                    placeholder="Comments *"
                                    maxLength={1000}
                                    value={this.state.signee_comment}
                                  />
                                  <div class="form-note">
                                    {this.state.signee_comment.length}/
                                    {this.state.charLimit} characters
                                  </div>
                                  <div class="form-note">
                                    {this.state.characterExceededMessage &&
                                    this.state.characterExceededMessage !==
                                      "" ? (
                                      <code>
                                        {this.state.characterExceededMessage}
                                      </code>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div class="example-alert">
                                  <div class="alert alert-warning alert-icon">
                                    <em class="icon ni ni-alert-circle"></em>{" "}
                                    <strong>NOTE</strong>. This will send new
                                    agreement sign request. Your previous
                                    request will be discarded if you have sent
                                    any.
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 text-right">
                                <button
                                  type="button"
                                  className="btn btn-light"
                                  style={{ marginRight: "10px" }}
                                  onClick={() => this.close_signee()}
                                >
                                  <em class="icon ni ni-step-back"></em> Cancel
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => this.send_email()}
                                  disabled={this.state.disabled}
                                >
                                  <em className="icon ni ni-done"></em> Send
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Signee Model */}
                    {/* Role Assignment Modal START */}
                    <div
                      className="modal fade"
                      tabIndex="-1"
                      id="modalAgentAssignRole"
                    >
                      <div
                        className="modal-dialog modal-xl modal-dialog-top"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <div
                              className="modal-title"
                              style={{ textAlign: "center", fontSize: "20px" }}
                            >
                              <strong>
                                <em className="icon ni ni-grid-alt"></em>Channel
                                Partner Menu{" "}
                                <sup>
                                  <small className="text-soft">
                                    {this.state.rolesAgentName}
                                  </small>
                                </sup>
                              </strong>
                            </div>
                            <a
                              href="#"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <em className="icon ni ni-cross"></em>
                            </a>
                          </div>

                          <div className="modal-body text-left">
                            <div className="nk-modal">
                              <div className="nk-modal-text">
                                {this.state.agentRoleLoader === true ? (
                                  tableLoader()
                                ) : (
                                  <>
                                    {this.state.errorMessageAgentRole !== "" ? (
                                      <div
                                        className="example-alert "
                                        style={{ marginBottom: "15px" }}
                                      >
                                        <div className="alert alert-pro alert-danger">
                                          <div className="alert-text">
                                            <h4>Error</h4>
                                            <p>
                                              {this.state.errorMessageAgentRole}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.successMessageAgentRole !==
                                    "" ? (
                                      <div
                                        className="example-alert "
                                        style={{ marginBottom: "15px" }}
                                      >
                                        <div className="alert alert-pro alert-success">
                                          <div className="alert-text">
                                            <h4>Success</h4>
                                            <p>
                                              {
                                                this.state
                                                  .successMessageAgentRole
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    <div className="card card-bordered card-preview">
                                      <table className="table table-sm">
                                        <thead className="tb-odr-head">
                                          <tr className="tb-odr-item">
                                            <th
                                              width="35%"
                                              style={{
                                                borderRight:
                                                  "1px solid #dbdfea",
                                              }}
                                            >
                                              <span className="tb-odr-action">
                                                Main Menu
                                              </span>
                                            </th>
                                            <th width="65%">
                                              <span className="tb-odr-action">
                                                Sub Menu
                                              </span>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody className="tb-odr-body">
                                          {this.state.allRoleData &&
                                          this.state.allRoleData.length > 0 ? (
                                            <>
                                              {this.state.allRoleData.map(
                                                (role, index) => (
                                                  <tr
                                                    className=""
                                                    key={`role${index}`}
                                                  >
                                                    <td
                                                      style={{
                                                        borderRight:
                                                          "1px solid #dbdfea",
                                                      }}
                                                    >
                                                      <FormControlLabel
                                                        control={
                                                          <Checkbox
                                                            checked={this.state.allRoleData.some(
                                                              (item) =>
                                                                item.alt ===
                                                                  role.alt &&
                                                                item.isChecked
                                                            )}
                                                            onChange={
                                                              this
                                                                .changedAllRolesRights
                                                            }
                                                            disabled={
                                                              role.seqno ===
                                                                1 &&
                                                              this.state.assignRoleData.some(
                                                                (subRole) =>
                                                                  role.alt ===
                                                                  subRole.alt
                                                              )
                                                            }
                                                            name={role?.id?.toString()}
                                                            color="primary"
                                                          />
                                                        }
                                                        style={{
                                                          marginBottom: "0px",
                                                        }}
                                                        label={role.alt}
                                                      />
                                                    </td>
                                                    <td
                                                      style={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        paddingLeft: "16px",
                                                      }}
                                                    >
                                                      {this.state.allSubRoleData
                                                        .filter((subRole) => {
                                                          return (
                                                            subRole.role_id ===
                                                            role.id
                                                          );
                                                        })
                                                        .map(
                                                          (
                                                            subRole,
                                                            subIndex
                                                          ) => (
                                                            <div
                                                              key={`subRole${subIndex}`}
                                                            >
                                                              <FormControlLabel
                                                                control={
                                                                  <Checkbox
                                                                    checked={this.state.allSubRoleData.some(
                                                                      (item) =>
                                                                        item.alt ===
                                                                          subRole.alt &&
                                                                        item.isChecked
                                                                    )}
                                                                    onChange={
                                                                      this
                                                                        .changedAllSubRolesRights
                                                                    }
                                                                    name={subRole?.tabdetailid?.toString()}
                                                                    color="primary"
                                                                  />
                                                                }
                                                                style={{
                                                                  marginBottom:
                                                                    "0px",
                                                                }}
                                                                label={
                                                                  subRole.alt
                                                                }
                                                              />
                                                            </div>
                                                          )
                                                        )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </>
                                          ) : null}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="nk-modal-action  text-right">
                                      <a
                                        href="#"
                                        className="btn btn-lg btn-mw btn-primary "
                                        onClick={() => {
                                          this.handleSubmitRoleCheckbox(
                                            this.state.checkedName,
                                            this.state.checkedEmail
                                          );
                                        }}
                                        disabled={this.state.disableRoleBtn}
                                      >
                                        Save Changes
                                      </a>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Role Assignment Modal End */}
                    {/* {MODAL LOGIN DETAILS } */}
                    <div
                      className="modal fade"
                      id="modalLoginDetail"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="modalLoginDetailLabel"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-xl modal-dialog-top"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="modalLoginDetailLabel"
                            >
                              Login Details
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            {/* Table to Display Login Details */}
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                <thead className="thead-light">
                                  <tr>
                                    <th>IP Address</th>
                                    <th>Country</th>
                                    <th>State</th>
                                    <th>City</th>
                                    <th>Timezone</th>
                                    <th>Login Time</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.loginDetails &&
                                  this.state.loginDetails.length > 0 ? (
                                    this.state.loginDetails.map(
                                      (detail, index) => (
                                        <tr key={index}>
                                          <td>{detail.ip_address}</td>
                                          <td>{detail.country}</td>
                                          <td>{detail.state}</td>
                                          <td>{detail.city}</td>
                                          <td>{detail.time_zone}</td>
                                          <td>
                                            {new Date(detail.login_time)
                                              .toLocaleDateString("en-US", {
                                                month: "long",
                                                day: "numeric",
                                                year: "numeric",
                                              })
                                              .replace(/(\d+)(?=,)/, "$1th")}
                                            ,{" "}
                                            {new Date(
                                              detail.login_time
                                            ).toLocaleTimeString("en-US")}
                                          </td>
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan="6" className="text-center">
                                        No login details found.
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* { MODAL LOGIN DETAILS } */}
                    {/* View Client Submissions */}
                    <div
                      className="modal fade zoom"
                      tabIndex="-1"
                      id="modalAgentSubmission"
                    >
                      <div
                        className="modal-dialog modal-xl modal-dialog-top"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <div
                              className="modal-title"
                              style={{ textAlign: "center", fontSize: "20px" }}
                            >
                              <strong>
                                <em className="icon ni ni-list-round"></em>{" "}
                                Submission Details{" "}
                              </strong>
                            </div>
                            <a
                              href="#"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <em className="icon ni ni-cross"></em>
                            </a>
                          </div>

                          <div className="modal-body">
                            {/* NRC DIVS START */}

                            {this.state.viewLoader === true ? (
                              FormLoader()
                            ) : (
                              <>
                                <div>
                                  <div className="card card-bordered">
                                    <div className="card-inner-group">
                                      {this.state.errorMessageTotals !== "" ? (
                                        <div class="alert alert-pro alert-danger">
                                          <div class="alert-text">
                                            <h6>
                                              {
                                                this.state
                                                  .errorMessageTotalsTitle
                                              }
                                            </h6>
                                            <p>
                                              {this.state.errorMessageTotals}
                                            </p>
                                          </div>
                                        </div>
                                      ) : null}
                                      {this.state.viewClientSubmissions &&
                                      this.state.viewClientSubmissions.length >
                                        0 ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              Form Submission Details
                                            </h6>
                                          </div>

                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <table className="table table-bordered table-striped">
                                              {this.state.viewClientSubmissions.map(
                                                (general, index) => (
                                                  <tbody
                                                    key={index}
                                                    className="text-dark"
                                                  >
                                                    <tr>
                                                      <td>Progress</td>
                                                      <td>
                                                        <div className="card card-bordered card-preview">
                                                          <div className="card-inner viewClient_card-inner_customStyle">
                                                            <div className="progress progress-lg">
                                                              <div
                                                                className="progress-bar"
                                                                data-progress={
                                                                  this.state
                                                                    .progressPercentage
                                                                }
                                                                style={{
                                                                  width: `${this.state.progressPercentage}%`,
                                                                }}
                                                              >
                                                                {
                                                                  this.state
                                                                    .progressPercentage
                                                                }
                                                                %
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Sent To Name</td>
                                                      <td>
                                                        {general.submitter_name}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Sent To Email</td>
                                                      <td>
                                                        {
                                                          general.submitter_email
                                                        }
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Sent By</td>
                                                      <td>
                                                        {general.sent_by_email}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Sent Date</td>
                                                      <td>
                                                        {this.format_date(
                                                          general.sent_on
                                                        )}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Submitted</td>
                                                      <td>
                                                        {general.is_submitted &&
                                                        general.is_submitted ===
                                                          1 ? (
                                                          <span class="badge badge-dot badge-success">
                                                            Yes
                                                          </span>
                                                        ) : (
                                                          <span class="badge badge-dot badge-danger">
                                                            No
                                                          </span>
                                                        )}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Submission Date</td>
                                                      <td>
                                                        {general.is_submitted &&
                                                        general.is_submitted ===
                                                          1
                                                          ? this.format_date(
                                                              general.submitted_on
                                                            )
                                                          : "---"}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>View PDF</td>
                                                      <td>
                                                        {general.is_submitted &&
                                                        general.is_submitted ===
                                                          1 ? (
                                                          <a
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              this.downloadFormPdf(
                                                                general.pdf_name
                                                              )
                                                            }
                                                          >
                                                            <span class="badge badge-outline-dark">
                                                              View
                                                            </span>
                                                          </a>
                                                        ) : (
                                                          "---"
                                                        )}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Extra Files</td>
                                                      <td>
                                                        {this.state
                                                          .extra_files_clients &&
                                                        this.state
                                                          .extra_files_clients !==
                                                          "" ? (
                                                          <>
                                                            {this.state.extra_files_clients.map(
                                                              (file, index) => (
                                                                <>
                                                                  {file.file_name &&
                                                                  file.file_name !==
                                                                    "" ? (
                                                                    <div
                                                                      key={
                                                                        index
                                                                      }
                                                                      className="col-md-12"
                                                                      style={{
                                                                        paddingLeft:
                                                                          "0px",
                                                                      }}
                                                                    >
                                                                      {file.field_name.trim()}{" "}
                                                                      <span
                                                                        onClick={() =>
                                                                          this.downloadFormPdf(
                                                                            file.file_name.trim()
                                                                          )
                                                                        }
                                                                        class="badge badge-outline-primary"
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                        title="Download / View"
                                                                      >
                                                                        <em class="icon ni ni-download"></em>{" "}
                                                                        {file.file_name.trim()}
                                                                      </span>
                                                                    </div>
                                                                  ) : null}
                                                                </>
                                                              )
                                                            )}
                                                          </>
                                                        ) : (
                                                          "---"
                                                        )}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Sent Form Subject</td>
                                                      <td>
                                                        {general.email_subject}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Sent Form Message</td>
                                                      <td>
                                                        {general.email_content}
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                )
                                              )}
                                            </table>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* View Client Submissions */}
                    {/* START APPROVE Client MODAL  */}
                    <div
                      className="modal fade"
                      tabIndex="-1"
                      id="modalApproveAgentAlert"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <a
                            style={{ cursor: "pointer" }}
                            className="close"
                            onClick={() => {
                              this.modalHideAlll("modalApproveAgentAlert");
                            }}
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                          <div className="modal-body modal-body-lg text-center">
                            <div className="nk-modal">
                              <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-done bg-success"></em>

                              <h4 className="nk-modal-title">
                                Approve Submission?
                              </h4>

                              <div className="nk-modal-text">
                                {this.state.approveAgentErrorMessage !== "" ? (
                                  <div
                                    className="example-alert"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>
                                          {this.state.approveAgentErrorMessage}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.approveAgentSuccessMessage !==
                                "" ? (
                                  <div
                                    className="example-alert"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>
                                          {
                                            this.state
                                              .approveAgentSuccessMessage
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                <div className="caption-text">
                                  Are you sure you want to approve this
                                  submission
                                </div>
                              </div>
                              <div className="nk-modal-action">
                                {this.state.approve_agent_button ? (
                                  <button
                                    class="btn btn-lg btn-primary"
                                    type="button"
                                    disabled
                                  >
                                    <span
                                      class="spinner-grow spinner-grow-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    <span> Processing... </span>
                                  </button>
                                ) : (
                                  <>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      className="btn btn-lg btn-dim btn-outline-light"
                                      onClick={() => {
                                        this.modalHideAlll(
                                          "modalApproveAgentAlert"
                                        );
                                      }}
                                    >
                                      <em class="icon ni ni-shrink"></em>{" "}
                                      <span>Cancel</span>
                                    </a>
                                    {"  "}
                                    <a
                                      style={{ cursor: "pointer" }}
                                      className="btn btn-lg btn-mw btn-primary"
                                      onClick={() => {
                                        this.approve_client_form();
                                      }}
                                      disabled={
                                        this.state.disabled ||
                                        this.state.isProcessing
                                      } // disable button when processing
                                    >
                                      <em class="icon ni ni-done"></em>{" "}
                                      <span>Approve</span>
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* END APPROVE CLIENT MODAL  */}
                    {/* START REJECTION CLIENT MODAL */}
                    <div
                      class="modal fade"
                      tabindex="-1"
                      id="modalRejectionAgentAlert"
                    >
                      <div
                        class="modal-dialog modal-dialog-top modal-xl"
                        role="document"
                      >
                        <div class="modal-content">
                          <a
                            href="#"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em class="icon ni ni-cross"></em>
                          </a>
                          <div class="modal-header">
                            <h5 class="modal-title">
                              {this.state.rejection_agent_title}{" "}
                              <sup className="text-primary">
                                {this.state.form_title}
                              </sup>
                            </h5>
                          </div>
                          <div class="modal-body">
                            {this.state.rejectAgentErrorMessage !== "" ? (
                              <div class="alert alert-pro alert-danger mb-2">
                                <div class="alert-text">
                                  <h6>Error</h6>
                                  <p>{this.state.rejectAgentErrorMessage} </p>
                                </div>
                              </div>
                            ) : null}
                            {this.state.rejectAgentSuccessMessage !== "" ? (
                              <div class="alert alert-pro alert-success mb-2">
                                <div class="alert-text">
                                  <h6>Success</h6>
                                  <p>{this.state.rejectAgentSuccessMessage} </p>
                                </div>
                              </div>
                            ) : null}
                            <div id="revokeReasonItems">
                              <div className="tb-odr-id">Reason to reject</div>
                              <div className="card card-bordered card-preview mt-1">
                                <TextareaAutosize
                                  maxrows={4}
                                  name="reject_agent_reason"
                                  id="reject_agent_reason"
                                  aria-label=""
                                  placeholder=""
                                  value={this.state.reject_agent_reason}
                                  helperText="Reason to reject this submission"
                                  onChange={this.handleChange}
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    borderColor: "rgba(0, 0, 0, 0.125)",
                                    borderRadius: "4px",
                                  }}
                                  variant="outlined"
                                />
                              </div>
                              <div className="form-group text-right mt-2 mb-2">
                                {this.state.reject_client_button === true ? (
                                  <button
                                    class="btn btn-primary"
                                    type="button"
                                    disabled
                                  >
                                    <span
                                      class="spinner-grow spinner-grow-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    <span> Loading... </span>
                                  </button>
                                ) : (
                                  <>
                                    <button
                                      type="button"
                                      className="btn btn-light"
                                      onClick={() => {
                                        this.modalHideAlll(
                                          "modalRejectionAgentAlert"
                                        );
                                      }}
                                      style={{ marginRight: "10px" }}
                                    >
                                      <em class="icon ni ni-shrink"></em>{" "}
                                      <span>Cancel</span>
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() => {
                                        this.reject_agent_form();
                                      }}
                                      disabled={this.state.disabled}
                                    >
                                      <em class="icon ni ni-cross-circle"></em>{" "}
                                      <span>Reject</span>
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* END REJECTION CLIENT MODAL */}
                    {/* START CLIENT REJECTIONS MODAL */}
                    <div
                      class="modal fade"
                      tabindex="-1"
                      id="modalRejectionAgents"
                    >
                      <div
                        class="modal-dialog modal-dialog-top modal-xl"
                        role="document"
                      >
                        <div class="modal-content">
                          <a
                            href="#"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em class="icon ni ni-cross"></em>
                          </a>
                          <div class="modal-header">
                            <h5 class="modal-title">
                              All Rejections{" "}
                              <sup className="text-primary">
                                {this.state.form_title}
                              </sup>
                            </h5>
                          </div>
                          <div class="modal-body">
                            {this.state.rejectAgentErrorMessage !== "" ? (
                              <div class="alert alert-pro alert-danger mb-2">
                                <div class="alert-text">
                                  <h6>Error</h6>
                                  <p>{this.state.rejectAgentErrorMessage} </p>
                                </div>
                              </div>
                            ) : null}
                            {this.state.rejectAgentSuccessMessage !== "" ? (
                              <div class="alert alert-pro alert-success mb-2">
                                <div class="alert-text">
                                  <h6>Success</h6>
                                  <p>{this.state.rejectAgentSuccessMessage} </p>
                                </div>
                              </div>
                            ) : null}
                            <div id="revokeReasonItems">
                              {this.state.rejectionLoader === true ? (
                                FormLoader()
                              ) : (
                                <>
                                  <div>
                                    <div className="card card-bordered">
                                      <div className="card-inner-group">
                                        {this.state.allAgentRejectionsListing &&
                                        this.state.allAgentRejectionsListing
                                          .length > 0 ? (
                                          <div className="card-inner">
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                All Rejections
                                              </h6>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <table className="table table-bordered table-striped">
                                                <thead>
                                                  <th>Form Title</th>
                                                  <th>Rejection date</th>
                                                  <th>Reason</th>
                                                  <th>Submitted PDF</th>
                                                </thead>
                                                {this.state.allAgentRejectionsListing.map(
                                                  (general, index) => (
                                                    <tbody
                                                      key={`ind${index}`}
                                                      className="text-dark"
                                                    >
                                                      <tr>
                                                        <td>
                                                          {
                                                            this.state
                                                              .form_title
                                                          }
                                                        </td>
                                                        <td>
                                                          {this.format_date(
                                                            general.rejection_date
                                                          )}
                                                        </td>
                                                        <td>
                                                          {
                                                            general.rejection_reason
                                                          }
                                                        </td>
                                                        <td>
                                                          {general.is_submitted &&
                                                          general.is_submitted ===
                                                            1 ? (
                                                            <a
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() =>
                                                                this.downloadPdfGeneral(
                                                                  general.pdf_name
                                                                )
                                                              }
                                                            >
                                                              <span class="badge badge-outline-dark">
                                                                View
                                                              </span>
                                                            </a>
                                                          ) : (
                                                            "---"
                                                          )}
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  )
                                                )}
                                              </table>
                                            </div>
                                          </div>
                                        ) : (
                                          <div class="alert alert-pro alert-primary">
                                            <div class="alert-text">
                                              <h6>No Rejection</h6>
                                              <p>
                                                Rejections are not available
                                                against this submission.
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* END REJECTIONS CLIENT MODAL */}
                    {/* MODAL ASSIGN FORM */}
                    <div
                      className="modal fade"
                      tabIndex="-1"
                      id="modalAssignFormAgent"
                    >
                      <div
                        className="modal-dialog modal-xl modal-dialog-top"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <div
                              className="modal-title"
                              style={{ textAlign: "center", fontSize: "20px" }}
                            >
                              <strong>
                                <em className="icon ni ni-clipboad-check"></em>
                                Assign Form to {this.state.client_update_name} (
                                {this.state.client_comp_name}){" "}
                              </strong>
                            </div>
                            <a
                              href="#"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <em className="icon ni ni-cross"></em>
                            </a>
                          </div>

                          <div className="modal-body text-left">
                            <div className="nk-modal">
                              <div className="nk-modal-text">
                                {this.state.assignModalLoader === true ? (
                                  FormLoader()
                                ) : (
                                  <>
                                    {this.state.errorMessageAssignModal !==
                                    "" ? (
                                      <div
                                        className="example-alert "
                                        style={{ marginBottom: "15px" }}
                                      >
                                        <div className="alert alert-pro alert-danger">
                                          <div className="alert-text">
                                            <h4>Error</h4>
                                            <p>
                                              {
                                                this.state
                                                  .errorMessageAssignModal
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.successMessageAssignModal !==
                                    "" ? (
                                      <div
                                        className="example-alert "
                                        style={{ marginBottom: "15px" }}
                                      >
                                        <div className="alert alert-pro alert-success">
                                          <div className="alert-text">
                                            <h4>Success</h4>
                                            <p>
                                              {
                                                this.state
                                                  .successMessageAssignModal
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    <div className="card card-bordered card-preview">
                                      <table className="table table-orders">
                                        <thead className="tb-odr-head">
                                          <tr className="tb-odr-item">
                                            <th>
                                              <span className="tb-odr-action">
                                                Forms
                                              </span>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody className="tb-odr-body">
                                          {this.state.agentsList &&
                                          this.state.agentsList.length > 0 ? (
                                            <tr className="tb-odr-item customStyle_tb-odr-item_forms">
                                              <td
                                                style={{
                                                  paddingLeft: "16px",
                                                }}
                                              >
                                                {this.state.agentsList &&
                                                this.state.agentsList.length >
                                                  0 ? (
                                                  <div
                                                    className="form-group"
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                    }}
                                                  >
                                                    <Autocomplete
                                                      multiple
                                                      id="clients-autocomplete"
                                                      options={
                                                        this.state.agentsList
                                                      }
                                                      disableCloseOnSelect
                                                      getOptionLabel={(
                                                        option
                                                      ) => option.label}
                                                      value={this.state.agentsList.filter(
                                                        (agent) =>
                                                          this.state.selectedForms.includes(
                                                            agent.value
                                                          )
                                                      )}
                                                      onChange={
                                                        this.handleClientChange
                                                      }
                                                      getOptionSelected={(
                                                        option,
                                                        value
                                                      ) =>
                                                        option.value ===
                                                        value.value
                                                      }
                                                      renderOption={(
                                                        option,
                                                        { selected }
                                                      ) => (
                                                        <Fragment>
                                                          <Checkbox
                                                            icon={icon}
                                                            checkedIcon={
                                                              checkedIcon
                                                            }
                                                            style={{
                                                              marginRight: 8,
                                                            }}
                                                            checked={selected}
                                                          />
                                                          {option.label}
                                                        </Fragment>
                                                      )}
                                                      style={{ width: "100%" }}
                                                      renderInput={(params) => (
                                                        <MutextField
                                                          {...params}
                                                          label="Select Forms"
                                                          placeholder=""
                                                        />
                                                      )}
                                                    />
                                                  </div>
                                                ) : null}
                                              </td>
                                            </tr>
                                          ) : null}
                                          {this.state.agentsList &&
                                          this.state.agentsList.length > 0 ? (
                                            <>
                                              <tr>
                                                <td>
                                                  <div className="nk-modal-action  text-right">
                                                    <a
                                                      href="#"
                                                      className="btn btn-lg btn-mw btn-primary "
                                                      onClick={() => {
                                                        this.handleSubmitAssignFormAgent();
                                                      }}
                                                      disabled={
                                                        this.state
                                                          .disableAssignBtn
                                                      }
                                                    >
                                                      Assign to Channel Partner
                                                    </a>
                                                  </div>
                                                </td>
                                              </tr>
                                            </>
                                          ) : null}
                                        </tbody>
                                      </table>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* MODAL ASSIGN FORM */}
                    {/* View Client Submissions */}
                    <div
                      className="modal fade zoom"
                      tabIndex="-1"
                      id="modalClientArchivedSubmission"
                    >
                      <div
                        className="modal-dialog modal-xl modal-dialog-top"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <div
                              className="modal-title"
                              style={{ textAlign: "center", fontSize: "20px" }}
                            >
                              <strong>
                                <em className="icon ni ni-list-round"></em>{" "}
                                Archived Submission Details{" "}
                              </strong>
                            </div>
                            <a
                              href="#"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <em className="icon ni ni-cross"></em>
                            </a>
                          </div>

                          <div className="modal-body">
                            {/* NRC DIVS START */}

                            {this.state.viewLoader === true ? (
                              FormLoader()
                            ) : (
                              <>
                                <div>
                                  <div className="card card-bordered">
                                    <div className="card-inner-group">
                                      <div className="card-inner">
                                        <div className="sp-plan-head">
                                          <h6 className="title">
                                            Form Submission Details
                                          </h6>
                                        </div>

                                        <div className="sp-plan-desc sp-plan-desc-mb">
                                          <table className="table table-bordered table-striped">
                                            <tbody
                                              key="arch1"
                                              className="text-dark"
                                            >
                                              <tr>
                                                <td>Form Title</td>
                                                <td>{this.state.form_title}</td>
                                              </tr>
                                              <tr>
                                                <td>Archive Date</td>
                                                <td>
                                                  {this.state.archive_date &&
                                                  this.state.archive_date !== ""
                                                    ? this.format_date(
                                                        this.state.archive_date
                                                      )
                                                    : "---"}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* View Client Submissions */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViewAgent));
